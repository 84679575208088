import React, { useState, useEffect, useCallback } from 'react';
import { getLocalStorage } from 'util/storage';
import { Button } from '@material-ui/core';
import { useStyles } from './style';
import PrinterDialog from 'components/PrinterDialog';
import PrintIcon from '@material-ui/icons/Print';
import { useStore } from 'store';

const PrinterStatus = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [printerStatus, setPrinterStatus] = useState<'ONLINE' | 'OFFLINE' | 'REGULAR'>('OFFLINE');

  const {
    state: { ping },
    dispatch
  } = useStore();

  const pingIp = useCallback(() => {
    const printIp = getLocalStorage('printIp');

    if (printIp) {
      const pingRequest = new XMLHttpRequest();
      pingRequest.onreadystatechange = () => {
        if (pingRequest.readyState === 4) {
          if (pingRequest.status === 200) {
            setPrinterStatus('ONLINE');
          } else {
            setPrinterStatus('OFFLINE');
          }
        }
      };
      pingRequest.open('POST', `https://${printIp}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`, true);
      pingRequest.send();
    } else {
      const regularPrinter = getLocalStorage('regularPrinter');
      if (regularPrinter === 'true') {
        setPrinterStatus('REGULAR');
      }
    }
  }, []);

  useEffect(() => {
    const regularPrinter = getLocalStorage('regularPrinter');
    const printIp = getLocalStorage('printIp');

    if (!regularPrinter && !printIp) {
      dispatch({
        type: 'SET_PING',
        payload: false
      });
    }

    if (regularPrinter === 'true') {
      setPrinterStatus('REGULAR');
      dispatch({
        type: 'SET_PING',
        payload: false
      });
    } else {
      pingIp();
      const interval = setInterval(() => {
        pingIp();
      }, 60000);

      if (!ping) {
        clearInterval(interval);
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, ping, pingIp]);

  const ip = getLocalStorage('printIp');

  return (
    <>
      <Button onClick={() => setOpen(true)} startIcon={<PrintIcon className={printerStatus === 'ONLINE' ? classes.onlineStatus : classes.offlineStatus} />}>
        {!ip ? (printerStatus === 'REGULAR' ? 'Regular printer' : 'Printer not found') : ip}
      </Button>

      {open && <PrinterDialog open={open} onClose={() => setOpen(false)} onSubmit={pingIp} />}
    </>
  );
};

export default PrinterStatus;
