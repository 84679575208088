export enum newOrderDurationIdentifier {
  WITHIN_2_MINS = 1,
  WITHIN_4_MINS,
  WITHIN_6_MINS,
  OVERDUE
}

export enum processingOrderDurationIdentifier {
  WITHIN_10_MINS = 1,
  WITHIN_20_MINS,
  WITHIN_30_MINS,
  OVERDUE
}
