import { gql } from 'apollo-boost';

export default gql`
query getWaitMinutes($input: GetWaitMinutesInput!) {
  getWaitMinutes(input: $input) {
    takeoutWaitMinutes
    deliveryWaitMinutes
    cookingWaitMinutes
  }
}
`