import React from 'react';
import { Box, Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { map, groupBy } from 'lodash';
import { useStyles } from '../style';
import CancelIcon from '@material-ui/icons/Cancel';
import { formatPriceToString, calculatePoints } from '../utils';

interface IProps {
  items: any;
}

const OrderItems = ({ items }: IProps) => {
  const classes = useStyles();

  const Row = ({ children }: any) => {
    return (
      <Box padding={1} borderTop={`solid 2px ${grey[500]}`}>
        {children}
      </Box>
    );
  };

  const dishesGroupedByCategory = groupBy(items, 'dishCategoryTitle[0].text');

  return (
    <Box paddingX={1}>
      {map(Object.keys(dishesGroupedByCategory), (category, index) => {
        return (
          <Row key={index}>
            <Box paddingBottom={0.5}>
              <Typography variant="h6" className={classes.boldText}>
                {category}
              </Typography>
            </Box>
            <Box paddingX={1}>
              {map(dishesGroupedByCategory[category], (item: any) => {
                const dishType = item.dishType || 'REGULAR';

                if (dishType === 'REGULAR') {
                  const { dishTitle, count, dishPrice, strikedPrice, discountPercent, dishExtras, note, dishOriginalPrice, isReward, points } = item;

                  const [{ text: _title }] = dishTitle || [{ text: '' }];

                  const [{ text: _dishSize }] = dishOriginalPrice ? dishOriginalPrice.size : [{ text: '' }];

                  return (
                    <>
                      <Box display="flex">
                        <Box flexGrow={1} paddingRight={1}>
                          <Typography variant="body2" className={classes.boldText}>
                            {count}x {_title} ({_dishSize})
                          </Typography>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                          {!!strikedPrice && (
                            <Box className={classes.priceItem}>
                              <Typography variant="body1" className={classes.priceStriked} noWrap={true} align="right">
                                ${formatPriceToString(strikedPrice * count)}
                              </Typography>
                            </Box>
                          )}
                          <Box className={classes.priceItem}>
                            <Typography variant="body1" className={classes.boldText} noWrap={true} align="right">
                              {isReward ? `${calculatePoints(count, points)} PTS` : `$${formatPriceToString(dishPrice * count)}`}
                            </Typography>
                          </Box>
                          {!!discountPercent && (
                            <Box className={classes.priceItem}>
                              <Typography variant="body1" className={classes.priceDiscount} noWrap={true} align="right">
                                {discountPercent}%&nbsp;OFF
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      {!!dishExtras && (
                        <>
                          {map(dishExtras, (extras, index1) => {
                            const { groupTitle, items: extraItems } = extras;

                            return (
                              <Typography variant="body2" key={index1}>
                                {groupTitle} : {extraItems.join(', ')}
                              </Typography>
                            );
                          })}
                        </>
                      )}
                      {note && (
                        <Typography variant="body2">
                          <span className={classes.boldText}>Dish Note: </span>
                          {note}
                        </Typography>
                      )}
                    </>
                  );
                }
                if (dishType === 'PIZZA') {
                  const {
                    count,
                    dishTitle,
                    pizzaCrust,
                    toppings: { leftToppings, rightToppings },
                    sauces: { leftSauces, rightSauces },
                    cheeses: { leftCheeses, rightCheeses },
                    halfAndHalf,
                    pizzaBasePrice,
                    dishPrice,
                    note,
                    strikedPrice,
                    discountPercent
                  } = item;

                  const [{ text: _title }] = dishTitle || [{ text: '' }];

                  const { title: _dishSize } = pizzaBasePrice && pizzaBasePrice.size ? pizzaBasePrice.size : { title: '' };

                  const { title: crust } = pizzaCrust || { title: '' };

                  return (
                    <>
                      <Box display="flex">
                        <Box flexGrow={1} paddingRight={1}>
                          <Typography variant="body2" className={classes.boldText}>
                            {count}x {_title} ({_dishSize})
                          </Typography>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                          {!!strikedPrice && (
                            <Box className={classes.priceItem}>
                              <Typography variant="body1" className={classes.priceStriked} noWrap={true} align="right">
                                ${formatPriceToString(strikedPrice * count)}
                              </Typography>
                            </Box>
                          )}
                          <Box className={classes.priceItem}>
                            <Typography variant="body1" className={classes.boldText} noWrap={true} align="right">
                              ${formatPriceToString(dishPrice * count)}
                            </Typography>
                          </Box>
                          {!!discountPercent && (
                            <Box className={classes.priceItem}>
                              <Typography variant="body1" className={classes.priceDiscount} noWrap={true} align="right">
                                {discountPercent}%&nbsp;OFF
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Typography variant="body2">{crust}</Typography>
                      {!halfAndHalf ? (
                        <>
                          {!!leftSauces.exclusions && !!leftSauces.exclusions.length && (
                            <>
                              {map(leftSauces.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}

                          {!!leftCheeses.exclusions && !!leftCheeses.exclusions.length && (
                            <>
                              {map(leftCheeses.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}

                          {!!leftToppings.exclusions && !!leftToppings.exclusions.length && (
                            <>
                              {map(leftToppings.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!leftSauces.additions && !!leftSauces.additions.length && (
                            <>
                              {map(leftSauces.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!leftCheeses.additions && !!leftCheeses.additions.length && (
                            <>
                              {map(leftCheeses.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!leftToppings.additions && !!leftToppings.additions.length && (
                            <>
                              {map(leftToppings.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography variant="body2">{'LEFT HALF'}</Typography>

                          {!!leftSauces.exclusions && !!leftSauces.exclusions.length && (
                            <>
                              {map(leftSauces.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}

                          {!!leftCheeses.exclusions && !!leftCheeses.exclusions.length && (
                            <>
                              {map(leftCheeses.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}

                          {!!leftToppings.exclusions && !!leftToppings.exclusions.length && (
                            <>
                              {map(leftToppings.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!leftSauces.additions && !!leftSauces.additions.length && (
                            <>
                              {map(leftSauces.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!leftCheeses.additions && !!leftCheeses.additions.length && (
                            <>
                              {map(leftCheeses.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!leftToppings.additions && !!leftToppings.additions.length && (
                            <>
                              {map(leftToppings.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}

                          <Typography variant="body2">{'RIGHT HALF'}</Typography>

                          {!!rightSauces.exclusions && !!rightSauces.exclusions.length && (
                            <>
                              {map(rightSauces.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}

                          {!!rightCheeses.exclusions && !!rightCheeses.exclusions.length && (
                            <>
                              {map(rightCheeses.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}

                          {!!rightToppings.exclusions && !!rightToppings.exclusions.length && (
                            <>
                              {map(rightToppings.exclusions, (topping, index1) => {
                                const { title = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2}>
                                    <CancelIcon className={classes.cancelIcon} />
                                    <Typography variant="body2">{title}</Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!rightSauces.additions && !!rightSauces.additions.length && (
                            <>
                              {map(rightSauces.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!rightCheeses.additions && !!rightCheeses.additions.length && (
                            <>
                              {map(rightCheeses.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                          {!!rightToppings.additions && !!rightToppings.additions.length && (
                            <>
                              {map(rightToppings.additions, (topping, index1) => {
                                const { title = '', density = '' } = topping;
                                return (
                                  <Box key={index1} display="flex" alignItems="center" paddingLeft={2.5}>
                                    <Typography variant="body2">
                                      {title + ' '}
                                      {density !== 'Regular' && <span className={classes.redTextColor}> ({density}) </span>}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}

                      {note && (
                        <Typography variant="body2">
                          <span className={classes.boldText}>Dish Note: </span>
                          {note}
                        </Typography>
                      )}
                    </>
                  );
                }
                return null;
              })}
            </Box>
          </Row>
        );
      })}
    </Box>
  );
};

export default OrderItems;
