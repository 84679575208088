/**
 * 
 * @param word Capitalise a word eg. BOONS to Boons
 */
export const capitalise = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

/**
 * 
 * @param data Capitalise a word eg Doordash_Classic to Doordash Classic
 * @returns 
 */
export const makeReadable = (data: string) => {
  const split = data.split('_');
  if (split.length === 1) {
    return capitalise(split[0]);
  } else {
    const capitals = split.map((word: string) => (capitalise(word)));
    return capitals.join(" ")
  }
};