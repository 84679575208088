import React, { useEffect, useState } from 'react';
import { getLocalStorage } from 'util/storage';
import { Box, Typography } from '@material-ui/core';

const Status = () => {
  const printIp = getLocalStorage('printIp');

  const [cover, setCover] = useState('NA');

  const [paper, setPaper] = useState('NA');

  const [drawer, setDrawer] = useState('NA');

  useEffect(() => {
    if (printIp && printIp !== '') {
      const PUBLIC_URL = process.env.PUBLIC_URL ? 'https://' + process.env.PUBLIC_URL : 'http://localhost:3000';

      const content = `
        <html>
          <script src="${PUBLIC_URL}/js/epos.js"></script>
          <script>
            const printer = new epson.ePOSBuilder();
            if (printer) {
              var address = "http://${printIp}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000";
              var epos = new epson.ePOSPrint(address);

              

              epos.oncoverok = ()=>{
                window.parent.postMessage({ statusMessage: "coverok"}, "*");
              }

              epos.oncoveropen = ()=>{
                window.parent.postMessage({ statusMessage: "coveropen"}, "*");
              }

              epos.onpaperok = ()=>{
                window.parent.postMessage({ statusMessage: "paperok"}, "*");
              }

              epos.onpaperend = ()=>{
                window.parent.postMessage({ statusMessage: "paperend"}, "*");
              }

              epos.ondrawerclosed = ()=>{
                window.parent.postMessage({ statusMessage: "drawerclose"}, "*");
              }

              epos.ondraweropen = ()=>{
                window.parent.postMessage({ statusMessage: "draweropen"}, "*");
              }

              epos.open();

             

              epos.send();
            }
          </script>
        </html>
      `;

      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.visibility = 'hidden';

      window.addEventListener(
        'message',
        (event: MessageEvent) => {
          if (event.data && event.data.statusMessage) {
            if (event.data.statusMessage === 'coverok') {
              setCover('Close');
            }
            if (event.data.statusMessage === 'coveropen') {
              setCover('Open');
            }
            if (event.data.statusMessage === 'paperok') {
              setPaper('Set');
            }
            if (event.data.statusMessage === 'paperend') {
              setPaper('End');
            }
            if (event.data.statusMessage === 'drawerclose') {
              setDrawer('Close');
            }
            if (event.data.statusMessage === 'draweropen') {
              setDrawer('Open');
            }
          }
        },
        false
      );

      hiddFrame.srcdoc = content;

      document.body.appendChild(hiddFrame);

      return () => {
        window.removeEventListener('message', () => {});
        try {
          document.body.removeChild(hiddFrame);
        } catch {}
      };
    }
  }, [printIp]);

  if (!printIp || printIp === '') {
    return null;
  }

  return (
    <Box paddingY={2}>
      <Box paddingY={1}>
        <Typography variant="h6">Printer status</Typography>
      </Box>
      <Box paddingY={1}>
        <Typography variant="body2">IP : {printIp}</Typography>
      </Box>
      <Box paddingY={1}>
        <Typography variant="body2">Paper : {paper}</Typography>
      </Box>
      <Box paddingY={1}>
        <Typography variant="body2">Cover : {cover}</Typography>
      </Box>
      <Box paddingY={1}>
        <Typography variant="body2">Drawer : {drawer}</Typography>
      </Box>
    </Box>
  );
};

export default Status;
