import React, { createContext, useContext, useEffect, useState } from 'react';

import { useGetWaitMins } from 'graphql/hooks/restaurant.hooks';

interface IInitialValue {
  takeOutWaitMins: number;
  deliveryWaitMins: number;
  cookingWaitMins: number;
}
interface IProps {
  children: React.ReactNode;
}

const defaultWaitMins = {
  takeOutWaitMins: 0,
  deliveryWaitMins: 0,
  cookingWaitMins: 0
};

const WaitMinsContext = createContext(defaultWaitMins);

export function WaitTimeContextProvider({ children }: IProps) {
  const { data } = useGetWaitMins();

  const [initialValues, setInitialValues] = useState<IInitialValue>({
    takeOutWaitMins: 0,
    deliveryWaitMins: 0,
    cookingWaitMins: 0
  });

  useEffect(() => {
    if (data && data.getWaitMinutes) {
      setInitialValues({
        takeOutWaitMins: data.getWaitMinutes.takeoutWaitMinutes || 0,
        deliveryWaitMins: data.getWaitMinutes.deliveryWaitMinutes || 0,
        cookingWaitMins: data.getWaitMinutes.cookingWaitMinutes || 0
      });
    }
  }, [data]);

  return <WaitMinsContext.Provider value={initialValues}>{children}</WaitMinsContext.Provider>;
}

export function useGetWaitTimeContext() {
  return useContext(WaitMinsContext);
}
