import { gql } from 'apollo-boost';

export default gql`
  query getRestaurantByNanoid($input: GetRestaurantByNanoidInput!) {
    getRestaurantByNanoid(input: $input) {
      id
      name {
        text
      }
      logo {
        id
        url
      }
    }
  }
`;
