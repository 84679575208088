import { useContext, useEffect, useState } from 'react';
import { GET_SELLER_ONGOING_ORDERS, GET_ORDER_BY_ORDER_ID, GET_ORDER_BY_ORDER_NUMBER, GET_SELLER_ORDERS } from 'graphql/query';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { HumanSpeaker } from 'util/speaker';
import { useStore } from 'store';
import { FirebaseContext } from 'fbase';
import { isEmpty, filter, map, sortBy, groupBy, uniq, isEqual } from 'lodash';
import Logger from 'util/logger';
import { useQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import { UPDATE_ORDER_STATUS, CLOSE_TABLE_SERVICE_ORDER, ORDER_PARTIAL_REFUND } from 'graphql/mutations';
import { IOrder, notifyType } from 'types';
import Moment from 'moment';
import { IDeliveryConfirmInput } from 'generated/custom';
import { getMillToAdd } from 'util/delivery';
import { ExecutionResult } from 'graphql/execution';
import moment from 'moment';

export const useOrders = () => {
  const {
    state: { restaurantId },
    dispatch
  } = useStore();
  const { firebase } = useContext(FirebaseContext);

  const { data, loading, error, refetch, called, startPolling, stopPolling } = useQuery(GET_SELLER_ONGOING_ORDERS, {
    variables: {
      input: {
        sellerBizId: restaurantId
      }
    }
  });

  const [newOrders, setNewOrders] = useState<any[]>([]);

  /**
   * To check if all orders are of 'type' orderType
   * @param orders The orders to check
   * @param type The orderType value to check for
   * @returns true is all orders are of 'type' orderType
   */
  const checkOrderType = (orders: any[], type: 'DINING' | 'TAKEOUT' | 'DELIVERY' | 'TABLE_SERVICE'): boolean => {
    const groupedByTypes = uniq(map(orders, 'type'));

    if (groupedByTypes.length === 1 && groupedByTypes[0] === type) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (firebase) {
      firebase
        .getDb()
        .ref('/restaurant/order/' + restaurantId)
        .on('value', (value: any) => {
          if (refetch) refetch();
        });
    }
    // Pull orders in every 10 minutes
    startPolling(600000);
    return () => {
      stopPolling();
      if (firebase) {
        firebase
          .getDb()
          .ref('/restaurant/order/' + restaurantId)
          .off('value');
      }
    };
  }, [firebase, refetch, restaurantId, startPolling, stopPolling]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const { getSellerOngoingOrders } = data;

      const _newOrders = filter(getSellerOngoingOrders, (order: any) => order.status === 'PLACED');

      const savedNewOrderIds = map(newOrders, 'id');

      const newReceivedOrder = filter(_newOrders, ({ id }) => !savedNewOrderIds.includes(id));
      /**
       * Check and notify if any order is accpted and it's overdue
       */
      const notifyAcceptedOverdueOrders = (takeOutProcessingOverdueOrders: Array<any>, deliveryProcessingOverdueOrders: Array<any>) => {
        if (takeOutProcessingOverdueOrders.length || deliveryProcessingOverdueOrders.length) {
          let notify: notifyType = 'ACCEPTED_OVERDUE';
          HumanSpeaker.speak(notify);
          dispatch({
            type: 'SET_NOTIFY',
            payload: notify
          });
        } else {
          dispatch({
            type: 'UNSET_NOTIFY'
          });
        }
      };
      /**
       * Sending audio alert if new orders comes
       */

      const now = Moment();
      const groupedOrders = groupBy(getSellerOngoingOrders, 'type');
      const takeOutOrders = groupedOrders['TAKEOUT'];
      const groupedTakeOutOrders = groupBy(takeOutOrders, 'status');
      const takeOutProcessingOverdueOrders = groupedTakeOutOrders['ACCEPTED']
        ? filter(groupedTakeOutOrders['ACCEPTED'], (order: any) => {
            const expTime = Moment(order.expectTime);
            const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
            return !!todayOrder && expTime.isBefore(now);
          })
        : [];

      const deliveryOrders = groupedOrders['DELIVERY'];
      const groupedDeliveryOrders = groupBy(deliveryOrders, 'status');
      const deliveryProcessingOverdueOrders = groupedDeliveryOrders['ACCEPTED']
        ? filter(groupedDeliveryOrders['ACCEPTED'], (order: any) => {
            const expTime = Moment(order.expectTime);
            const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
            return !!todayOrder && expTime.isBefore(now);
          })
        : [];
      if (newReceivedOrder.length) {
        let notify: notifyType = 'DEFAULT';

        //Checking if all new received orders are TABLE_SERVICE type
        const isOnlyTableServiceOrders = checkOrderType(newReceivedOrder, 'TABLE_SERVICE');

        if (isOnlyTableServiceOrders) {
          notify = 'TABLE_SERVICE';
          HumanSpeaker.speak(notify);
          dispatch({
            type: 'SET_NOTIFY',
            payload: notify
          });
        } else {
          dispatch({
            type: 'UNSET_NOTIFY'
          });
        }
      } else {
        notifyAcceptedOverdueOrders(takeOutProcessingOverdueOrders, deliveryProcessingOverdueOrders);
      }

      /**
       * If there is atleast one pending order
       */
      if (_newOrders.length) {
        let notify: notifyType = 'DEFAULT';

        //Checking if all pending orders are TABLE_SERVICE type
        const isOnlyTableServiceOrders = checkOrderType(_newOrders, 'TABLE_SERVICE');

        if (isOnlyTableServiceOrders) {
          notify = 'TABLE_SERVICE';
        }

        dispatch({
          type: 'SET_NOTIFY',
          payload: notify
        });
      } else {
        notifyAcceptedOverdueOrders(takeOutProcessingOverdueOrders, deliveryProcessingOverdueOrders);
      }
      if (!isEqual(newOrders, _newOrders)) {
        setNewOrders(_newOrders);
      }
    }
  }, [data, newOrders.length, dispatch, newOrders]);

  if (error) {
    Logger.log('ERROR: ' + error);
  }

  if (!isEmpty(data)) {
    const { getSellerOngoingOrders } = data;

    /*
     * Group orders by order types TAKEOUT,DINING,TABLE_SERVICE,DELIVERY
     */
    const groupedOrders = groupBy(getSellerOngoingOrders, 'type');

    /**
     * Take Out Orders
     * Should be sorted by expectTime
     **/

    const takeOutOrders = sortBy(groupedOrders['TAKEOUT'] ? groupedOrders['TAKEOUT'] : [], 'expectTime');

    /*
     * group takeout orders by order status
     **/

    const groupedTakeOutOrders = groupBy(takeOutOrders, 'status');

    const takeOutNewOrders = groupedTakeOutOrders['PLACED'] ? groupedTakeOutOrders['PLACED'] : [];
    /*
     * Show only today's orders in processing and other orders in future orders
     */
    const now = Moment();
    const takeOutProcessingOrders = groupedTakeOutOrders['ACCEPTED']
      ? filter(groupedTakeOutOrders['ACCEPTED'], (order: any) => {
          const expTime = Moment(order.expectTime);
          const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
          return !!todayOrder;
        })
      : [];
    const futureTakeOutOrders = groupedTakeOutOrders['ACCEPTED']
      ? filter(groupedTakeOutOrders['ACCEPTED'], (order: any) => {
          const expTime = Moment(order.expectTime);
          const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
          return !todayOrder && expTime.isAfter(now);
        })
      : [];
    const takeOutReadyOrders = groupedTakeOutOrders['READY'] ? groupedTakeOutOrders['READY'] : [];

    /**
     * Dining Orders
     * Should be sorted by createdAt
     **/

    const diningOrders = groupedOrders['DINING'] ? sortBy(groupedOrders['DINING'], 'createdAt') : [];

    /*
     * group dining orders by order status
     **/

    const groupedDiningOrders = groupBy(diningOrders, 'status');

    const diningNewOrders = groupedDiningOrders['PLACED'] ? groupedDiningOrders['PLACED'] : [];
    const diningProcessingOrders = groupedDiningOrders['ACCEPTED'] ? groupedDiningOrders['ACCEPTED'] : [];
    const diningReadyOrders = groupedDiningOrders['READY'] ? groupedDiningOrders['READY'] : [];

    /**
     * Table Service Orders
     * Should be sorted by createdAt
     **/

    const tableServiceOrders = groupedOrders['TABLE_SERVICE'] ? sortBy(groupedOrders['TABLE_SERVICE'], 'createdAt') : [];

    /*
     * group table service orders by order status
     **/

    const groupedTableServiceOrders = groupBy(tableServiceOrders, 'status');

    const tableServiceNewOrders = groupedTableServiceOrders['PLACED'] ? groupedTableServiceOrders['PLACED'] : [];
    const tableServiceClosedOrders = groupedTableServiceOrders['CLOSED'] ? groupedTableServiceOrders['CLOSED'].reverse() : [];

    /**
     * Delivery Orders
     * Should be sorted by expectTime
     **/

    const deliveryOrders = groupedOrders['DELIVERY'] ? sortBy(groupedOrders['DELIVERY'], 'expectTime') : [];

    /*
     * group table service orders by order status
     **/

    const groupedDeliveryOrders = groupBy(deliveryOrders, 'status');

    const deliveryNewOrders = groupedDeliveryOrders['PLACED'] ? groupedDeliveryOrders['PLACED'] : [];

    const deliveryProcessingOrders = groupedDeliveryOrders['ACCEPTED']
      ? filter(groupedDeliveryOrders['ACCEPTED'], (order: any) => {
          const expTime = Moment(order.expectTime);
          const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
          return !!todayOrder;
        })
      : [];

    const futureDeliveryOrders = groupedDeliveryOrders['ACCEPTED']
      ? filter(groupedDeliveryOrders['ACCEPTED'], (order: any) => {
          const expTime = Moment(order.expectTime);
          const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
          return !todayOrder && expTime.isAfter(now);
        })
      : [];

    const deliveryReadyOrders = groupedDeliveryOrders['READY'] ? groupedDeliveryOrders['READY'] : [];

    return {
      newOrders: [...diningNewOrders, ...takeOutNewOrders, ...deliveryNewOrders],
      processingOrders: [...diningProcessingOrders, ...takeOutProcessingOrders, ...deliveryProcessingOrders],
      readyOrders: [...diningReadyOrders, ...takeOutReadyOrders, ...deliveryReadyOrders],
      futureOrders: sortBy([...futureTakeOutOrders, ...futureDeliveryOrders], 'expectTime'),
      allOrders: getSellerOngoingOrders,
      tableServiceNewOrders,
      tableServiceClosedOrders,
      tableServiceOrderCount: tableServiceNewOrders.length,
      loading,
      error,
      called
    };
  }

  return {
    newOrders: null,
    processingOrders: null,
    readyOrders: null,
    allOrders: null,
    tableServiceNewOrders: null,
    tableServiceClosedOrders: null,
    futureOrders: null,
    tableServiceOrderCount: 0,
    loading,
    error,
    called
  };
};

export const useOrderDetails = (orderId: string, buyerId: string) => {
  const { loading, data, error } = useQueryWithLoader(GET_ORDER_BY_ORDER_ID, {
    skip: !orderId,
    variables: {
      input: {
        orderId,
        buyerUid: buyerId
      }
    }
  });

  if (error) {
    Logger.log('ERROR: ' + error);
  }

  if (!isEmpty(data)) {
    const { getOrderByOrderId } = data;

    return { loading, data: getOrderByOrderId, error };
  }

  return { loading, data, error };
};

export const useOrderDetailsWithoutLoader = (orderId: string, buyerId: string) => {
  const { loading, data, error } = useQuery(GET_ORDER_BY_ORDER_ID, {
    skip: !orderId,
    variables: {
      input: {
        orderId,
        buyerUid: buyerId
      }
    }
  });

  if (error) {
    Logger.log('ERROR: ' + error);
  }

  if (!isEmpty(data)) {
    const { getOrderByOrderId } = data;

    return { loading, data: getOrderByOrderId, error };
  }

  return { loading, data, error };
};

export const useUpdateOrderStatus = () => {
  const [updateStatus, { data, loading, error }] = useMutation(UPDATE_ORDER_STATUS);

  const _updateStatus = async (restaurantId: string, orderId: string, buyerId: string, status: string, msg?: string, deliveryConfirmInput?: IDeliveryConfirmInput, delayEtaBy?: number) => {
    try {
      const response: ExecutionResult<any> | any = await updateStatus({
        variables: {
          input: {
            buyerUid: buyerId,
            sellerBizId: restaurantId,
            orderId,
            newStatus: status,
            msg,
            noOfBags: !isEmpty(deliveryConfirmInput) ? deliveryConfirmInput?.bagNumber : undefined,
            cookingWaitMins: !isEmpty(deliveryConfirmInput) ? deliveryConfirmInput?.cookingWaitMins : undefined,
            specialHandlingInstruction: !isEmpty(deliveryConfirmInput) ? deliveryConfirmInput?.handlingIns : undefined,
            type: !isEmpty(deliveryConfirmInput) ? deliveryConfirmInput?.type : undefined,
            deliveryProvider: !isEmpty(deliveryConfirmInput) ? deliveryConfirmInput?.deliveryProvider : undefined,
            deliveryQuoteId: !isEmpty(deliveryConfirmInput) ? deliveryConfirmInput?.deliveryQuoteId : undefined,
            delayEtaBy: delayEtaBy ?? delayEtaBy
          }
        },
        update: (cache) => {
          // const cookingWaitMins = deliveryConfirmInput?.cookingWaitMins;

          const { getSellerOngoingOrders: orders }: any = cache.readQuery({
            query: GET_SELLER_ONGOING_ORDERS,
            variables: {
              input: {
                sellerBizId: restaurantId
              }
            }
          });

          const { getOrderByOrderId }: any = cache.readQuery({
            query: GET_ORDER_BY_ORDER_ID,
            variables: {
              input: {
                orderId: orderId,
                buyerUid: buyerId
              }
            }
          });
          const newEtaTime = delayEtaBy ? moment.now() + delayEtaBy * 60000 : getOrderByOrderId.expectTime;
          const newDataWithEta = Object.assign(getOrderByOrderId, { expectTime: newEtaTime });

          const newData = map(orders, (order) => {
            if (order.id === orderId) {
              return { ...order, status };
            }
            return order;
          });

          cache.writeQuery({
            data: {
              getSellerOngoingOrders: newData,
              getOrderByOrderId: newDataWithEta
            },
            query: GET_SELLER_ONGOING_ORDERS,
            variables: {
              input: {
                sellerBizId: restaurantId
              }
            }
          });
        }
      });

      return response;
    } catch (e) {
      return {
        error: e
      };
    }
  };

  return {
    updateStatus: _updateStatus,
    data,
    loading,
    error
  };
};

export const useFindOrder = () => {
  const [findOrder, { data, error, loading, called }] = useLazyQuery(GET_ORDER_BY_ORDER_NUMBER);

  return {
    findOrder,
    data,
    loading,
    error,
    called
  };
};

export const useHistory = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const [hasMore, setHasMore] = useState(false);

  const [endCursor, setEndCursor] = useState(null);

  const [historyOrders, setHistoryOrders] = useState<IOrder[]>([]);

  const [fetching, setFetching] = useState(true);

  const pageSize = 100;

  const { data, error, fetchMore } = useQueryWithLoader(GET_SELLER_ORDERS, {
    skip: !restaurantId,
    variables: {
      input: {
        sellerBizId: restaurantId,
        first: pageSize
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.getSellerOrders && !error) {
      const { getSellerOrders } = data;

      const {
        pageInfo: { hasNextPage, endCursor }
      } = getSellerOrders;

      setHasMore(hasNextPage);
      setEndCursor(endCursor);

      if (getSellerOrders.edges.length) {
        const { getSellerOrders: { edges } = { edges: [] } } = data;
        setHistoryOrders(map(edges, ({ node }) => node));
      }
      setFetching(false);
    }
    if (error) {
      setFetching(false);
      Logger.log('ERROR: ' + error);
    }
  }, [data, error]);

  const fetchMoreData = async () => {
    if (hasMore) {
      try {
        setFetching(true);
        await fetchMore({
          query: GET_SELLER_ORDERS,
          variables: {
            input: {
              sellerBizId: restaurantId,
              first: pageSize,
              after: endCursor
            }
          },
          updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
            const { pageInfo } = fetchMoreResult.getSellerOrders;
            const newCursor = pageInfo.endCursor;

            return {
              getSellerOrders: {
                edges: [...previousResult.getSellerOrders.edges, ...fetchMoreResult.getSellerOrders.edges],
                pageInfo: {
                  endCursor: newCursor,
                  hasNextPage: pageInfo.hasNextPage,
                  __typename: pageInfo.__typename
                },

                __typename: previousResult.getSellerOrders.__typename
              }
            };
          }
        });
      } catch (e) {
        setFetching(false);
        Logger.log(e.message);
      }
    }
  };

  return {
    historyOrders,
    fetchMoreData,
    fetching,
    hasMore,
    error
  };
};

export const useCloseTableServiceOrder = () => {
  const [closeOrder, { data, loading, error }] = useMutationWithLoader(CLOSE_TABLE_SERVICE_ORDER);

  const _closeOrder = async (restaurantId: string, orderId: string) => {
    try {
      const response = await closeOrder({
        variables: {
          input: {
            sellerBizId: restaurantId,
            orderId
          }
        },
        update: (cache) => {
          const { getSellerOngoingOrders: orders }: any = cache.readQuery({
            query: GET_SELLER_ONGOING_ORDERS,
            variables: {
              input: {
                sellerBizId: restaurantId
              }
            }
          });
          const newData = map(orders, (order) => {
            if (order.id === orderId) {
              return { ...order, status: 'CLOSED', updatedAt: Moment() };
            }
            return order;
          });
          cache.writeQuery({
            data: {
              getSellerOngoingOrders: newData
            },
            query: GET_SELLER_ONGOING_ORDERS,
            variables: {
              input: {
                sellerBizId: restaurantId
              }
            }
          });
        }
      });

      return response;
    } catch (e) {
      return null;
    }
  };

  return {
    closeOrder: _closeOrder,
    data,
    loading,
    error
  };
};

export const usePartialRefund = () => {
  const [addPartialRefund, { data, loading, error }] = useMutation(ORDER_PARTIAL_REFUND);

  interface IPartialRefundInput {
    restaurantId: string;
    orderNumber: string;
    orderId: string;
    adjustAmount: number;
    adjustReason: string;
    buyerId: string;
  }

  const _addPartialRefund = async (input: IPartialRefundInput) => {
    const { restaurantId, orderNumber, orderId, adjustAmount, adjustReason, buyerId } = input;

    try {
      const response = await addPartialRefund({
        variables: {
          input: {
            sellerBizId: restaurantId,
            orderNumber,
            adjustAmount,
            adjustReason
          }
        },
        refetchQueries: [
          {
            query: GET_ORDER_BY_ORDER_ID,
            variables: {
              input: {
                orderId: orderId,
                buyerUid: buyerId
              }
            }
          }
        ]
      });

      return response;
    } catch (e) {
      return null;
    }
  };

  return {
    addPartialRefund: _addPartialRefund,
    data,
    loading,
    error
  };
};
