import { gql } from 'apollo-boost';

export default gql`
  mutation updateGroceryWaitMinutes($input: UpdateGroceryWaitMinutesInput!) {
    updateGroceryWaitMinutes(input: $input) {
      takeoutWaitMinutes
      deliveryWaitMinutes
      packingWaitMins
    }
  }
`;
