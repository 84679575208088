import React from 'react';
import SingleItem from '../SingleItem';
import { IIngredientItem } from '../types';
interface IProps {
  initialValues: Array<IIngredientItem>;
  isDisabled: boolean;
  updateIngredients: (itemKey: string, action: boolean) => void;
}

const ItemList = ({ initialValues, isDisabled, updateIngredients }: IProps) => {
  if (initialValues && initialValues.length > 0) {
    return (
      <>
        {initialValues.map((singleItem: any) => {
          if (singleItem.disabled === isDisabled) {
            return <SingleItem key={singleItem.key} singleItemData={singleItem} updateIngredients={updateIngredients} />;
          } else {
            return <></>;
          }
        })}{' '}
      </>
    );
  } else {
    return <></>;
  }
};

export default ItemList;
