import React from 'react';
import { Box } from '@material-ui/core';
import { useStore } from 'store';
import { useStyles } from './style';
import { Switch, Route } from 'react-router-dom';
import { DisplayOrders, OrderHistory } from 'pages/GroceryStoreDashboard/Orders';
import { useGroceryStoreOrders } from 'graphql/hooks/groceryStoreOrders.hooks';
import GroceryHeader from 'components/GroceryHeader';

const Home = () => {
  const classes = useStyles();

  const {
    state: { storeId }
  } = useStore();

  const { futureOrders, newOrders, processingOrders, readyOrders } = useGroceryStoreOrders();

  return (
    <Box className={classes.container}>
      <GroceryHeader storeId={storeId} />
      <Box width="100%" display="flex">
        <Switch>
          <Route
            exact={true}
            path="/grocerystore/:storeId/home"
            component={() => {
              return <DisplayOrders newOrders={newOrders} processingOrders={processingOrders} readyOrders={readyOrders} futureOrders={futureOrders} />;
            }}
          />
          <Route path="/grocerystore/:storeId/home/history" component={OrderHistory} />
        </Switch>
      </Box>
    </Box>
  );
};

export default Home;
