import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { getLocalStorage, removeLocalStorage } from 'util/storage';
import config, { defaults } from 'apollo';
import App from './App';
import './global.css';
import * as serviceWorker from './serviceWorker';

const cache = new InMemoryCache();

const client = new ApolloClient({
  ...config,
  cache
});

/**
 * Resetting apollo client.
 * Removes token from local storage.
 * Reset cache to its default value.
 */
client.onResetStore(async () => {
  // Remove token from localStorage.
  removeLocalStorage('token');
  // Write default values in the cache memory
  client.writeData({ data: defaults });
});

/**
 * Initialize local cache if token is present in local storage logs in user otherwise log out.
 */
cache.writeData({
  data: {
    isLoggedIn: !!getLocalStorage('token')
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();