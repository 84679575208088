import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField } from '@material-ui/core';
import { useStore } from 'store';
import { useStyles } from './style';
import { Dialog, ConfirmDialog } from '@lokobee/lokobee-ui';
import { useSnackbar } from 'notistack';
import Print from 'components/Print';
import { getLocalStorage } from 'util/storage';
import { useGroceryUpdateOrderStatus } from 'graphql/hooks/groceryStoreOrders.hooks';
import { DeliveryProviders, OrderType } from 'generated/custom';

interface IProps {
  orderId: string;
  orderStatus: string;
  buyerId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onPrint: () => void;
  isFutureOrder?: boolean;
  deliveryProviders?: DeliveryProviders;
  orderType: OrderType;
}

type STATUS_TYPES = 'ACCEPTED' | 'REJECTED' | 'READY' | 'CLOSED' | 'CANCELLED';

const ActionButtons = ({ orderId, orderStatus, buyerId, setLoading, onPrint, isFutureOrder = false, deliveryProviders, orderType }: IProps) => {
  const classes = useStyles();

  const {
    state: { storeId }
  } = useStore();

  const { updateStatus, loading } = useGroceryUpdateOrderStatus();

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const [message, setMessage] = useState('');

  const snackbar = useSnackbar();

  const callUpdateStatus = async (status: STATUS_TYPES) => {
    const response = await updateStatus(storeId, orderId, buyerId, status);

    if (response) {
      const regularPrinter = getLocalStorage('regularPrinter');

      if (status === 'ACCEPTED' && regularPrinter === 'false') {
        onPrint();
      }
      snackbar.enqueueSnackbar('Order status updated.', { variant: 'success' });
    } else {
      snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }
    setLoading(false);
  };

  const callUpdateStatusWithMsg = async (status: STATUS_TYPES) => {
    if (message !== '' && message !== undefined) {
      const response = await updateStatus(storeId, orderId, buyerId, status, message);

      if (response) {
        snackbar.enqueueSnackbar('Order status updated.', { variant: 'success' });
      } else {
        snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
      }
      setLoading(false);
    }
  };

  if (buyerId === '') {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {orderStatus === 'REJECTED' && (
        <Box paddingY={1}>
          <Typography variant="body2" color="error">
            ORDER REJECTED
          </Typography>
        </Box>
      )}
      {orderStatus === 'CANCELLED' && (
        <Box paddingY={1}>
          <Typography variant="body2" color="error">
            ORDER CANCELLED
          </Typography>
        </Box>
      )}
      {orderStatus === 'CLOSED' && (
        <Box paddingY={1}>
          <Typography variant="body2" className={classes.successText}>
            ORDER CLOSED
          </Typography>
        </Box>
      )}

      {orderStatus === 'PLACED' && (
        <Dialog dialogProps={{ fullWidth: true, maxWidth: 'xs' }}>
          <Button variant="outlined" color="default" disableElevation={true} size="small" className={classes.btns}>
            Reject
          </Button>
          <Dialog.DialogContent>
            <Typography variant="body1"> Reason for rejection : </Typography>
            <TextField type="text" variant="outlined" multiline={true} rowsMax="6" value={message} fullWidth={true} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)} />
          </Dialog.DialogContent>
          <Dialog.DialogActions onSave={() => callUpdateStatusWithMsg('REJECTED')} onCancel={() => setMessage('')} saveBtnText="OK" disableSaveBtn={message.trim() === ''} />
        </Dialog>
      )}

      {orderStatus === 'ACCEPTED' && !(orderType === OrderType.Delivery && deliveryProviders === DeliveryProviders.DoordashClassic) && (
        <Box flex={1}>
          <Dialog dialogProps={{ fullWidth: true, maxWidth: 'xs' }}>
            <Button variant="outlined" color="default" disableElevation={true} size="small" className={classes.btns}>
              Cancel
            </Button>
            <Dialog.DialogContent>
              <Typography variant="body1"> Reason for cancellation : </Typography>
              <TextField
                type="text"
                variant="outlined"
                multiline={true}
                rowsMax="6"
                value={message}
                fullWidth={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
              />
            </Dialog.DialogContent>
            <Dialog.DialogActions onSave={() => callUpdateStatusWithMsg('CANCELLED')} onCancel={() => setMessage('')} saveBtnText="OK" disableSaveBtn={message.trim() === ''} />
          </Dialog>
        </Box>
      )}

      {orderStatus === 'PLACED' && !isFutureOrder && (
        <Button variant="contained" color="primary" disableElevation={true} size="small" className={classes.btns} onClick={() => callUpdateStatus('ACCEPTED')}>
          Confirm
        </Button>
      )}

      {orderStatus === 'PLACED' && isFutureOrder && (
        <ConfirmDialog message="This is a future order. Are you sure you want to accept the order?" onYes={() => callUpdateStatus('ACCEPTED')}>
          <Button variant="contained" color="primary" disableElevation={true} size="small" className={classes.btns}>
            Confirm
          </Button>
        </ConfirmDialog>
      )}

      {orderStatus === 'ACCEPTED' && !(orderType === OrderType.Delivery && deliveryProviders === DeliveryProviders.DoordashClassic) && (
        <Button
          variant="contained"
          color="primary"
          disableElevation={true}
          size="small"
          className={`${classes.btns} ${classes.successBtn}`}
          onClick={() => callUpdateStatus('READY')}
          disabled={isFutureOrder}>
          Ready
        </Button>
      )}

      {orderStatus === 'READY' && (
        <Button variant="contained" color="secondary" disableElevation={true} size="small" className={classes.btns} onClick={() => callUpdateStatus('CLOSED')}>
          Close
        </Button>
      )}

      {orderStatus !== 'PLACED' && <Print onPrint={onPrint} />}
    </Box>
  );
};

export default ActionButtons;
