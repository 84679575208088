import React, { useState, useEffect } from 'react';
import { Dialog, Box, TextField, Button, Typography, Radio } from '@material-ui/core';
import { setLocalStorage, getLocalStorage, removeLocalStorage } from 'util/storage';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './style';
import IpAddressField from './IpAddressField';
import { useStore } from 'store';
import Status from './Status';
import DescriptionIcon from '@material-ui/icons/Description';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const PrinterDialog = ({ open, onClose, onSubmit }: IProps) => {
  const classes = useStyles();

  const [step, setStep] = useState(0);

  const [ip, setIp] = useState('');

  const [printerType, setPrinterType] = useState<'EPSON' | 'REGULAR'>('EPSON');

  const { dispatch } = useStore();

  const [error, setError] = useState(false);

  const nextStep = () => {
    setStep((oldVal: number) => {
      return oldVal + 1;
    });
  };

  const prevStep = () => {
    setStep((oldVal: number) => {
      return oldVal - 1;
    });
  };

  const handlePrev = () => {
    prevStep();
  };

  const handleNext = () => {
    if (step === 0 && printerType === 'REGULAR') {
      setStep(2);
      removeLocalStorage('printIp');
      setLocalStorage('regularPrinter', 'true');
    } else if (step === 1) {
      if (ip.trim() !== '' && !error) {
        setLocalStorage('printIp', ip);
        setLocalStorage('regularPrinter', 'false');
        setStep(2);
      }
    } else {
      nextStep();
    }
  };

  const handleClose = () => {
    if (printerType === 'REGULAR') {
      dispatch({
        type: 'SET_PING',
        payload: false
      });
      setStep(0);
    } else {
      dispatch({
        type: 'SET_PING',
        payload: true
      });
      setStep(1);
    }
    onSubmit();
    onClose();
  };

  useEffect(() => {
    const printIp = getLocalStorage('printIp');

    if (printIp && printIp !== '') {
      setPrinterType('EPSON');
      setIp(printIp);
      setStep(1);
    }
  }, []);

  const printIp = getLocalStorage('printIp');

  const hostName = window ? (window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://' + window.location.hostname) : '';

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <Box padding={2}>
        {step === 0 && (
          <Box>
            <Typography variant="body1">Please select your printer</Typography>
            <Box display="flex" alignItems="center">
              <Radio
                checked={printerType === 'EPSON'}
                onChange={(e: any, checked) => {
                  if (checked) {
                    setPrinterType('EPSON');
                  }
                }}
              />
              <Typography variant="body2">Epson Tm-m30 (Thermal POS printer)</Typography>(
              <Box paddingY={1} display="flex" alignItems="center">
                <DescriptionIcon fontSize="small" />
                <a href={hostName + '/doc/PrinterSetupInstructions.pdf'} download="PrinterSetupInstructions">
                  Setup Guide
                </a>
              </Box>
              )
            </Box>
            <Box display="flex" alignItems="center">
              <Radio
                checked={printerType === 'REGULAR'}
                onChange={(e: any, checked) => {
                  if (checked) {
                    setPrinterType('REGULAR');
                  }
                }}
              />
              <Typography variant="body2">Regular (Laser / Ink printer)</Typography>
            </Box>
          </Box>
        )}

        {step === 1 && (
          <Box paddingY={1}>
            <Typography variant="body2">Please enter IP address of your printer:</Typography>
            <IpAddressField value={ip} onChange={setIp} onError={(err: boolean) => setError(err)} />
            {printIp && <Status />}
          </Box>
        )}
        {step === 2 && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box paddingY={1}>
              <CheckCircleIcon className={classes.successIcon} />
            </Box>
            <Box paddingY={1}>
              <Typography variant="h6">Printer setup done</Typography>
            </Box>
            <Box paddingY={1}>
              <Button variant="contained" color="secondary" disableElevation={true} onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        )}
        <Box display="flex" alignItems="center" width="100%">
          {step !== 0 && step !== 2 && (
            <Box>
              <Button variant="contained" color="secondary" disableElevation={true} onClick={handlePrev}>
                {printIp ? 'Settings' : 'Prev'}
              </Button>
            </Box>
          )}
          {step !== 2 && (
            <Box marginLeft="auto" display="flex">
              <Box paddingX={1}>
                <Button variant="contained" color="secondary" disableElevation={true} onClick={onClose}>
                  Close
                </Button>
              </Box>
              <Button variant="contained" color="secondary" disableElevation={true} onClick={handleNext}>
                Next
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default PrinterDialog;
