import { useEffect, useState } from 'react';
import Logger from 'util/logger';
import packageJSON from '../../package.json';

(global as any).appVersion = packageJSON.version;

// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (latestVersion: string, previousVersion: string) => {
  const versionsA = latestVersion.split(/\./g);

  const versionsB = previousVersion.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());

    if (a === b) {
      continue;
    }

    return a > b || isNaN(b);
  }

  return false;
};

const useClearCache = () => {
  const [state, setState] = useState({
    loading: true,
    isLatestVersion: false
  });

  const refetchCacheAndReload = () => {
    Logger.log('Clearing cache and hot reloading ... ');

    if (window.caches) {
      window.caches
        .keys()
        .then((names) => {
          for (const name of names) {
            window.caches
              .delete(name)
              .then(() => {
                // delete browser cache and hard reload
                window.location.reload();
              })
              .catch(() => Logger.log('Error in deleting caches'));
          }
        })
        .catch((error) => {
          Logger.log(error);
        });
    }
  };

  useEffect(() => {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;

        const currentVersion = (global as any).appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

        if (shouldForceRefresh) {
          Logger.log(`We have a new version - ${latestVersion}. Should force refresh`);

          setState((prev) => ({ ...prev, loading: false, isLatestVersion: false }));
        } else {
          Logger.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);

          setState((prev) => ({ ...prev, loading: false, isLatestVersion: true }));
        }
      })
      .catch(() => {
        Logger.log('Fetch meta.json failed');
      });
  }, []);

  return {
    state,
    refetchCacheAndReload
  };
};

export default useClearCache;
