import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  scrollContainer: {
    width: '100%',
    height: '90vh',
    position: 'relative'
  },
  container: {
    height: '100%',
    position: 'absolute'
  },
  item: {
    padding: theme.spacing(1)
  },
  cardContainer: {
    padding: theme.spacing(2)
  },
  cardItem: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  divider: {
    height: '5px'
  },
  expansionPanel: {
    padding: 0,
    margin: theme.spacing(1, 0),
    boxShadow: 'none',
    minHeight: 'unset',
    backgroundColor: 'transparent',
    '&::before': {
      height: 0
    }
  },
  expansionSummary: {
    margin: 0,
    padding: theme.spacing(0, 1),
    borderBottom: 'solid black 1px',
    '&.Mui-expanded': {
      minHeight: '45px'
    },
    '& .Mui-expanded': {
      margin: 0
    },
    '& .MuiIconButton-edgeEnd': {
      padding: 0,
      margin: 0
    }
  },
  expansionDetails: {
    padding: 0,
    margin: 0
  },
  loadingIcon: {
    height: '80vh',
    width: '100%',
    marginTop: '-20px',
    marginLeft: '-20px',
    position: 'fixed',
    transform: 'translate(50%,50%)'
  },
  redTextColor: {
    color: theme.palette.error.main
  },
  greenTextColor: {
    color: theme.palette.success.main
  },
  boldText: {
    fontWeight: 'bolder'
  },
  cancelIcon: {
    color: theme.palette.error.main
  },
  scroll1: {
    position: 'absolute',
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  scroll2: {
    position: 'absolute',
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
    right: 0,
    backgroundColor: theme.palette.grey[300],
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  adjustReason: {
    flexBasis: '50%'
  },
  priceItem: {
    padding: theme.spacing(0.5)
  },
  priceStriked: {
    fontWeight: 'bolder',
    color: grey[500],
    textDecoration: 'line-through'
  },
  priceDiscount: {
    background: theme.palette.primary.main,
    color: theme.palette.grey[800],
    padding: theme.spacing(0, 0.5),
    borderRadius: '2px',
    fontWeight: 500
  },
  homeIcon: {
    paddingRight: theme.spacing(0.5)
  }
}));
