import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  printbtn: {
    margin: theme.spacing(1)
  },
  onlineStatus: {
    backgroundColor: theme.palette.success.main
  },
  offlineStatus: {
    backgroundColor: theme.palette.error.main
  }
}));
