import React, { useState } from 'react';
import { Box, Button, Typography, TextField } from '@material-ui/core';
import { useStyles } from '../style';
import { useUpdateOrderStatus } from 'graphql/hooks/orders.hooks';
import { useHistory } from 'react-router-dom';
import { ConfirmDialog, Dialog } from '@lokobee/lokobee-ui';
import { useSnackbar } from 'notistack';

interface IProps {
  orderId: string;
  orderStatus: string;
  buyerId: string;
  restaurantId: string;
  printOrderDetails?: () => void;
  disableReadyBtn?: boolean;
}

type STATUS_TYPES = 'ACCEPTED' | 'REJECTED' | 'READY' | 'CLOSED' | 'CANCELLED';

const ActionButtons = ({ orderId, orderStatus, buyerId, restaurantId, printOrderDetails, disableReadyBtn }: IProps) => {
  const classes = useStyles();

  const { updateStatus } = useUpdateOrderStatus();

  const [message, setMessage] = useState('');

  const isNew = orderStatus === 'PLACED' ? true : false;

  const hasAccepted = orderStatus === 'ACCEPTED' ? true : false;

  const isReady = orderStatus === 'READY';

  const history = useHistory();

  const snackbar = useSnackbar();

  const [openPrintDialog, setOpenPrintDialog] = useState(false);

  if (buyerId === '') {
    return null;
  }

  const _updateStatus = async (status: STATUS_TYPES) => {
    const response = await updateStatus(restaurantId, orderId, buyerId, status);

    if (response) {
      if (status === 'ACCEPTED') {
        setOpenPrintDialog(true);
      } else {
        history.push('/' + restaurantId + '/home');
      }
    } else {
      snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }
  };

  const _updateStatusWithMsg = async (status: STATUS_TYPES) => {
    if (message !== '' && message !== undefined) {
      const response = await updateStatus(restaurantId, orderId, buyerId, status, message);

      if (response) {
        history.push('/' + restaurantId + '/home');
      } else {
        snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
      }
    }
  };

  return (
    <Box display="flex" width="100%" paddingY={1}>
      {isNew && (
        <Box flex={1} padding={1}>
          <Dialog dialogProps={{ fullWidth: true, maxWidth: 'xs' }}>
            <Button variant="contained" className={classes.errorBtn} fullWidth={true}>
              Reject Order
            </Button>
            <Dialog.DialogContent>
              <Typography variant="body1"> Reason for rejection : </Typography>
              <TextField
                type="text"
                variant="outlined"
                multiline={true}
                rowsMax="6"
                value={message}
                fullWidth={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
              />
            </Dialog.DialogContent>
            <Dialog.DialogActions onSave={() => _updateStatusWithMsg('REJECTED')} onCancel={() => setMessage('')} saveBtnText="OK" disableSaveBtn={message.trim() === ''} />
          </Dialog>
        </Box>
      )}
      {hasAccepted && (
        <Box flex={1} padding={1}>
          <Dialog dialogProps={{ fullWidth: true, maxWidth: 'xs' }}>
            <Button variant="contained" className={classes.errorBtn} fullWidth={true}>
              Cancel Order
            </Button>
            <Dialog.DialogContent>
              <Typography variant="body1"> Reason for cancellation : </Typography>
              <TextField
                type="text"
                variant="outlined"
                multiline={true}
                rowsMax="6"
                value={message}
                fullWidth={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
              />
            </Dialog.DialogContent>
            <Dialog.DialogActions onSave={() => _updateStatusWithMsg('CANCELLED')} onCancel={() => setMessage('')} saveBtnText="OK" disableSaveBtn={message.trim() === ''} />
          </Dialog>
        </Box>
      )}
      {isNew && (
        <Box flex={1} padding={1}>
          <ConfirmDialog onYes={() => _updateStatus('ACCEPTED')} message="Are you sure if you want to accept this order?">
            <Button variant="contained" color="secondary" fullWidth={true}>
              Confirm Order
            </Button>
          </ConfirmDialog>
        </Box>
      )}
      {hasAccepted ? (
        <Box flex={1} padding={1}>
          <ConfirmDialog onYes={() => _updateStatus('READY')} message="Are you sure if you want to move this order to ready?">
            <Button variant="contained" color="secondary" fullWidth={true} disabled={disableReadyBtn}>
              Ready for pickup
            </Button>
          </ConfirmDialog>
        </Box>
      ) : null}
      {isReady && (
        <Box flex={1} padding={1}>
          <ConfirmDialog onYes={() => _updateStatus('CLOSED')} message="Are you sure want to close this order?">
            <Button variant="contained" color="secondary" fullWidth={true}>
              Close Order
            </Button>
          </ConfirmDialog>
        </Box>
      )}
      <Dialog dialogProps={{ fullWidth: true, maxWidth: 'xs' }} open={openPrintDialog} setOpen={setOpenPrintDialog}>
        <Dialog.DialogContent>
          <Typography variant="body1">Do you want to print order details ? </Typography>
        </Dialog.DialogContent>
        <Dialog.DialogActions onSave={printOrderDetails ? printOrderDetails : () => {}} onCancel={() => history.push('/' + restaurantId + '/home')} saveBtnText="YES" />
      </Dialog>
    </Box>
  );
};

export default ActionButtons;
