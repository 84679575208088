import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  btns: {
    margin: theme.spacing(1)
  },
  printIcon: {
    margin: theme.spacing(0, 1),
    cursor: 'pointer'
  },
  successBtn: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  successText: {
    color: theme.palette.success.main
  },
  inputFiled: {
    width: theme.spacing(10),
    // height: theme.spacing(10),
    margin: 0,
  },
  otpInputField:{
    width:theme.spacing(15),
    // margin: '0 1rem',
    fontSize: '2rem',
    borderRadius: '4px',
    borderColor: 'transparent',
    borderBottomColor: '#ffc131',
    borderBottomWidth: 'thick',
  // border: 1px solid rgba(0,0,0,0.3);
  },
  otpInputFielderror: {
    border: '1px solid red',  
  }
}));
