import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',

    display: 'flex',
    flexDirection: 'column'
  }
}));
