import React from 'react';
import { Box, Button, Dialog, Switch, Typography, FormControlLabel, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useUpdateGroceryStoreOrderPause } from 'graphql/hooks/groceryStore.hooks';

interface IProps {
  takeOut: boolean;
  delivery: boolean;
  open: boolean;
  onClose: () => void;
}

export const useStyles = makeStyles((theme) => ({
  switch: {
    marginRight: theme.spacing(1)
  }
}));

const OrderPauseDialog = ({ takeOut, delivery, open, onClose }: IProps) => {
  const { updateGrocerySuspendedOrderStatus } = useUpdateGroceryStoreOrderPause();

  const snackbar = useSnackbar();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      takeOutPause: takeOut,
      deliveryPause: delivery
    },
    enableReinitialize: true,

    onSubmit: async (formValues) => {
      const pauseData = [];

      const { takeOutPause, deliveryPause } = formik.values;

      const now = moment().format('YYYY-MM-DD');

      const pauseStr = 'PAUSE';
      const resumeStr = 'RESUME';

      if (takeOutPause) {
        pauseData.push({
          type: 'TAKEOUT',
          status: pauseStr,
          orderPauseDate: now
        });
      } else {
        pauseData.push({
          type: 'TAKEOUT',
          status: resumeStr,
          orderPauseDate: now
        });
      }
      if (deliveryPause) {
        pauseData.push({
          type: 'DELIVERY',
          status: pauseStr,
          orderPauseDate: now
        });
      } else {
        pauseData.push({
          type: 'DELIVERY',
          status: resumeStr,
          orderPauseDate: now
        });
      }

      const response = await updateGrocerySuspendedOrderStatus(pauseData);

      if (response) {
        snackbar.enqueueSnackbar('Pause status updated succesfully.', { variant: 'success' });
        onClose();
      } else {
        snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
      }
    }
  });

  const { values } = formik;

  const { takeOutPause, deliveryPause } = values;

  return (
    <Dialog open={open} maxWidth="md">
      <Box padding={2}>
        <Typography variant="h6">Pause</Typography>
        <Typography variant="body2">You can temporarily pause ordering till the end of the day.</Typography>
        <Typography variant="body2">You can again restart ordering by disabling the pause.</Typography>
        <Box paddingY={1} display="flex" alignItems="center" justifyContent="center">
          <FormControlLabel className={classes.switch} control={<Switch name="takeOutPause" checked={takeOutPause} onChange={formik.handleChange} />} label="Pause takeout" />
          <FormControlLabel className={classes.switch} control={<Switch name="deliveryPause" checked={deliveryPause} onChange={formik.handleChange} />} label="Pause delivery" />
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={takeOutPause && deliveryPause}
                onChange={(e: any, val: boolean) => {
                  formik.setValues({
                    takeOutPause: val,
                    deliveryPause: val
                  });
                }}
              />
            }
            label="Pause all"
          />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Box paddingX={1}>
            <Button variant="contained" color="primary" disableElevation={true} onClick={() => formik.submitForm()}>
              Save
            </Button>
          </Box>
          <Box paddingX={1}>
            <Button variant="outlined" color="default" onClick={() => onClose()}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default OrderPauseDialog;
