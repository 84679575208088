import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { config } from './config';

interface IFirebase {
  getAuth: () => any;
}

class Firebase implements IFirebase {
  constructor() {
    try {
      if (!firebase.apps.length) {
        firebase.initializeApp(config);
      }
    } catch (e) {
      console.log(e);
    }
  }

  getAuth = () => {
    return firebase.auth;
  };

  getDb = () => {
    return firebase.database();
  };

  /**
   * Sign out current user
   */
  signOut = () => {
    const { auth } = firebase;

    return auth().signOut();
  };

  signInWithCustomToken = (token: string) => {
    const { auth } = firebase;

    return auth().signInWithCustomToken(token);
  };
}

export default Firebase;
