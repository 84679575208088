import { gql } from 'apollo-boost';

export default gql`
  query getGroceryOrderByOrderId($input: GetGroceryOrderByOrderIdInput!) {
    getGroceryOrderByOrderId(input: $input) {
      id
      status
      expectTime
      bizExpectTime
      orderNumber
      note
      lokobeeFee
      adjustAmount
      adjustReason
      totalAfterAdjust
      totalTax
      deliveryNote
      adjustReason
      isDiscounted
      redeemPoints
      seller {
        id
        name
        address {
          text
        }
        phone
      }
      deliveryAddress {
        text
      }
      timeLines {
        msg
        status
      }
      deliveryDistance
      buyer {
        phoneNumber
        email
        displayName
      }
      deliveryProvider
      orderEmail
      orderPhoneNumber
      paymentType
      type
      createdAt

      subTotal
      total
      tip
      deliveryFee
      couponDiscount
      lokobeeFee
      adjustAmount
      totalAfterAdjust
      coupon {
        code
      }
      items {
        itemId
        title
        brand
        upcCode
        size
        categoryId
        subCategoryId
        brand
        rewardPoints
        price
        status
        description
        taxMode
        count
        isReward
        isDiscounted
        isAlcohol
      }
    }
  }
`;
