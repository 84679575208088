import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from 'fbase';

export const AuthContext = React.createContext<any>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);

  const [idToken, setIdToken] = useState<string>('');

  const [loading, setLoading] = useState(true);

  /**
   * Get firebase reference
   */
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    const auth = firebase && firebase.getAuth();

    auth &&
      auth().onAuthStateChanged(async (user) => {
        setCurrentUser(user);
        if (user) {
          const idToken = await user.getIdToken();
          if (idToken) {
            setIdToken(idToken);
          }
        }

        if (loading) {
          setLoading(false);
        }
      });
  }, [currentUser, firebase, loading]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        idToken
      }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useIdToken = () => {
  const idToken = useContext(AuthContext);

  if (idToken) {
    return idToken;
  }

  return null;
};
