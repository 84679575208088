import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useGetGroceryWaitMins, useUpdateGroceryWaitMins } from 'graphql/hooks/groceryStore.hooks';
import { useStore } from 'store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Dialog, TextField, Typography } from '@material-ui/core';
import { useStyles } from './style';

type minuteType = 'takeout' | 'delivery' | 'packingTime';

interface IInitialValue {
  takeOutWaitMins: number;
  deliveryWaitMins: number;
  packingWaitMins: number;
}

interface IProps {
  storeId: string;
}

const GroceryWaitMins = ({ storeId }: IProps) => {
  const { data } = useGetGroceryWaitMins();

  const classes = useStyles();

  const { updateGroceryWaitMins } = useUpdateGroceryWaitMins();

  const [openDialog, setOpenDialog] = useState(false);

  const [activeOrderType, setactiveOrderType] = useState<minuteType>('takeout');

  const snackbar = useSnackbar();

  const {
    state: { groceryOpsSettings }
  } = useStore();

  const { enableTakeoutOrder = false, enableDeliveryOrder = false } = groceryOpsSettings || { enableTakeoutOrder: false, enableDeliveryOrder: false };

  const [initialValues, setInitialValues] = useState<IInitialValue>({
    takeOutWaitMins: 0,
    deliveryWaitMins: 0,
    packingWaitMins: 0
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      takeOutWaitMins: Yup.number()
        .required('Required')
        .min(0),
      deliveryWaitMins: Yup.number()
        .required('Required')
        .min(0),
      packingWaitMins: Yup.number()
        .required('Required')
        .min(0)
    }),
    onSubmit: async (formValues) => {
      const { takeOutWaitMins, deliveryWaitMins, packingWaitMins } = formValues;

      const response = await updateGroceryWaitMins(takeOutWaitMins, deliveryWaitMins, packingWaitMins);

      setOpenDialog(false);

      if (response) {
        snackbar.enqueueSnackbar('Wait time updated succesfully.', { variant: 'success' });
      } else {
        snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (data && data.getGroceryWaitMinutes) {
      setInitialValues({
        takeOutWaitMins: data.getGroceryWaitMinutes.takeoutWaitMinutes || 0,
        deliveryWaitMins: data.getGroceryWaitMinutes.deliveryWaitMinutes || 0,
        packingWaitMins: data.getGroceryWaitMinutes.packingWaitMins || 0
      });
    }
  }, [data]);

  const handleClick = (type: minuteType) => {
    setactiveOrderType(type);
    setOpenDialog(true);
  };

  const { values, handleBlur, handleChange } = formik;

  if (data && data.getGroceryWaitMinutes) {
    const { takeOutWaitMins, deliveryWaitMins, packingWaitMins } = formik.values;

    return (
      <Box>
        {enableTakeoutOrder && (
          <Button className={classes.btns} size="small" onClick={() => handleClick('takeout')}>
            TAKEOUT{`(${takeOutWaitMins || 0}mins)`}
          </Button>
        )}
        {enableDeliveryOrder && (
          <Button className={classes.btns} size="small" onClick={() => handleClick('delivery')}>
            DELIVERY{`(${deliveryWaitMins || 0}mins)`}
          </Button>
        )}

        <Button className={classes.btns} size="small" onClick={() => handleClick('packingTime')}>
          PACKING TIME{`(${packingWaitMins || 0}mins)`}
        </Button>

        <Dialog open={openDialog}>
          <Box padding={3} textAlign="center">
            {activeOrderType === 'takeout' && <Typography variant="body1"> Update takeout time</Typography>}
            {activeOrderType === 'delivery' && <Typography variant="body1"> Update delivery time</Typography>}
            {activeOrderType === 'packingTime' && <Typography variant="body1"> Update cooking time</Typography>}
            <Box paddingY={1}>
              {activeOrderType === 'takeout' && (
                <TextField type="number" variant="outlined" value={values.takeOutWaitMins} name="takeOutWaitMins" onChange={handleChange} onBlur={handleBlur} label="Takeout wait time (mins)" />
              )}
              {activeOrderType === 'delivery' && (
                <TextField type="number" variant="outlined" value={values.deliveryWaitMins} name="deliveryWaitMins" onChange={handleChange} onBlur={handleBlur} label="Delivery time (mins)" />
              )}
              {activeOrderType === 'packingTime' && (
                <TextField type="number" variant="outlined" value={values.packingWaitMins} name="packingWaitMins" onChange={handleChange} onBlur={handleBlur} label="Packing time (mins)" />
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              <Box padding={1}>
                <Button variant="contained" color="primary" disableElevation={true} onClick={() => formik.submitForm()}>
                  Save
                </Button>
              </Box>
              <Box padding={1}>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    setOpenDialog(false);
                    formik.resetForm();
                  }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </Box>
    );
  }

  return null;
};

export default GroceryWaitMins;
