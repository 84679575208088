import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  footerContainer: {
    left: 0,
    bottom: 0,
    width: '100%'
  },
  link: {
    cursor: 'pointer',
    margin: theme.spacing(0, 2)
  },
  logo: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  colorSpan: {
    margin: theme.spacing(0, 0.5),
    color: theme.palette.primary.main
  }
}));
