import { gql } from 'apollo-boost';

export default gql`
  query getGroceryStore($input: getGroceryStoreInput!) {
    getGroceryStore(input: $input) {
      id
      timezone
      enableTakeoutOrder
      enableDeliveryOrder
      name
    }
  }
`;
