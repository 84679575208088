import React, { useState, useEffect } from 'react';
import { useStyles } from '../style';
import { Box, Card, Divider, Typography, Button } from '@material-ui/core';
import Moment from 'moment';
import { getNewOrderPickerBackgroundColor, getNewOrderTimeDifference } from './utils';
import { map } from 'lodash';
import { useCloseTableServiceOrder } from 'graphql/hooks/orders.hooks';
import { useSnackbar } from 'notistack';
import { useStore } from 'store';

interface IProps {
  id: string;
  tableName: string;
  name: string;
  createdAt: string | number;
  updatedAt: string | number;
  items: {
    title: string;
    count: number;
  }[];
  status: string;
}

const TableServiceOrderCard = ({ id, items, createdAt, updatedAt, name, tableName, status }: IProps) => {
  const classes = useStyles();

  const {
    state: { restaurantId }
  } = useStore();

  const snackbar = useSnackbar();

  const [timeDifference, setTimeDifference] = useState(0);

  const [receivedIn, setReceivedIn] = useState<null | string>(null);

  const { closeOrder } = useCloseTableServiceOrder();

  useEffect(() => {
    if (status === 'PLACED') {
      const interval = setInterval(() => {
        const currentMoment = Moment();

        const createdAtMoment = Moment(createdAt);

        setReceivedIn(createdAtMoment.toNow(true).toString());

        const difference = Math.round(currentMoment.diff(createdAtMoment, 's') / 60);

        const _timeDiffernce = getNewOrderTimeDifference(difference);

        setTimeDifference(_timeDiffernce);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      const createdAtMoment = Moment(createdAt);
      const updatedAtMoment = Moment(updatedAt);

      setReceivedIn(Moment.duration(createdAtMoment.diff(updatedAtMoment)).humanize());

      const difference = Math.round(updatedAtMoment.diff(createdAtMoment, 's') / 60);

      const _timeDiffernce = getNewOrderTimeDifference(difference);

      setTimeDifference(_timeDiffernce);
    }
  }, [createdAt, status, timeDifference, updatedAt]);

  const _closeOrder = async () => {
    const response = await closeOrder(restaurantId, id);

    if (response) {
      snackbar.enqueueSnackbar('Order closed.', { variant: 'success' });
    } else {
      snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }
  };

  const pickerClassName = timeDifference ? (classes as any)[getNewOrderPickerBackgroundColor(timeDifference)] : null;

  return (
    <Box width="100%" padding={0} margin={0}>
      <Card className={status === 'CLOSED' ? classes.tableServiceClosed : ''} elevation={status === 'CLOSED' ? 0 : 1}>
        <Box padding={1} display="flex" alignItems="center">
          <Box paddingX={1}>
            <Typography variant="body2">
              <span className={classes.boldText}>{tableName}</span>
            </Typography>
          </Box>
          <Box paddingX={1}>
            <Typography variant="body2">
              <span className={classes.boldText}>{name}</span>
            </Typography>
          </Box>

          <Box flexGrow={1} display="flex" justifyContent="flex-end">
            {receivedIn && (
              <Box className={`${classes.picker} ${pickerClassName}`} width="max-content" marginX={1}>
                <Typography variant="body2">
                  {receivedIn} {status === 'PLACED' ? 'ago' : ''}
                </Typography>
              </Box>
            )}
            {status !== 'CLOSED' && (
              <Button variant="contained" color="default" disableElevation={true} size="small" onClick={_closeOrder}>
                Close
              </Button>
            )}
          </Box>
        </Box>

        <Divider className={classes.divider} />
        <Box paddingY={1} paddingX={2}>
          <Typography variant="body2">
            {map(items, ({ title, count }) => {
              return ' ' + count + 'x ' + title;
            }).join(',')}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default TableServiceOrderCard;
