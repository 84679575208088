import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from '../style';
import { Link } from 'react-router-dom';
import restaurant_empty_icon from 'assets/img/restaurant-empty-icon.png';
interface IProps {
  id: string;
  imageUrl: string;
  itemName: string;
  isRestaurant: boolean;
}
const RenderItem: React.FC<IProps> = ({ id, imageUrl, itemName, isRestaurant }) => {
  const classes = useStyles();
  return (
    <Grid xs={12} sm={4} md={3} item={true} className={classes.restaurantContainer}>
      <Link
        to={isRestaurant ? '/' + id + '/home' : '/grocerystore/' + id + '/home'}
        className={classes.link}
        onClick={() => {
          if (isRestaurant) {
            localStorage.setItem('ops_rest_id', id);
          } else {
            localStorage.setItem('ops_store_id', id);
          }
        }}>
        <Box boxShadow={2}>
          <Box className={classes.restaurantImg}>
            <img src={imageUrl ? imageUrl : restaurant_empty_icon} width={imageUrl ? '100%' : '50%'} alt="" />
          </Box>
          <Box padding={2}>
            <Typography variant="body2">{itemName}</Typography>
          </Box>
        </Box>
      </Link>
    </Grid>
  );
};

export default RenderItem;
