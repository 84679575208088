import React, { useState, useContext, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { FirebaseContext } from 'fbase';
import { useStore } from 'store';
import moment from 'moment';
import { useObject } from 'react-firebase-hooks/database';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 600
  }
}));
export interface IPauseData {
  DINING?: {
    pauseDate: string;
    status: 'pause' | 'resume';
  };
  TAKEOUT?: {
    pauseDate: string;
    status: 'pause' | 'resume';
  };
  DELIVERY?: {
    pauseDate: string;
    status: 'pause' | 'resume';
  };
}

interface IInitialValue {
  diningPause: boolean;
  takeOutPause: boolean;
  deliveryPause: boolean;
}

const OrderPauseDisplay = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const { firebase } = useContext(FirebaseContext);

  const [snapshot] = useObject(firebase?.getDb().ref(`/orderPause/${restaurantId}/`));

  const classes = useStyles();

  const pauseData: IPauseData | null | undefined = snapshot?.val();

  const now = moment().format('YYYY-MM-DD');

  const diningPause = pauseData && pauseData.DINING ? pauseData.DINING?.status === 'pause' && now === pauseData.DINING?.pauseDate : false;

  const takeOutPause = pauseData && pauseData.TAKEOUT ? pauseData.TAKEOUT?.status === 'pause' && now === pauseData.TAKEOUT?.pauseDate : false;

  const deliveryPause = pauseData && pauseData.DELIVERY ? pauseData.DELIVERY?.status === 'pause' && now === pauseData.DELIVERY?.pauseDate : false;

  const pausedOrderTypes = [];

  if (takeOutPause) {
    pausedOrderTypes.push('Takeout');
  }

  if (diningPause) {
    pausedOrderTypes.push('Dining');
  }

  if (deliveryPause) {
    pausedOrderTypes.push('Delivery');
  }

  if (pausedOrderTypes.length) {
    return (
      <Typography className={classes.text} variant="body1" color="error">
        Paused - {pausedOrderTypes.join(', ')}
      </Typography>
    );
  }

  return null;
};

export default OrderPauseDisplay;
