import Big from 'big.js';
import { formatPhoneNumber, convertPrice, applyDiscount, convertPriceTo } from 'util/number';
import { groupBy, map, filter } from 'lodash';
import moment from 'moment';
import { getLocalStorage } from 'util/storage';
import { DeliveryProviders } from 'generated/custom';

// Add dish extras price to dish price for display purpose
export const parseOrderItems = (items: any, isOrderDiscounted: boolean = false) => {
  if (items && items.length) {
    return items.map((item: any) => {
      const { dishPrice } = item;

      const dishType = item.dishType || 'REGULAR';

      if (dishType === 'REGULAR') {
        let finalPrice = Big(0);

        let strikedPrice = Big(0);

        let discountPercent = null;

        const { dishExtras, isDiscounted, ...rest } = item;

        const {
          price: { intValue, shift },
          discountAmount
        } = dishPrice || { price: { intValue: 0, shift: 0 }, discountAmount: 0 };

        finalPrice = Big(convertPrice(intValue, shift));

        if (!!discountAmount && isDiscounted && isOrderDiscounted) {
          const discount = applyDiscount(finalPrice.toFixed(2), convertPriceTo(discountAmount, 'DOLLAR'));

          discountPercent = discount.percentage;
        }

        if (dishExtras && dishExtras.length) {
          let sum = Big(0);

          for (const extras of dishExtras) {
            const {
              dishExtraItem: {
                price: { intValue: _intValue, shift: _shift }
              }
            } = extras;
            const price = convertPrice(_intValue, _shift);

            sum = sum.plus(Big(price));
          }

          finalPrice = finalPrice.plus(sum);

          if (!!discountAmount && isDiscounted && isOrderDiscounted) {
            strikedPrice = finalPrice;

            finalPrice = finalPrice.minus(convertPriceTo(discountAmount, 'DOLLAR'));
          }

          const _extras = map(groupBy(dishExtras, 'dishExtraGroupId'), (value) => {
            if (value && value.length) {
              const { dishExtraGroupTitle } = value[0];

              const [{ text: groupTitle }] = dishExtraGroupTitle || [{ text: '' }];

              const items = map(value, ({ dishExtraItem: { title } }) => {
                const [{ text: extrasTitle }] = title || [{ text: '' }];

                return extrasTitle;
              });

              return {
                groupTitle,
                items
              };
            } else return value;
          });

          return {
            ...rest,
            discountPercent,
            strikedPrice: !!discountAmount && isDiscounted && isOrderDiscounted ? strikedPrice.toFixed(2) : null,
            dishPrice: finalPrice.toFixed(2),
            dishExtras: _extras,
            dishOriginalPrice: dishPrice
          };
        }
        if (!!discountAmount && isDiscounted && isOrderDiscounted) {
          strikedPrice = finalPrice;

          finalPrice = finalPrice.minus(convertPriceTo(discountAmount, 'DOLLAR'));
        }

        return {
          ...rest,
          discountPercent,
          strikedPrice: !!discountAmount && isDiscounted && isOrderDiscounted ? strikedPrice.toFixed(2) : null,
          dishPrice: finalPrice.toFixed(2),
          dishOriginalPrice: dishPrice
        };
      }

      if (dishType === 'PIZZA') {
        let finalPrice = Big(0.0);

        let strikedPrice = Big(0);

        let discountPercent = null;

        const { pizzaBasePrice, pizzaToppings, pizzaCheeses, pizzaSauces, pizzaCrust, isDiscounted } = item;

        const {
          price: { intValue, shift },
          discountAmount
        } = pizzaBasePrice || { price: { intValue: 0, shift: 0 }, discountAmount: 0 };

        finalPrice = finalPrice.plus(Big(convertPrice(intValue, shift)));

        if (!!discountAmount && isDiscounted && isOrderDiscounted) {
          const discount = applyDiscount(finalPrice.toFixed(2), convertPriceTo(discountAmount, 'DOLLAR'));

          discountPercent = discount.percentage;
        }

        if (pizzaCrust && pizzaCrust.price) {
          const {
            price: { intValue: crustPriceVal, shift: crustPriceShift }
          } = pizzaCrust || { price: { intValue: 0, shift: 0 } };

          finalPrice = finalPrice.plus(Big(convertPrice(crustPriceVal, crustPriceShift)));
        }

        const leftToppings: any = {
          exclusions: [],
          additions: []
        };
        const leftSauces: any = {
          exclusions: [],
          additions: []
        };
        const leftCheeses: any = {
          exclusions: [],
          additions: []
        };

        const rightToppings: any = {
          exclusions: [],
          additions: []
        };
        const rightSauces: any = {
          exclusions: [],
          additions: []
        };
        const rightCheeses: any = {
          exclusions: [],
          additions: []
        };

        let halfAndHalf = false;

        if (pizzaToppings && pizzaToppings.length) {
          pizzaToppings.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

            let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

            const excludeTitle = `No ${toppingTitle}`;

            const toppingsText = {
              title: toppingTitle,
              density: toppingDensity,
              price: toppingPriceToBeAdded
            };

            const excludeText = {
              title: excludeTitle,
              price: toppingPriceToBeAdded
            };
            const alreadyPresentRightAdd = filter(rightToppings.additions, ({ title }) => title === toppingTitle)[0];
            const alreadyPresentLeftAdd = filter(leftToppings.additions, ({ title }) => title === toppingTitle)[0];

            if (preset) {
              if (side === 'Left') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  leftToppings.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    leftToppings.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  rightToppings.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    rightToppings.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftToppings.exclusions.push(excludeText);
                rightToppings.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                leftToppings.additions.push(toppingsText);
                rightToppings.additions.push(toppingsText);
              }
            } else {
              if (side === 'Left') {
                halfAndHalf = true;
                leftToppings.additions.push(toppingsText);
                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightToppings.additions.push(toppingsText);
                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole') {
                leftToppings.additions.push(toppingsText);
                rightToppings.additions.push(toppingsText);
              }
            }
            finalPrice = finalPrice.plus(toppingPriceToBeAdded);
          });
        }

        if (pizzaSauces && pizzaSauces.length) {
          pizzaSauces.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

            let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

            const excludeTitle = `No ${toppingTitle}`;

            const toppingsText = {
              title: toppingTitle,
              density: toppingDensity,
              price: toppingPriceToBeAdded
            };

            const excludeText = {
              title: excludeTitle,
              price: toppingPriceToBeAdded
            };
            const alreadyPresentRightAdd = filter(rightSauces.additions, ({ title }) => title === toppingTitle)[0];
            const alreadyPresentLeftAdd = filter(leftSauces.additions, ({ title }) => title === toppingTitle)[0];

            if (preset) {
              /*
               * If preset
               * If left
               * If not none & not regular - add to leftAdd
               * theres possibility that it is not present in right - so add to rightExc
               * If present in right than remove from rightExc
               * vice versa for right
               */

              if (side === 'Left') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  leftSauces.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    leftSauces.additions.push(toppingsText);
                  }
                }
                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }

              if (side === 'Right') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  rightSauces.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    rightSauces.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftSauces.exclusions.push(excludeText);
                rightSauces.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                rightSauces.additions.push(toppingsText);
                leftSauces.additions.push(toppingsText);
              }
            } else {
              if (side === 'Left') {
                halfAndHalf = true;
                leftSauces.additions.push(toppingsText);
                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightSauces.additions.push(toppingsText);
                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole') {
                leftSauces.additions.push(toppingsText);
                rightSauces.additions.push(toppingsText);
              }
            }
            finalPrice = finalPrice.plus(toppingPriceToBeAdded);
          });
        }

        if (pizzaCheeses && pizzaCheeses.length) {
          pizzaCheeses.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

            let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

            const excludeTitle = `No ${toppingTitle}`;

            const toppingsText = {
              title: toppingTitle,
              density: toppingDensity,
              price: toppingPriceToBeAdded
            };

            const excludeText = {
              title: excludeTitle,
              price: toppingPriceToBeAdded
            };
            const alreadyPresentRightAdd = filter(rightCheeses.additions, ({ title }) => title === toppingTitle)[0];
            const alreadyPresentLeftAdd = filter(leftCheeses.additions, ({ title }) => title === toppingTitle)[0];

            if (preset) {
              if (side === 'Left') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  leftCheeses.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    leftCheeses.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  rightCheeses.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    rightCheeses.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftCheeses.exclusions.push(excludeText);
                rightCheeses.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                rightCheeses.additions.push(toppingsText);
                leftCheeses.additions.push(toppingsText);
              }
            } else {
              if (side === 'Left') {
                halfAndHalf = true;
                leftCheeses.additions.push(toppingsText);
                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightCheeses.additions.push(toppingsText);
                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole') {
                leftCheeses.additions.push(toppingsText);
                rightCheeses.additions.push(toppingsText);
              }
            }
            finalPrice = finalPrice.plus(toppingPriceToBeAdded);
          });
        }

        if (!!discountAmount && isDiscounted && isOrderDiscounted) {
          strikedPrice = finalPrice;

          finalPrice = finalPrice.minus(convertPriceTo(discountAmount, 'DOLLAR'));
        }

        return {
          ...item,
          dishPrice: finalPrice.toFixed(2),
          discountPercent,
          strikedPrice: !!discountAmount && isDiscounted && isOrderDiscounted ? strikedPrice.toFixed(2) : null,
          toppings: { leftToppings, rightToppings },
          sauces: { leftSauces, rightSauces },
          cheeses: { leftCheeses, rightCheeses },
          halfAndHalf,
          dishOriginalPrice: pizzaBasePrice
        };
      }

      return { ...item, dishPrice: 0, dishOriginalPrice: dishPrice };
    });
  }
  return items;
};

export const formatPriceToString = (price: any) => {
  if (!price) {
    return null;
  }

  if (price instanceof Big) {
    return price.toFixed(2).toString();
  }

  return Big(price).toFixed(2);
};

export const calculatePoints = (qty: number, points: number) => {
  if (!qty || !points) {
    return 0;
  }

  return qty * points;
};

export const printOrderDetails = (data: any, items: any[], opsSettings: any, notify: (msg: string) => void, timeDifferenceType?: string) => {
  const {
    orderNumber,
    expectTime,
    buyer: { displayName },
    orderPhoneNumber,
    subTotal,
    tip,
    total,
    tax,
    deliveryFee,
    couponDiscount,
    coupon,
    paymentType,
    type,
    tableName,
    createdAt,
    seller,
    note: orderNote,
    deliveryAddress,
    deliveryNote,
    lokobeeFee,
    redeemPoints,
    deliveryProvider,
    bizExpectTime,
    dropOffInstruction
  } = data;

  const { disableTakeoutOrderDetailDisplay = false, disableDeliveryOrderDetailDisplay = false, disableDiningOrderDetailDisplay = false } = opsSettings || {
    disableTakeoutOrderDetailDisplay: false,
    disableDeliveryOrderDetailDisplay: false,
    disableDiningOrderDetailDisplay: false
  };

  const displayBill =
    (type === 'TAKEOUT' && !disableTakeoutOrderDetailDisplay) || (type === 'DELIVERY' && !disableDeliveryOrderDetailDisplay) || (type === 'DINING' && !disableDiningOrderDetailDisplay);

  const couponCode = coupon ? coupon.code || '' : '';

  const { name, address, phone } = seller;
  const [{ text: restaurantName }] = name || [{ text: '' }];
  const { text: restaurantAddress } = address || { text: '' };

  const restaurantPhone = formatPhoneNumber(phone) ? formatPhoneNumber(phone) : phone;

  let customerPhone = '';

  if (orderPhoneNumber) {
    customerPhone = formatPhoneNumber(orderPhoneNumber) ? formatPhoneNumber(orderPhoneNumber) : orderPhoneNumber;
  } else {
    customerPhone = 'Phone no. NA';
  }

  const expectTimeMoment = moment(expectTime);
  const createdAtMoment = moment(createdAt);
  const bizExpectTimeMoment = moment(bizExpectTime);

  let orderType = '';
  if (type === 'DINING') orderType = `DINING - ${tableName}`;
  if (type === 'TAKEOUT') orderType = `Take out`;
  if (type === 'DELIVERY') orderType = `Delivery`;

  const { text: _deliveryAddress } = deliveryAddress || { text: '' };

  const lokobeeFeeNumber = lokobeeFee && lokobeeFee.intValue && lokobeeFee.shift ? parseFloat(convertPrice(lokobeeFee.intValue, lokobeeFee.shift)) : 0.0;

  const taxNumber = tax && tax.intValue && tax.shift ? parseFloat(convertPrice(tax.intValue, tax.shift)) : 0;

  const dishesGroupedByCategory = groupBy(items, 'dishCategoryTitle[0].text');

  let itemCount = 0;

  items.forEach(({ count }: any) => {
    if (count) {
      itemCount += count;
    }
  });

  const regularPrinter = getLocalStorage('regularPrinter');

  if (regularPrinter === 'true') {
    const content = `
    <html>
    <head>
    <meta name="format-detection" content="telephone=no">
    <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <style type="text/css">
      @page 
      {
        margin: 0mm; 
      }
      *{ 
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact; 
      }
      html{
        margin:0px;
        width:100%;
        display:flex;
        justify-content:center;
        font-family: 'Ubuntu', sans-serif;
      }
      body{
        margin:2px;
        margin-top:20px;
        padding:0;
        width:278.4px
      }
      h1,h2,h3,h4,h5,h6,p{
        margin:0
      }
      .flexbox{
        display:flex;
        justify-content:space-between;
        align-items:center
      }
      .customerDetails{
        padding:5px;
        font-size:10px;
      }
      .customerDetailsHeader{
        font-size:12px;
        align-items: flex-start;
        padding:1px;
      }
      .noWrap{
        white-space: nowrap;
      }
      .boldText{
        font-weight:bold
      }
      .line{
        border-top: 2px dashed black;
      }
      .dishDetails p{
        font-size:15px;
      }
    </style>
    </head>
    
    <body>
      
    <div style="width:100%">
      <div class="flexbox">
        <h2>${orderNumber.substr(orderNumber.length - 4)}</h2>
        <h2>
        ${orderType.toUpperCase()}
        </h2>
      </div>      
      ${type === 'TAKEOUT' || type === 'DELIVERY' ? `<hr class="line" />` : ``} 
      ${type === 'TAKEOUT' && timeDifferenceType ? (timeDifferenceType === 'SCHEDULED' ? `<h2>SCHEDULED</h2>` : `<h2>ASAP</h2>`) : ''}
      ${type === 'DELIVERY' ? `<h2>Scheduled</h2>` : ``}
      <h2> ${type === 'TAKEOUT' || (type === 'DELIVERY' && deliveryProvider === DeliveryProviders.Self) ? expectTimeMoment.format('ddd MMM-DD, hh:mm A').toUpperCase() : ''}     
      ${type === 'DELIVERY' && deliveryProvider === DeliveryProviders.DoordashClassic ? bizExpectTimeMoment.format('ddd MMM-DD').toUpperCase() : ''}          </h2>
      <hr class="line" />
      <div class="flexbox" style="align-items: flex-start;margin-bottom:5px">
        <p style="font-size:14px;">${restaurantName}</p>
      </div>
      
      <div class="flexbox">
        <div>
          <p style="font-size:12px;">${restaurantAddress}</p>
          <p style="font-size:12px;">${restaurantPhone}</p>
        </div>
      </div>
    
      <hr class="line" />
      <div class="customerDetails" >
        <div class="flexbox boldText customerDetailsHeader">
          <p>${displayName}</p>
          <p class="noWrap">${paymentType === 'ONLINE_STRIPE' ? 'PAID' : 'PAYMENT DUE'}</p>
        </div>
        <p style="padding:1px">${customerPhone}</p>
        <div class="flexbox" style="align-items: flex-start;padding:1px;">
          <p class="noWrap">Order#: ${orderNumber}</p>
          <p>${createdAtMoment.format('MMM-DD-YYYY HH:mm A')}</p>
        </div>
      </div>
      ${
        type === 'DELIVERY'
          ? `
        
        <div class="customerDetails" style="margin-top:5px;">
          ${deliveryAddress ? `<p><span class="boldText">Delivery Address:</span> ${_deliveryAddress}</p>` : ``}
          ${deliveryNote ? `<p><span class="boldText">Delivery Note:</span> ${deliveryNote}</p>` : ``}
          ${dropOffInstruction ? `<p><span class="boldText">Drop off instruction: </span> ${dropOffInstruction}</p>` : ``}          
        </div>
      `
          : ``
      }
     
      <div class="dishDetails">
        <hr class="line" />
        <p style="font-size:14px;"> ITEMS (${itemCount})</p>
        <hr class="line" />
        ${Object.keys(dishesGroupedByCategory)
          .map((category: string) => {
            return `
          <h5 style="padding-bottom:5px;">${category}</h5>
          <div style="padding-left:10px;">
          ${dishesGroupedByCategory[category]
            .map((item: any) => {
              const { dishTitle, count, dishPrice, dishExtras, note, dishOriginalPrice, isReward, points } = item;
              const dishType = item.dishType || 'REGULAR';

              if (dishType === 'REGULAR') {
                const [{ text: _title }] = dishTitle || [{ text: '' }];

                const [{ text: _dishSize }] = dishOriginalPrice.size || [{ text: '' }];

                const extras = map(dishExtras, (extra) => {
                  const { groupTitle, items } = extra;
                  return `${groupTitle} : ${items.join(', ')}`;
                }).join('<br />');

                return `
                <div style="padding-bottom:10px;">  
                <h5>${count}x ${_title} </h5>
                  ${_dishSize !== 'Regular' ? `<p>${_dishSize}</p>` : ``}
                  ${extras ? `<p>${extras}</p>` : ``}
                  ${note ? `<p style="font-weight:bold;display:inline;">Dish Note:&nbsp;</p>${note}` : ``}
                </div>
                `;
              }

              if (dishType === 'PIZZA') {
                const {
                  count,
                  dishTitle,
                  pizzaCrust,
                  toppings: { leftToppings, rightToppings },
                  sauces: { leftSauces, rightSauces },
                  cheeses: { leftCheeses, rightCheeses },
                  halfAndHalf,
                  pizzaBasePrice,
                  dishPrice,
                  note
                } = item;

                const [{ text: _title }] = dishTitle || [{ text: '' }];

                const { title: _dishSize } = pizzaBasePrice && pizzaBasePrice.size ? pizzaBasePrice.size : { title: '' };

                const { title: crust } = pizzaCrust || { title: '' };

                let toppingsDisplay = ``;

                if (halfAndHalf) {
                  toppingsDisplay += `
                    <p>${'LEFT HALF'}</p>
                    <p>${_title}</p>
                  `;
                  // Left Exclusions
                  if (!!leftSauces.exclusions && !!leftSauces.exclusions.length) {
                    toppingsDisplay += map(leftSauces.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons">cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }

                  if (!!leftCheeses.exclusions && !!leftCheeses.exclusions.length) {
                    toppingsDisplay += map(leftCheeses.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons">cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftToppings.exclusions && !!leftToppings.exclusions.length) {
                    toppingsDisplay += map(leftToppings.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons">cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  // Left Additions
                  if (!!leftSauces.additions && !!leftSauces.additions.length) {
                    toppingsDisplay += map(leftSauces.additions, (topping) => {
                      const { title = '', density = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftCheeses.additions && !!leftCheeses.additions.length) {
                    toppingsDisplay += map(leftCheeses.additions, (topping) => {
                      const { title = '', density = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }

                  if (!!leftToppings.additions && !!leftToppings.additions.length) {
                    toppingsDisplay += map(leftToppings.additions, (topping) => {
                      const { title = '', density = '' } = topping;

                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }

                  toppingsDisplay += `
                    <p>${'RIGHT HALF'}</p>
                    <p>${_title}</p>
                  `;

                  // Right Exclusions
                  if (!!rightSauces.exclusions && !!rightSauces.exclusions.length) {
                    toppingsDisplay += map(rightSauces.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons">cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }

                  if (!!rightCheeses.exclusions && !!rightCheeses.exclusions.length) {
                    toppingsDisplay += map(rightCheeses.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons">cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!rightToppings.exclusions && !!rightToppings.exclusions.length) {
                    toppingsDisplay += map(rightToppings.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons">cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  // right Additions
                  if (!!rightSauces.additions && !!rightSauces.additions.length) {
                    toppingsDisplay += map(rightSauces.additions, (topping) => {
                      const { title = '', density = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!rightCheeses.additions && !!rightCheeses.additions.length) {
                    toppingsDisplay += map(rightCheeses.additions, (topping) => {
                      const { title = '', density = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!rightToppings.additions && !!rightToppings.additions.length) {
                    toppingsDisplay += map(rightToppings.additions, (topping) => {
                      const { title = '', density = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }
                } else {
                  toppingsDisplay += `<p>${_title}</p>`;

                  if (!!leftSauces.exclusions && !!leftSauces.exclusions.length) {
                    toppingsDisplay += map(leftSauces.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons">cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }

                  if (!!leftCheeses.exclusions && !!leftCheeses.exclusions.length) {
                    toppingsDisplay += map(leftCheeses.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" >cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftToppings.exclusions && !!leftToppings.exclusions.length) {
                    toppingsDisplay += map(leftToppings.exclusions, (topping) => {
                      const { title = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons">cancel</i>
                            <p>${title}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  // Left Additions
                  if (!!leftSauces.additions && !!leftSauces.additions.length) {
                    toppingsDisplay += map(leftSauces.additions, (topping) => {
                      const { title = '', density = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftCheeses.additions && !!leftCheeses.additions.length) {
                    toppingsDisplay += map(leftCheeses.additions, (topping) => {
                      const { title = '', density = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftToppings.additions && !!leftToppings.additions.length) {
                    toppingsDisplay += map(leftToppings.additions, (topping) => {
                      const { title = '', density = '' } = topping;
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            ${density !== 'Regular' ? `<p>${title}<span class="boldText"> (${density}) </span></p>` : `<p>${title}</p>`}
                          </div>
                      `;
                    }).join('');
                  }
                }

                return `
                <div style="padding-bottom:10px;">  
                  <h5>${count}x Pizza</h5>
                  <div>
                    <p>${_dishSize}</p>
                    <p>${crust}</p>
                    
                    ${toppingsDisplay}
                    
                    ${note ? `<p style="font-weight:bold">Dish Note:</p><p>${note}</p>` : ``}
                  </div>
                  </div>
                `;
              }

              return ``;
            })
            .join('')}
            </div>
          <hr class="line" />
          `;
          })
          .join('')}
          
        
          ${
            orderNote
              ? `            
          <p style="font-weight:bold">Order Note:</p>
          <p>${orderNote}</p>
          <hr class="line" />
          `
              : ``
          }   
      </div>
      <div class="flexbox" style="justify-content:center;font-size:8px;">
          <p>Powered by <span class="boldText">Lokobee</span></p>
      </div>
    </div>
    </body>
    </html>
    `;

    const hiddFrame = document.createElement('iframe');
    hiddFrame.style.position = 'fixed';
    hiddFrame.style.visibility = 'hidden';

    hiddFrame.srcdoc = content;

    document.body.appendChild(hiddFrame);
    hiddFrame.onload = () => {
      setTimeout(() => {
        hiddFrame.contentWindow?.print();
      }, 500);

      setTimeout(() => {
        try {
          document.body.removeChild(hiddFrame);
        } catch {}
      }, 2000);
    };
  } else {
    const printerIP = getLocalStorage('printIp');

    const PUBLIC_URL = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://' + window.location.hostname;

    const content = `
      <html>
      <script src="${PUBLIC_URL}/js/epos.js"></script>
      <script>
        // Total cols 48 for size 1
        const totalCols = 48;

        const printer = new epson.ePOSBuilder();

        function sendRequest() {
          if (printer) {            
            var request = printer.toString();
            var address = "https://${printerIP}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000";
            var epos = new epson.ePOSPrint(address);
            epos.onreceive = function (res) {            
              if (!res.success) {
                window.parent.postMessage({ printerMessage: "Printer error !"}, "*");
              }else{
                window.parent.postMessage({ printerMessage: "success"}, "*");
              }
            };
            epos.onerror = function (res) {
              window.parent.postMessage({ printerMessage: "Connection Error !"}, "*");
            };
           
            epos.send(request);
            
          }
        }
       

        function printStrings(string1, string2, width1, height1, width2, height2, wrap, bold1, bold2) {
          if (width1 === 0) {
            width1 = 1;
          }
          if (width2 === 0) {
            width2 = 1;
          }

          if (!string1) {
            string1 = "";
          }
          if (!string2) {
            string2 = "";
          }

          const leftCols = string1.length * width1;
          const rightCols = string2.length * width2;

          if (leftCols + rightCols < totalCols) {
            const remainingCols = totalCols - leftCols - rightCols;

            // Print string 1
            printer.addTextSize(width1, height1);
            if (bold1) {
              printBold(string1);
            } else {
              printer.addText(string1);
            }

            // Print spaces
            printer.addTextSize(1, 1);
            printer.addText(" ".repeat(remainingCols));

            // Print string 2
            printer.addTextSize(width2, height2);
            if (bold2) {
              printBold(string2);
            } else {
              printer.addText(string2);
            }
          } else {
            if (wrap) {
              // Keeping minimum 5 spaces
              // We do not wrap right string
              // Wrap only left string
              const remainingCols = totalCols - 5 - rightCols;

              // Print string 1
              printer.addTextSize(width1, height1);
              if (bold1) {
                printBold(string1.substring(0, remainingCols));
              } else {
                printer.addText(string1.substring(0, remainingCols));
              }

              // Print spaces
              printer.addTextSize(1, 1);
              printer.addText(" ".repeat(5));

              // Print string 2
              printer.addTextSize(width2, height2);
              if (bold2) {
                printBold(string2);
              } else {
                printer.addText(string2);
              }

              // New Line
              printer.addText("\\n");

              // Print remaining string1
              printer.addTextSize(width1, height1);
              if (bold1) {
                printBold(string1.substring(remainingCols));
              } else {
                printer.addText(string1.substring(remainingCols));
              }
              printer.addText("\\n");
            } else {
              // Print string 1
              printer.addTextSize(width1, height1);
              if (bold1) {
                printBold(string1);
              } else {
                printer.addText(string1);
              }

              // Print spaces
              printer.addTextSize(1, 1);
              printer.addText(" ".repeat(5));

              // Print string 2
              printer.addTextSize(width2, height2);
              if (bold2) {
                printBold(string2);
              } else {
                printer.addText(string2);
              }
            }
          }
        }

        function addDivider() {
          printer.addText("\\n");
          printer.addTextSize(2, 2);
          printer.addText("-".repeat(totalCols / 2));
          printer.addText("\\n");
        }

        function printBold(text) {
          if (!text) {
            text = "";
          }
          printer.addTextStyle(false, false, true, undefined);
          printer.addText(text);
          printer.addTextStyle(false, false, false, undefined);
        }

        function indent(spaces, text) {
          printer.addText(" ".repeat(spaces) + text);
        }

        async function drawExclusionIcon(text) {
          return new Promise((resolve, reject) => {
            var svgElement = document.getElementById("closeIcon");
            if (svgElement) {
              let clonedSvgElement = svgElement.cloneNode(true);
              const blob = new Blob([clonedSvgElement.outerHTML], { type: "image/svg+xml;charset=utf-8" });
              let blobURL = URL.createObjectURL(blob);
              
              let image = new Image();
              image.height = 50;
              image.width = 50;
              image.onload = () => {
                const canvas = document.createElement("canvas");  
                canvas.height = 50;
                canvas.width = 550;  
                const context = canvas.getContext("2d");
                //context.font = "50px Times New Roman";
                //context.fillText("          ", 0, 0);
                //context.drawImage(image, 10, 0, 40, 40);
                //context.font = "50px Times New Roman";
                //context.fillText(text, 40 + 10, 30);

                context.drawImage(image, 0, 0, 50, 50);
                context.font = "50px Times New Roman";
                context.fillText(text, 50 , 40);
  
                printer.addImage(context, 0, 0, canvas.width, canvas.height);
                resolve(true);
              };
              image.src = blobURL;
            } else {
              reject(true);
            }
          });
        }

        async function buildPDF(){
          if (printer) {
            printStrings("${orderNumber.substr(orderNumber.length - 4)}", "${
      type === 'TAKEOUT'
        ? expectTimeMoment.format('ddd').toUpperCase()
        : moment()
            .format('ddd')
            .toUpperCase()
    }", 3, 3, 3, 3, true, true, true);

            printStrings("${restaurantName}", "${orderType.toUpperCase()}", 1, 1, 2, 2, true, false, true);

            printStrings("${restaurantAddress}", "${type === 'TAKEOUT' || type === 'DELIVERY' ? expectTimeMoment.format('MMM-DD').toUpperCase() : ''}", 1, 1, 2, 2, true, false, true);

            printStrings("${restaurantPhone}", "", 1, 1, 2, 2, true, false, true);

            ${type === 'TAKEOUT' || type === 'DELIVERY' ? `printStrings("Scheduled:", "${expectTimeMoment.format('hh:mm A')}", 2, 2, 2, 2, true, false, true);` : ``}

            addDivider();

            printStrings("${displayName}", "${paymentType === 'ONLINE_STRIPE' ? 'PAID' : 'PAYMENT DUE'}", 1, 1, 1, 1, true, true, true);
            printStrings("${customerPhone}", "", 1, 1, 1, 1, true, false, false);
            printStrings("Order#: ${orderNumber}", "${createdAtMoment.format('MMM-DD-YYYY HH:mm A')}", 1, 1, 1, 1, true, false, false);

            addDivider();

            ${
              type === 'DELIVERY'
                ? `              
                printer.addTextSize(1, 1);
                ${deliveryAddress ? `printBold("Delivery Address: ");printer.addText("${_deliveryAddress}\\n");` : ``}
                ${deliveryNote ? `printBold("Delivery Note: ");printer.addText("${deliveryNote}\\n");` : ``}
                ${deliveryNote ? `printBold("Drop off instruction: ");printer.addText("${dropOffInstruction}\\n");` : ``}
                
                addDivider();              
            `
                : ``
            }

            

            printer.addTextSize(2, 2);
            printBold("Items(${itemCount})");
  
            addDivider();

            ${Object.keys(dishesGroupedByCategory)
              .map((key) => {
                let str = `printer.addTextSize(2, 2);
              printBold("${key}\\n");`;
                str += dishesGroupedByCategory[key]
                  .map((item: any) => {
                    const { dishTitle, count, dishPrice, dishExtras, note, dishOriginalPrice, isReward, points } = item;

                    const dishType = item.dishType || 'REGULAR';

                    if (dishType === 'REGULAR') {
                      const [{ text: _title }] = dishTitle || [{ text: '' }];

                      const [{ text: _dishSize }] = dishOriginalPrice.size || [{ text: '' }];

                      const extras = map(dishExtras, (extra) => {
                        const { groupTitle, items } = extra;
                        return `${groupTitle} : ${items.join(', ')}`;
                      }).join('\\n');

                      return `
                        printer.addTextSize(2, 2);
                        printBold("${count}x ${_title}\\n");
                        ${_dishSize !== 'Regular' ? `printer.addText("${_dishSize}");` : ``}
                        ${
                          extras
                            ? `
                          printer.addText("\\n");
                          printer.addText("${extras}")`
                            : ``
                        }
                        ${
                          note
                            ? `
                          printBold("Dish Note:");
                          printer.addText("${note}");
                        `
                            : ``
                        }                                        
                        printer.addText("\\n");
                      `;
                    }

                    if (dishType === 'PIZZA') {
                      const {
                        count,
                        dishTitle,
                        pizzaCrust,
                        toppings: { leftToppings, rightToppings },
                        sauces: { leftSauces, rightSauces },
                        cheeses: { leftCheeses, rightCheeses },
                        halfAndHalf,
                        pizzaBasePrice,
                        dishPrice,
                        note
                      } = item;

                      const [{ text: _title }] = dishTitle || [{ text: '' }];

                      const { title: _dishSize } = pizzaBasePrice && pizzaBasePrice.size ? pizzaBasePrice.size : { title: '' };

                      const { title: crust } = pizzaCrust || { title: '' };

                      let toppingsDisplay = ``;

                      if (halfAndHalf) {
                        toppingsDisplay += `
                        printBold("LEFT HALF\\n");
                        printer.addText("${_title}\\n")
                      `;

                        // Left Exclusions
                        if (!!leftSauces.exclusions && !!leftSauces.exclusions.length) {
                          toppingsDisplay += map(leftSauces.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }

                        if (!!leftCheeses.exclusions && !!leftCheeses.exclusions.length) {
                          toppingsDisplay += map(leftCheeses.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }
                        if (!!leftToppings.exclusions && !!leftToppings.exclusions.length) {
                          toppingsDisplay += map(leftToppings.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }

                        // Left Additions
                        if (!!leftSauces.additions && !!leftSauces.additions.length) {
                          toppingsDisplay += map(leftSauces.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }
                        if (!!leftCheeses.additions && !!leftCheeses.additions.length) {
                          toppingsDisplay += map(leftCheeses.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }

                        if (!!leftToppings.additions && !!leftToppings.additions.length) {
                          toppingsDisplay += map(leftToppings.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }

                        toppingsDisplay += `
                        printBold("RIGHT HALF\\n")
                        printer.addText("${_title}\\n")
                    `;

                        // Right Exclusions
                        if (!!rightSauces.exclusions && !!rightSauces.exclusions.length) {
                          toppingsDisplay += map(rightSauces.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }

                        if (!!rightCheeses.exclusions && !!rightCheeses.exclusions.length) {
                          toppingsDisplay += map(rightCheeses.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }
                        if (!!rightToppings.exclusions && !!rightToppings.exclusions.length) {
                          toppingsDisplay += map(rightToppings.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }
                        // right Additions
                        if (!!rightSauces.additions && !!rightSauces.additions.length) {
                          toppingsDisplay += map(rightSauces.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }
                        if (!!rightCheeses.additions && !!rightCheeses.additions.length) {
                          toppingsDisplay += map(rightCheeses.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }
                        if (!!rightToppings.additions && !!rightToppings.additions.length) {
                          toppingsDisplay += map(rightToppings.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }
                      } else {
                        toppingsDisplay += `printer.addText("${_title}\\n");`;

                        if (!!leftSauces.exclusions && !!leftSauces.exclusions.length) {
                          toppingsDisplay += map(leftSauces.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }

                        if (!!leftCheeses.exclusions && !!leftCheeses.exclusions.length) {
                          toppingsDisplay += map(leftCheeses.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }
                        if (!!leftToppings.exclusions && !!leftToppings.exclusions.length) {
                          toppingsDisplay += map(leftToppings.exclusions, (topping) => {
                            const { title = '' } = topping;
                            return `printBold("-- No ");printer.addText("${title.substring(3)}\\n");`;
                          }).join('');
                        }
                        // Left Additions
                        if (!!leftSauces.additions && !!leftSauces.additions.length) {
                          toppingsDisplay += map(leftSauces.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }
                        if (!!leftCheeses.additions && !!leftCheeses.additions.length) {
                          toppingsDisplay += map(leftCheeses.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }
                        if (!!leftToppings.additions && !!leftToppings.additions.length) {
                          toppingsDisplay += map(leftToppings.additions, (topping) => {
                            const { title = '', density = '' } = topping;
                            return `printer.addText("${title} ");${density !== 'Regular' ? `printBold("(${density})\\n");` : `printer.addText("\\n");`}`;
                          }).join('');
                        }
                      }

                      return `
                      printer.addTextSize(2, 2);
                      printBold("${count}x Pizza\\n");
                      printer.addText('${_dishSize}\\n');
                      printer.addText("${crust}\\n");
                      ${toppingsDisplay}
                      ${
                        note
                          ? `
                        printBold("Dish Note:");
                        printer.addText("${note}");
                      `
                          : ``
                      }                                      
                      printer.addText("\\n");                  
                  `;
                    }
                    return ``;
                  })
                  .join('');

                str += `addDivider();`;

                return str;
              })
              .join('')}

           

              ${
                orderNote
                  ? `            
                  printBold("Order Note:");
                  printer.addText("${orderNote}");
                  addDivider();
              `
                  : ``
              }


            printer.addTextSize(1, 1);
            printer.addTextAlign(printer.ALIGN_CENTER);
            printer.addText("Powered by ");
            printBold("Lokobee");

            printer.addText("\\n");
            printer.addText("\\n");
            printer.addText("\\n");
            printer.addText("\\n");
            printer.addCut(printer.CUT_FEED);

            sendRequest();
          }

          
          
        }
        setTimeout(() => {
          buildPDF()
        }, 1000);
        
      </script>
      <body>
        <svg
        style="height: 50px; width: 50px"
        enable-background="new 0 0 24 24"
        id="closeIcon"
        version="1.0"
        viewBox="0 0 24 24"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <path
            d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M16.9,15.5l-1.4,1.4L12,13.4l-3.5,3.5   l-1.4-1.4l3.5-3.5L7.1,8.5l1.4-1.4l3.5,3.5l3.5-3.5l1.4,1.4L13.4,12L16.9,15.5z"
          />
        </g>
      </svg>
      
      </body>
      </html>
      `;

    const hiddFrame = document.createElement('iframe');
    hiddFrame.style.position = 'fixed';
    hiddFrame.style.visibility = 'hidden';

    window.addEventListener(
      'message',
      (event: MessageEvent) => {
        if (event.data && event.data.printerMessage) {
          try {
            document.body.removeChild(hiddFrame);
          } catch {}
          if (event.data.printerMessage !== 'success') {
            notify(event.data.printerMessage);
          }
        }
      },
      false
    );

    hiddFrame.srcdoc = content;

    document.body.appendChild(hiddFrame);
  }
};
