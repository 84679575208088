export const gloabalLoaderActionInitialState = {
  loadingCount: 0
};

export const globalLoaderActions = {
  LOADER_INCREMENT_COUNT: (state: any, payload: any) => ({ ...state, loadingCount: state.loadingCount + 1 }),
  LOADER_DECREMENT_COUNT: (state: any, payload: any) => {
    if (state.loadingCount > 0) {
      return { ...state, loadingCount: state.loadingCount - 1 };
    }

    return { ...state };
  }
};

export default {};
