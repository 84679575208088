import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',

    display: 'flex',
    flexDirection: 'column'
  },
  switch: {
    marginRight: theme.spacing(1)
  },
  scroll1: {
    position: 'absolute',
    overflowY: 'scroll',
    height: '80%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  scroll2: {
    position: 'absolute',
    overflowY: 'scroll',
    height: '80%',
    width: '100%',
    right: 0,
    backgroundColor: theme.palette.grey[300],
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  searchBox: {
    backgroundColor: '#fff',
    border: 'none',
    width: '100%'
  },
  cancelIcon: {
    cursor: 'pointer'
  }
}));
