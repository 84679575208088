import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from 'pages/RestaurantDashboard/Login';
import Home from 'pages/RestaurantDashboard/Home';
import PrivateRoute from 'components/PrivateRoute';

const RestaurantRoutes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path="/:restaurantId/home" component={Home} />
      <Route path="/:restaurantId" component={Login} />
    </Switch>
  );
};

export default RestaurantRoutes;
