import React, { useEffect, useContext } from 'react';
import useGroceryStore from 'hooks/useGroceryStore';
import GroceryStoreRoutes from './routes';
import { HumanSpeaker } from 'util/speaker';
import { useStore } from 'store';
import { AuthContext } from 'fbase/authContext';

const RestaurantDashboard = () => {
  const {
    state: { notify }
  } = useStore();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notify) {
        HumanSpeaker.speak(notify);
      }
    }, 30000);

    if (!currentUser) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [currentUser, notify]);

  const isValid = useGroceryStore();
  if (isValid) {
    return <GroceryStoreRoutes />;
  }

  return null;
};

export default RestaurantDashboard;
