import React, { useContext } from 'react';
import { FirebaseContext } from 'fbase';
import { AuthContext } from 'fbase/authContext';
import { useLogin } from 'graphql/hooks/login.hooks';
import { Box, TextField, Button } from '@material-ui/core';
import { Formik } from 'formik';
import validationSchema from './validation';
import { useStyles } from './style';
import { useSnackbar } from 'notistack';
import { useHistory, Redirect } from 'react-router-dom';
import { useStore } from 'store';

const LoginForm = () => {
  const classes = useStyles();

  const { firebase } = useContext(FirebaseContext);

  const { currentUser } = useContext(AuthContext);

  const history = useHistory();

  const { loginEmployee } = useLogin();

  const snackbar = useSnackbar();

  const {
    state: { restaurantId }
  } = useStore();

  const onSubmit = async (values: any) => {
    const { username, password } = values;

    try {
      const { data } = await loginEmployee({
        variables: {
          input: {
            password,
            userName: username.replace(/\s/g, ''),
            bizId: restaurantId
          }
        }
      });

      if (data && data.loginBizEmployee) {
        const {
          employee: { displayName },
          token
        } = data.loginBizEmployee;

        if (firebase) {
          const { user } = await firebase.signInWithCustomToken(token);

          if (user) {
            await user.updateProfile({
              displayName
            });
            localStorage.setItem('ops_rest_id', restaurantId);
            history.push('/' + restaurantId + '/home');
          }
        }
      }
    } catch (e) {
      snackbar.enqueueSnackbar('Invalid credentials.', { variant: 'error' });
    }
  };

  if (currentUser) {
    localStorage.setItem('ops_rest_id', restaurantId);
    return <Redirect to={'/' + restaurantId + '/home'} />;
  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ handleSubmit, isValid, values, handleChange, handleBlur, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <Box className={classes.formWrapper}>
            <TextField
              name="username"
              label="username"
              placeholder="Username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              fullWidth={true}
              className={classes.textField}
              error={errors.username && touched.username ? true : false}
              helperText={touched.username ? errors.username : ''}
            />
            <TextField
              type="password"
              name="password"
              label="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              fullWidth={true}
              className={classes.textField}
              error={errors.password && touched.password ? true : false}
              helperText={touched.password ? errors.password : ''}
            />
            <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
              Login
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
