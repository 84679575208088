import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useStore } from 'store';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 1500,
      color: '#fff'
    }
  })
);
const Loader: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    state: { loadingCount }
  } = useStore();

  useEffect(() => {
    if (loadingCount) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [loadingCount, open]);
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress />
    </Backdrop>
  );
};

export default Loader;
