import React from 'react';
import { Box, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { useStyles } from '../../DishOptions/style';
import { IIngredientItem } from '../types';
interface IProps {
  singleItemData: IIngredientItem;
  updateIngredients: (itemKey: string, action: boolean) => void;
}

const SingleItem = ({ singleItemData, updateIngredients }: IProps) => {
  const classes = useStyles();
  return (
    <Box paddingX={1} borderBottom={0}>
      <Grid container>
        <Grid item xs={8} className={classes.alignCenter}>
          <Box>
            <Typography variant={'body2'}>{singleItemData.title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            label={''}
            className={classes.switch}
            control={<Switch checked={singleItemData.disabled} onChange={() => updateIngredients(singleItemData.key || '', !singleItemData.disabled)} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleItem;
