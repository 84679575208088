import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getGroceryStoreById from 'graphql/query/getGroceryStoreById.query';
import { useLazyQuery } from '@apollo/react-hooks';
import { useStore } from 'store';
import moment from 'moment-timezone';

export default () => {
  const { storeId } = useParams() as any;
  const { dispatch } = useStore();

  const [isValid, setValid] = useState<boolean>(false);

  const [getGroceryStore, { data }] = useLazyQuery(getGroceryStoreById);

  const [groceryStoreId, setGroceryStoreId] = useState(null);

  useEffect(() => {
    if (groceryStoreId && groceryStoreId === storeId) {
      return;
    } else {
      getGroceryStore({
        variables: {
          input: {
            storeId: storeId
          }
        }
      });
    }
  }, [getGroceryStore, groceryStoreId, storeId]);

  useEffect(() => {
    if (data && data.getGroceryStore) {
      const { id, enableTakeoutOrder, enableDeliveryOrder, timezone, name } = data.getGroceryStore;

      const storeName = name || '';

      const getTimezone = timezone || 'us/pacific';

      moment.tz.setDefault(timezone);

      setGroceryStoreId(id);

      dispatch({
        type: 'SET_GROCERY_STORE',
        payload: id
      });

      dispatch({
        type: 'SET_GROCERY_STORE_TIMEZONE',
        payload: getTimezone
      });

      dispatch({
        type: 'SET_GROCERY_STORE_OPS_SETTINGS',
        payload: {
          enableTakeoutOrder,
          enableDeliveryOrder
        }
      });
      dispatch({
        type: 'SET_GROCERY_STORE_INFO',
        payload: {
          name: storeName
        }
      });

      setValid(true);
    }
  }, [data, dispatch]);

  useEffect(() => {
    return () => dispatch({ type: 'RESET_GROCERY_STORE' });
  }, [dispatch]);

  return isValid;
};
