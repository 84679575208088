import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useOrderDetails } from 'graphql/hooks/orders.hooks';
import { formatPhoneNumber, convertPrice } from 'util/number';
import { getPaymentTypeString } from './constants';
import { parseOrderItems, printOrderDetails } from './utils';
import { Box, Typography, Grid } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useStyles } from './style';
import Moment from 'moment';
import ActionButtons from './ActionButtons';
import PrintIcon from '@material-ui/icons/Print';
import OrderItems from './OrderItems';

const OrderDetails = () => {
  const classes = useStyles();

  const { state } = useLocation();

  const { orderId, restaurantId } = useParams();

  const history = useHistory();

  const { buyerId } = (state as any) || { buyerId: '' };

  const { data } = useOrderDetails(orderId, buyerId);

  if (data && buyerId !== '') {
    const {
      orderNumber,
      expectTime,
      buyer: { displayName, email, phoneNumber },
      note,
      subTotal,
      tip,
      total,
      tax,
      status,
      paymentType,
      items,
      type,
      deliveryFee,
      tableName,
      partySize,
      createdAt,
      deliveryAddress,
      deliveryNote,
      deliveryDistance
    } = data;

    const _items = parseOrderItems(items);

    const paymentTypeString = (getPaymentTypeString as any)[paymentType];

    const goBackToMainPage = () => {
      history.push('/' + restaurantId + '/home');
    };

    const _phoneNumber = formatPhoneNumber(phoneNumber);

    const Row = ({ children }: any) => {
      return (
        <Box padding={1} borderBottom={`solid 2px ${grey[200]}`}>
          {children}
        </Box>
      );
    };

    const _printOrderDetails = () => {
      printOrderDetails(data, _items);
    };

    const disableReadyBtn = (type === 'TAKEOUT' || type === 'DELIVERY') && expectTime && Moment(expectTime).diff(Moment(), 'days') > 0;

    const { text: _deliveryAddress } = deliveryAddress || { text: '' };

    return (
      <Box width="100%" padding={2}>
        <Box width="100%" bgcolor="white" id="orderDetails">
          <Row>
            <Box display="flex" alignItems="center">
              <ArrowBackIcon className={classes.backIcon} onClick={goBackToMainPage} />
              <Typography variant="body1" className={classes.boldText}>
                Customer Information
              </Typography>
              {status !== 'PLACED' && (
                <Box marginLeft="auto" onClick={_printOrderDetails}>
                  <PrintIcon fontSize="large" color="secondary" />
                </Box>
              )}
            </Box>
          </Row>
          <Row>
            <Typography variant="body2">
              <span className={classes.boldText}>Name : </span>
              {displayName}
            </Typography>
            <Typography variant="body2">
              <span className={classes.boldText}>Phone : </span>
              <a href={`tel:${phoneNumber}`} className={classes.link}>
                {_phoneNumber ? _phoneNumber : phoneNumber}
              </a>
            </Typography>
            <Typography variant="body2">
              <span className={classes.boldText}>Email : </span>
              {email}
            </Typography>
            {type === 'DINING' && tableName && (
              <Typography variant="body2">
                <span className={classes.boldText}>Table Name : </span>
                {tableName}
              </Typography>
            )}
            {type === 'DINING' && partySize && (
              <Typography variant="body2">
                <span className={classes.boldText}># of people : </span>
                {partySize}
              </Typography>
            )}
          </Row>
          {type === 'DELIVERY' && (
            <>
              <Row>
                <Typography variant="body1" className={classes.boldText}>
                  Delivery Information
                </Typography>
              </Row>
              <Row>
                {deliveryAddress && (
                  <Typography variant="body2">
                    <span className={classes.boldText}>Address: </span>
                    {_deliveryAddress}
                  </Typography>
                )}
                {deliveryNote && (
                  <Typography variant="body2">
                    <span className={classes.boldText}>Delivery Note: </span>
                    {deliveryNote}
                  </Typography>
                )}
                {deliveryDistance !== 0 && (
                  <Typography variant="body2">
                    <span className={classes.boldText}>Estimated Distance: </span>
                    {deliveryDistance.toFixed(2)} miles
                  </Typography>
                )}
              </Row>
            </>
          )}
          <Row>
            <Typography variant="body1" className={classes.boldText}>
              Order Information ( {status} )
            </Typography>
          </Row>
          <Row>
            <span className={classes.boldText}>Order # :</span>
            {orderNumber}
          </Row>
          <Row>
            <Typography variant="body1" className={classes.boldText}>
              Payment Information
            </Typography>
          </Row>
          <Row>
            <Typography variant="body2" className={paymentType === 'ONLINE_STRIPE' ? classes.greenTextColor : classes.redTextColor}>
              {paymentTypeString}
            </Typography>
          </Row>

          {type === 'DINING' ? (
            <>
              <Row>
                <Typography variant="body1" className={classes.boldText}>
                  Dining
                </Typography>
              </Row>
              <Row>
                <Typography variant="body2">
                  {Moment(createdAt)
                    .toNow(true)
                    .toString()}{' '}
                  ago
                </Typography>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Typography variant="body1" className={classes.boldText}>
                  Pickup time
                </Typography>
              </Row>
              <Row>
                <Typography variant="body2">{Moment(expectTime).format('Do MMM hh:mm a')}</Typography>
              </Row>
            </>
          )}

          {note && (
            <>
              <Row>
                <Typography variant="body1" className={classes.boldText}>
                  Order Note
                </Typography>
              </Row>
              <Row>
                <Typography variant="body2">{note}</Typography>
              </Row>
            </>
          )}

          <OrderItems items={_items} />

          {/* <Grid container={true} className={classes.amountContainer}>
            <Grid item={true} sm={2}>
              <Typography variant="body1" className={classes.boldText}>
                Sub Total
              </Typography>
            </Grid>
            <Grid item={true} sm={10}>
              <Typography variant="body2" align="right">
                $ {convertPrice(subTotal.intValue ? subTotal.intValue : 0, subTotal.shift ? subTotal.shift : -2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container={true} className={classes.amountContainer}>
            <Grid item={true} sm={2}>
              <Typography variant="body1" className={classes.boldText}>
                Tax
              </Typography>
            </Grid>
            <Grid item={true} sm={10}>
              <Typography variant="body2" align="right">
                $ {convertPrice(tax.intValue ? tax.intValue : 0, tax.shift ? tax.shift : -2)}
              </Typography>
            </Grid>
          </Grid>
          {type === 'DELIVERY' && deliveryFee && (
            <Grid container={true} className={classes.amountContainer}>
              <Grid item={true} sm={2}>
                <Typography variant="body1" className={classes.boldText}>
                  Delivery Fee
                </Typography>
              </Grid>
              <Grid item={true} sm={10}>
                <Typography variant="body2" align="right">
                  $ {convertPrice(deliveryFee.intValue ? deliveryFee.intValue : 0, deliveryFee.shift ? deliveryFee.shift : -2)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container={true} className={classes.amountContainer}>
            <Grid item={true} sm={2}>
              <Typography variant="body1" className={classes.boldText}>
                Tip Amount
              </Typography>
            </Grid>
            <Grid item={true} sm={10}>
              <Typography variant="body2" align="right">
                $ {convertPrice(tip.intValue ? tip.intValue : 0, tip.shift ? tip.shift : -2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container={true} className={classes.amountContainer}>
            <Grid item={true} sm={2}>
              <Typography variant="body1" className={classes.boldText}>
                Total Amount
              </Typography>
            </Grid>
            <Grid item={true} sm={10}>
              <Typography variant="body2" align="right">
                $ {convertPrice(total.intValue ? total.intValue : 0, total.shift ? total.shift : -2)}
              </Typography>
            </Grid>
          </Grid> */}
        </Box>
        <ActionButtons orderId={orderId} restaurantId={restaurantId} buyerId={buyerId} orderStatus={status} printOrderDetails={_printOrderDetails} disableReadyBtn={disableReadyBtn} />
      </Box>
    );
  }

  return null;
};

export default OrderDetails;
