import { Howl, Howler } from 'howler';
import { notifyType } from 'types';

const tracks = {
  DEFAULT: '/audio/notification.m4a',
  TABLE_SERVICE: '/audio/table_service.m4a',
  ACCEPTED_OVERDUE: '/audio/accepted_overdue.mp3'
};

export const HumanSpeaker = {
  speak: (type: notifyType = 'DEFAULT') => {
    const sound = new Howl({
      src: [tracks[type]]
    });

    Howler.volume(1);

    sound.play();
  }
};
