import React, { useReducer, useContext } from 'react';
import { restaurantActions, restaurantInitialState } from './restaurantActions';
import { waiterPageInitialState, waiterPageActions } from './waiterPageActions';
import { notificationInitialState, notificationActions } from './notificationActions';
import { gloabalLoaderActionInitialState, globalLoaderActions } from './globalLoaderActions';
import { pingActions, pingInitialState } from './pingingActions';
import { groceryStoreInitialState, groceryStoreActions } from './groceryStoreActions';

const initialState: any = {
  ...restaurantInitialState,
  ...waiterPageInitialState,
  ...gloabalLoaderActionInitialState,
  ...notificationInitialState,
  ...pingInitialState,
  ...groceryStoreInitialState
};

const Actions = {
  ...restaurantActions,
  ...waiterPageActions,
  ...globalLoaderActions,
  ...notificationActions,
  ...pingActions,
  ...groceryStoreActions
};

const reducer = (state: any, action: any) => {
  const act = (Actions as any)[action.type];
  const update = act(state, action.payload);
  return { ...state, ...update };
};

const LoaderContext = React.createContext(initialState);

export const LoaderStore: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);

  return <LoaderContext.Provider value={{ state, dispatch }}>{children}</LoaderContext.Provider>;
};

export const useStore = () => {
  const { state, dispatch } = useContext(LoaderContext);
  return { state, dispatch };
};

export default {};
