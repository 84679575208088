import { useState } from 'react';
import { useQueryWithLoader, useMutationWithLoader, useLazyQueryWithLoader } from 'hooks/loader';
import { isEmpty, map } from 'lodash';
import { GET_RESTAURANT_BY_ZIPCODE, GET_WAIT_MINS, GET_RESTAURANT_BY_NANOID, GET_ORDER_BY_ORDER_ID } from 'graphql/query';
import { UPDATE_WAIT_MINS, BIZ_ORDER_PAUSE, INCREASE_EXPECTED_TIME } from 'graphql/mutations';
import { useStore } from 'store';
import Logger from 'util/logger';
import { OrderType } from 'generated/custom';
import moment from 'moment';

export const useRestaurantList = () => {
  const [query, setQuery] = useState<string>('');

  const { loading, data, error, called } = useQueryWithLoader(GET_RESTAURANT_BY_ZIPCODE, {
    variables: {
      input: {
        zipcode: query
      }
    }
  });

  if (error) {
    Logger.log('ERROR: ' + error);
  }

  if (!isEmpty(data)) {
    const { getRestaurantsByZipcode: { edges } = { edges: [] } } = data;

    return {
      data: map(edges, ({ node }) => node),
      loading,
      error,
      setQuery,
      called
    };
  }

  return {
    data: null,
    loading,
    error,
    setQuery,
    called
  };
};

export const useGetRestaurantByNanoId = () => {
  const [getRestaurantByNanoId, { data, called, loading }] = useLazyQueryWithLoader(GET_RESTAURANT_BY_NANOID);

  const queryRestaurant = (nanoid: string) => {
    getRestaurantByNanoId({
      variables: {
        input: {
          nanoid
        }
      }
    });
  };

  return { queryRestaurant, data, called, loading };
};

export const useGetWaitMins = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const { data } = useQueryWithLoader(GET_WAIT_MINS, {
    variables: {
      input: {
        id: restaurantId
      }
    }
  });

  return {
    data
  };
};

export const useUpdateWaitMins = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const [updateWaitMins, { data }] = useMutationWithLoader(UPDATE_WAIT_MINS);

  const updateCaller = async (takeoutWaitMinutes: number, deliveryWaitMinutes: number, cookingWaitMinutes: number) => {
    const response = await updateWaitMins({
      variables: {
        input: {
          id: restaurantId,
          takeoutWaitMinutes,
          deliveryWaitMinutes,
          cookingWaitMinutes
        }
      },
      update: (cache, { data: newData }) => {
        const { updateWaitMinutes } = newData || { updateWaitMinutes: {} };

        cache.writeQuery({
          data: { getWaitMinutes: { ...updateWaitMinutes } },
          query: GET_WAIT_MINS,
          variables: {
            input: {
              id: restaurantId
            }
          }
        });
      }
    });

    return response;
  };

  return {
    updateWaitMins: updateCaller,
    data
  };
};

export const useUpdateRestaurantPause = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const [updateRestaurantPause, { data }] = useMutationWithLoader(BIZ_ORDER_PAUSE);

  const updateCaller = async (pauseData: any) => {
    const response = await updateRestaurantPause({
      variables: {
        input: {
          id: restaurantId,
          pauseData: pauseData
        }
      }
    });

    return response;
  };

  return {
    updateRestaurantPause: updateCaller,
    data
  };
};

export const useIncreaseOrderExpectedTime = (buyerUid: string, orderId: string) => {
  const {
    state: { restaurantId }
  } = useStore();

  const [increaseOrderExpectedTime, { data }] = useMutationWithLoader(INCREASE_EXPECTED_TIME);

  const updateCaller = async (updateEtaBy: number) => {
    const response = await increaseOrderExpectedTime({
      variables: {
        input: {
          buyerUid: buyerUid,
          sellerBizId: restaurantId,
          orderId: orderId,
          delayExpectedMinBy: updateEtaBy
        }
      },
      update: (cache, { data: newDataUpdated }) => {
        const { increaseOrderExpectedTime } = newDataUpdated || { increaseOrderExpectedTime: {} };
        if (increaseOrderExpectedTime === true) {
          const { getOrderByOrderId }: any = cache.readQuery({
            query: GET_ORDER_BY_ORDER_ID,
            variables: {
              input: {
                orderId: orderId,
                buyerUid: buyerUid
              }
            }
          });
          const newEtaTime = moment.now() + updateEtaBy * 60000;
          const newData = Object.assign(getOrderByOrderId, { expectTime: newEtaTime });

          cache.writeQuery({
            data: {
              getOrderByOrderId: newData
            },
            query: GET_ORDER_BY_ORDER_ID,
            variables: {
              input: {
                orderId: orderId,
                buyerUid: buyerUid
              }
            }
          });
        }
      }
    });

    return response;
  };

  return {
    increaseOrderExpectedTimeBy: updateCaller,
    data
  };
};
