import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { useGetDishes } from 'graphql/hooks/dishes.hooks';
import SingleDishItem from './SingleDishItem';
import Fuse from 'fuse.js';
import { filter, groupBy } from 'lodash';
import CancelIcon from '@material-ui/icons/Cancel';

export const DISH_STATUS_ACTIVE = 'ACTIVE';
export const DISH_STATUS_HIDDEN = 'HIDDEN';

const Dishes = () => {
  const classes = useStyles();
  const { data } = useGetDishes();

  const [initialValues, setInitialValues] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const availableDishes = filter(initialValues, (item: any) => item.status === DISH_STATUS_ACTIVE);
  const hiddenDishes = filter(initialValues, (item: any) => item.status === DISH_STATUS_HIDDEN);

  useEffect(() => {
    if (data && data.getAllDishes) {
      setInitialValues(data.getAllDishes);
    }
  }, [data]);

  const GrouppedItem = (passedDishes: Array<any>) => {
    const grouppedItem = groupBy(passedDishes, 'category.id');
    if (grouppedItem) {
      let tempArray = [];
      for (const i in grouppedItem) {
        let items: Array<any> = grouppedItem[i];
        if (items && items.length > 0) {
          const categoryTitle = items[0]?.category?.title[0].text || '';
          const categoryDishesIds = items.map((i) => i.id);
          tempArray.push(
            <Box key={items[0]?.category?.id}>
              {searchQuery.trim() === '' && (
                <SingleDishItem
                  isCatagoryItem={true}
                  categoryDishesIds={categoryDishesIds}
                  key={items[0]?.category?.id}
                  dishStatus={items.length === items.filter((i) => i.status === DISH_STATUS_ACTIVE).length ? DISH_STATUS_ACTIVE : DISH_STATUS_HIDDEN}
                  dishTitle={categoryTitle}
                />
              )}
              {items.map((dishItem: any) => {
                return <SingleDishItem isCatagoryItem={false} dishId={dishItem.id} dishStatus={dishItem.status} key={dishItem.id} dishTitle={dishItem.title[0].text} />;
              })}
            </Box>
          );
        }
      }
      return <>{tempArray}</>;
    } else {
      return <></>;
    }
  };

  const onSearchChange = (q: string) => {
    if (q.trim() === '') {
      setInitialValues(data.getAllDishes);
    } else {
      const fuse = new Fuse(data.getAllDishes, {
        keys: ['title.text'],
        isCaseSensitive: false,
        shouldSort: true,
        minMatchCharLength: 3
      });
      setInitialValues(fuse.search(q).map((res: any) => res.item));
    }
    setSearchQuery(q);
  };
  return (
    <Box flex={1}>
      <Box flex={1} justifyContent="center" width={'100%'} paddingY={2} display="flex">
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <TextField
              InputProps={{
                endAdornment:
                  searchQuery !== '' ? (
                    <CancelIcon
                      color="primary"
                      className={classes.cancelIcon}
                      onClick={() => {
                        onSearchChange('');
                      }}
                    />
                  ) : null
              }}
              className={classes.searchBox}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
              value={searchQuery}
              variant="outlined"
              placeholder="Enter name to find dishes"
              name="searchquery"
              size="small"
            />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <Box display="flex" paddingY={2} justifyContent={'center'}>
            <Typography variant="h5">Available Dishes</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" paddingY={2} justifyContent={'center'}>
            <Typography variant="h5">Hidden Dishes</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} className={classes.scroll1}>
          <Box padding={2}>
            <Box>{GrouppedItem(availableDishes)}</Box>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.scroll2}>
          <Box padding={2}>
            <Box>{GrouppedItem(hiddenDishes)}</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dishes;
