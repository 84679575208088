import { gql } from 'apollo-boost';

export default gql`
  mutation updateWaitMinutes($input: UpdateWaitMinutesInput!) {
    updateWaitMinutes(input: $input) {
      takeoutWaitMinutes
      deliveryWaitMinutes
      cookingWaitMinutes
    }
  }
`;
