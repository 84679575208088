import { useQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import { map } from 'lodash';
import { GET_ALL_DISHES, GET_DISH_INGREDIENTS } from 'graphql/query';
import { useStore } from 'store';
import { UPDATE_DISH_INGREDIENTS, UPDATE_HIDDEN_DISH } from 'graphql/mutations';

export const useGetDishes = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const { data } = useQueryWithLoader(GET_ALL_DISHES, {
    variables: {
      input: {
        bizId: restaurantId
      }
    }
  });

  return {
    data
  };
};

export const useUpdateDish = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const [updateDish, { data }] = useMutationWithLoader(UPDATE_HIDDEN_DISH);

  const updateDishCaller = async (dishId: Array<string>, status: string) => {
    const response = await updateDish({
      variables: {
        input: {
          sellerBizId: restaurantId,
          dishId: dishId,
          status: status
        }
      },
      update: (cache, { data: newDataUpdated }) => {
        const { restaurantEmployeeUpdateDishStatus } = newDataUpdated || { restaurantEmployeeUpdateDishStatus: {} };
        if (restaurantEmployeeUpdateDishStatus === true) {
          const { getAllDishes: dishes }: any = cache.readQuery({
            query: GET_ALL_DISHES,
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });
          const newData = map(dishes, (dish) => {
            if (dishId.includes(dish.id)) {
              dish.status = status;
            }
            return dish;
          });
          cache.writeQuery({
            data: {
              getAllDishes: newData
            },
            query: GET_ALL_DISHES,
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });
        }
      }
    });

    return response;
  };

  return {
    updateDish: updateDishCaller,
    data
  };
};

export const useGetIngredients = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const { data } = useQueryWithLoader(GET_DISH_INGREDIENTS, {
    variables: {
      input: {
        bizId: restaurantId
      }
    }
  });

  return {
    data
  };
};
export const useUpdateIngredients = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const [updateIngredientsInfo, { data }] = useMutationWithLoader(UPDATE_DISH_INGREDIENTS);

  const updateIngredientsCaller = async (ingredientInfo: Array<any>) => {
    const response = await updateIngredientsInfo({
      variables: {
        input: {
          bizId: restaurantId,
          ingredientInfo: ingredientInfo
        }
      },
      update: (cache, { data: newDataUpdated }) => {
        const { updateIngredientsInfo } = newDataUpdated || { updateIngredientsInfo: {} };
        if (updateIngredientsInfo === true) {
          const { getIngredientsInfo: dishIngredients }: any = cache.readQuery({
            query: GET_DISH_INGREDIENTS,
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });
          const newData = map(dishIngredients, (item) => {
            ingredientInfo.forEach((itemNew, index) => {
              if (item.key === itemNew.key) {
                item.disabled = itemNew.disabled;
              }
            });
            return item;
          });
          cache.writeQuery({
            data: {
              getIngredientsInfo: newData
            },
            query: GET_DISH_INGREDIENTS,
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });
        }
      }
    });

    return response;
  };

  return {
    updateIngredientsInfo: updateIngredientsCaller,
    data
  };
};
