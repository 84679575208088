import React from 'react';
import Header from 'components/Header';
import { Box } from '@material-ui/core';
import { useStore } from 'store';
import { useStyles } from './style';
import { Switch, Route } from 'react-router-dom';
import { DisplayOrders, OrderHistory } from 'pages/RestaurantDashboard/Orders';
import OrderDetails from 'pages/RestaurantDashboard/OrderDetails';
import SearchOrders from 'pages/RestaurantDashboard/SearchOrders';
import { useOrders } from 'graphql/hooks/orders.hooks';
import TableService from 'pages/RestaurantDashboard/TableService';
import Dishes from 'pages/Dishes';
import { WaitTimeContextProvider } from 'context/WaitMinsContext';
import DishOptions from 'pages/DishOptions';

const Home = () => {
  const classes = useStyles();

  const {
    state: { restaurantId }
  } = useStore();

  const { tableServiceNewOrders, tableServiceClosedOrders, tableServiceOrderCount, futureOrders, newOrders, processingOrders, readyOrders } = useOrders();

  return (
    <Box className={classes.container}>
      <WaitTimeContextProvider>
        <Header restaurantId={restaurantId} tableServiceOrderCount={tableServiceOrderCount} />
        <Box width="100%" display="flex">
          <Switch>
            <Route
              exact={true}
              path="/:restaurantId/home"
              component={() => {
                return <DisplayOrders newOrders={newOrders} processingOrders={processingOrders} readyOrders={readyOrders} futureOrders={futureOrders} />;
              }}
            />
            <Route
              path="/:restaurantId/home/tableservices"
              component={() => {
                return <TableService tableServiceNewOrders={tableServiceNewOrders} tableServiceClosedOrders={tableServiceClosedOrders} />;
              }}
            />
            <Route path="/:restaurantId/home/dishoptions" component={DishOptions} />
            <Route path="/:restaurantId/home/dishes" component={Dishes} />
            <Route path="/:restaurantId/home/history" component={OrderHistory} />
            <Route path="/:restaurantId/home/search" component={SearchOrders} />
            <Route path="/:restaurantId/home/:orderId" component={OrderDetails} />
          </Switch>
        </Box>
      </WaitTimeContextProvider>
    </Box>
  );
};

export default Home;
