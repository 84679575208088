import { gql } from 'apollo-boost';

export default gql`
  query getGroceryWaitMinutes($input: GetGroceryWaitMinutesInput!) {
    getGroceryWaitMinutes(input: $input) {
      takeoutWaitMinutes
      deliveryWaitMinutes
      packingWaitMins
    }
  }
`;
