import { gql } from 'apollo-boost';

export const restaurantEmployeeLogin = gql`
  mutation loginBizEmployee($input: LoginBizEmployeeInput!) {
    loginBizEmployee(input: $input) {
      employee {
        displayName
      }
      token
    }
  }
`;

export const groceryStoreEmployeeLogin = gql`
  mutation loginGroceryBizEmployee($input: LoginGroceryBizEmployeeInput!) {
    loginGroceryBizEmployee(input: $input) {
      employee {
        displayName
      }
      token
    }
  }
`;
