import React from 'react';
import { Box, Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { map } from 'lodash';
import { useStyles } from '../style';
import CancelIcon from '@material-ui/icons/Cancel';
import { calculatePoints, formatPriceToString } from '../utils';
import { convertPriceTo } from 'util/number';

interface IProps {
  items: any;
}

const OrderItems = ({ items }: IProps) => {
  const classes = useStyles();

  const Row = ({ children }: any) => {
    return (
      <Box padding={1} borderTop={`solid 2px ${grey[500]}`}>
        {children}
      </Box>
    );
  };

  return (
    <Box paddingX={1}>
      {map(items, (item: any, index) => {
        const { title, count, price, size, isReward, rewardPoints, brand } = item;

        return (
          <Row key={index}>
            <Box display="flex">
              <Box flexGrow={1} paddingRight={1}>
                <Typography variant="body2" className={classes.boldText}>
                  {count}x {title} ({`${brand}`}) {size && `(${size})`}
                </Typography>
              </Box>
              <Box className={classes.priceItem}>
                <Typography variant="body1" className={classes.boldText} noWrap={true} align="right">
                  {isReward ? `${calculatePoints(count, rewardPoints)} PTS` : `$${convertPriceTo(price * count, 'DOLLAR')}`}
                </Typography>
              </Box>
            </Box>
          </Row>
        );
      })}
    </Box>
  );
};

export default OrderItems;
