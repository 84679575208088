import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';

interface IProps {
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  onError: (err: boolean) => void;
}

const IpAddressField = ({ value, onChange, onError }: IProps) => {
  const error = value.match(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);

  useEffect(() => {
    onError(!error);
  }, [error, onError, value]);

  return <TextField variant="outlined" placeholder="IP address" value={value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)} error={value !== '' && !error} />;
};

export default IpAddressField;
