import { gql } from 'apollo-boost';

export const updateDishStatus = gql`
  mutation restaurantEmployeeUpdateDishStatus($input: EmployeeUpdateDishStatusInput!) {
    restaurantEmployeeUpdateDishStatus(input: $input)
  }
`;

export const updateIngredientsInfo = gql`
  mutation updateIngredientsInfo($input: UpdateIngredientsInfoInput!) {
    updateIngredientsInfo(input: $input)
  }
`;
