import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, Button, CircularProgress } from '@material-ui/core';
import { useFindOrder } from 'graphql/hooks/orders.hooks';
import { DiningOrderCard, TakeOutOrderCard } from 'components/OrderCard';
import { convertPrice } from 'util/number';
import SearchIcon from '@material-ui/icons/Search';
import { useStore } from 'store';

const SearchOrders = () => {
  const {
    state: { restaurantId }
  } = useStore();

  const orderNumberFormat = '0000-00000';

  const [query, setQuery] = useState('');

  const { findOrder, data, loading, called } = useFindOrder();

  const search = () => {
    if (query !== '') {
      const len = orderNumberFormat.length;

      findOrder({
        variables: {
          input: {
            sellerBizId: restaurantId,
            orderNumber: orderNumberFormat.slice(0, len - query.length) + query
          }
        }
      });
    }
  };

  const renderSearchedOrders = () => {
    if (!data && called && !loading) {
      return (
        <Box paddingY={1}>
          <Typography color="error" variant="body1">
            Order not found.
          </Typography>
        </Box>
      );
    }
    if (data && data.getOrderByOrderNumber && !loading) {
      const { getOrderByOrderNumber } = data;

      const {
        id,
        orderNumber,
        status,
        createdAt,
        total: { intValue, shift },
        buyer: { phoneNumber, displayName, uid },
        expectTime,
        type,
        partySize,
        tableName
      } = getOrderByOrderNumber;

      if (type !== 'TABLE_SERVICE') {
        return (
          <Box paddingY={1} width="100%">
            <Grid container={true}>
              <Grid item={true} sm={4}>
                {type === 'DINING' ? (
                  <DiningOrderCard
                    key={id}
                    orderId={id}
                    orderNumber={orderNumber}
                    name={displayName}
                    phone={phoneNumber}
                    total={convertPrice(intValue, shift)}
                    partySize={partySize}
                    tableName={tableName}
                    createdAt={createdAt}
                    buyerId={uid}
                    status={status}
                  />
                ) : (
                  <TakeOutOrderCard
                    key={id}
                    orderId={id}
                    orderNumber={orderNumber}
                    name={displayName}
                    phone={phoneNumber}
                    total={convertPrice(intValue, shift)}
                    expectedTime={expectTime}
                    createdAt={createdAt}
                    buyerId={uid}
                    status={status}
                    type={type}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        );
      } else {
        return (
          <Box paddingY={1}>
            <Typography color="error" variant="body1">
              Table service order
            </Typography>
          </Box>
        );
      }
    }

    return null;
  };

  return (
    <Box padding={2} width="100%">
      <Typography variant="body1">Enter order number to search your order.</Typography>
      <br />
      <TextField type="text" placeholder="0000-00004" variant="outlined" value={query} onChange={(e: any) => setQuery(e.target.value)} />
      <br />
      <br />
      <Box display="flex" alignItems="center">
        <Box marginRight={1}>
          <Button variant="contained" color="secondary" size="small" onClick={search} disabled={loading} endIcon={<SearchIcon fontSize="small" />}>
            Search
          </Button>
        </Box>
        {loading && <CircularProgress size={20} color="secondary" />}
      </Box>
      {renderSearchedOrders()}
    </Box>
  );
};

export default SearchOrders;
