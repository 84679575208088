import React from 'react';
import Firebase from './firebase';

type FirebaseContextProps = {
  firebase: Firebase;
};

const FirebaseContext = React.createContext<Partial<FirebaseContextProps>>({});

interface InjectedProps {
  firebase: Firebase;
}

export const withFirebase = <P extends InjectedProps>(Component: React.ComponentType<P>) => (props: any) => (
  <FirebaseContext.Consumer>{(firebase: Partial<FirebaseContextProps>) => <Component {...props} firebase={firebase} />}</FirebaseContext.Consumer>
);

export default FirebaseContext;
