import React, { useState, useEffect } from 'react';
import { IOrder } from 'types';
import { Box, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, useMediaQuery } from '@material-ui/core';
import OrderDetails from '../OrderDetails';
import { useStyles } from '../style';
import { map } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import grey from '@material-ui/core/colors/grey';
import GroceryOrderCard from 'components/GroceryOrderCard';
import GroceryStoreOrderPauseDisplay from 'components/GroceryHeader/OrderPause/GroceryStoreOrderPauseDisplay';
import HomeIcon from '@material-ui/icons/Home';
import { useStore } from 'store';
interface IProps {
  newOrders: IOrder[] | null;
  processingOrders: IOrder[] | null;
  readyOrders: IOrder[] | null;
  futureOrders: IOrder[] | null;
}

interface IActiveOrder {
  orderId: string;
  buyerId: string;
}

const DisplayOrders = ({ newOrders, processingOrders, readyOrders, futureOrders }: IProps) => {
  const classes = useStyles();

  const [activeOrder, setActiveOrder] = useState<IActiveOrder>({
    orderId: '',
    buyerId: ''
  });

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const {
    state: { storeInfo }
  } = useStore();
  const newOrderCount = newOrders ? newOrders.length : 0;
  const processingOrderCount = processingOrders ? processingOrders.length : 0;
  const readyOrderCount = readyOrders ? readyOrders.length : 0;
  const futureOrderCount = futureOrders ? futureOrders.length : 0;

  useEffect(() => {
    if (newOrders && newOrders.length) {
      const {
        id,
        buyer: { uid }
      } = newOrders[0];

      setActiveOrder({
        orderId: id,
        buyerId: uid
      });
    } else if (processingOrders && processingOrders.length) {
      const {
        id,
        buyer: { uid }
      } = processingOrders[0];

      setActiveOrder({
        orderId: id,
        buyerId: uid
      });
    } else if (readyOrders && readyOrders.length) {
      const {
        id,
        buyer: { uid }
      } = readyOrders[0];

      setActiveOrder({
        orderId: id,
        buyerId: uid
      });
    } else if (futureOrders && futureOrders.length) {
      const {
        id,
        buyer: { uid }
      } = futureOrders[0];

      setActiveOrder({
        orderId: id,
        buyerId: uid
      });
    } else {
    }
  }, [futureOrders, newOrders, processingOrders, readyOrders]);

  const cardColor = (i: number) => {
    if (!isDesktop) {
      if (i % 2 === 0) return 'white';
      else return grey[300];
    } else {
      return '';
    }
  };

  const getItemCount = (items: any) => {
    let itemCount = 0;

    items.forEach(({ count }: any) => {
      if (count) {
        itemCount += count;
      }
    });

    return itemCount;
  };

  const { orderId, buyerId } = activeOrder;
  return (
    <Box className={classes.scrollContainer}>
      <Grid container={true} className={classes.container}>
        <Grid item={true} xs={6} lg={7} className={classes.scroll1}>
          <Box padding={2}>
            <GroceryStoreOrderPauseDisplay />
          </Box>
          <Box padding={2}>
            <Box display="flex" alignItems="center">
              <HomeIcon className={classes.homeIcon} />
              <Typography variant="h5" component="span">
                {storeInfo.name}
              </Typography>
            </Box>
            <ExpansionPanel className={classes.expansionPanel} defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionSummary}>
                <Typography variant="h6"> New {!!newOrderCount ? `( ${newOrderCount} )` : ''} </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                {!newOrderCount ? (
                  <Box padding={1}>
                    <Typography variant="h6">No New Orders.</Typography>
                  </Box>
                ) : (
                  <Grid container={true} className={classes.cardContainer}>
                    {map(newOrders, ({ id, createdAt, status, orderPhoneNumber, buyer: { displayName, uid }, partySize, type, expectTime, deliveryDistance, items, orderNumber }, index) => {
                      return (
                        <Grid
                          key={index}
                          item={true}
                          xs={12}
                          lg={6}
                          className={classes.cardItem}
                          onClick={() =>
                            setActiveOrder({
                              buyerId: uid,
                              orderId: id
                            })
                          }>
                          <GroceryOrderCard
                            name={displayName}
                            phone={orderPhoneNumber}
                            type={type}
                            deliveryDistance={deliveryDistance}
                            partySize={partySize}
                            itemCount={getItemCount(items)}
                            isSelected={orderId === id}
                            expectedTime={expectTime}
                            createdAt={createdAt}
                            status={status}
                            bgColor={cardColor(index)}
                            orderNumber={orderNumber}
                            isNewOrder={true}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel className={classes.expansionPanel} defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionSummary}>
                <Typography variant="h6"> Processing {!!processingOrderCount ? `( ${processingOrderCount} )` : ''} </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                {!processingOrderCount ? (
                  <Box padding={1}>
                    <Typography variant="h6">No Processing Orders.</Typography>
                  </Box>
                ) : (
                  <Grid container={true} className={classes.cardContainer}>
                    {map(processingOrders, ({ id, createdAt, status, orderPhoneNumber, buyer: { displayName, uid }, partySize, type, expectTime, deliveryDistance, items, orderNumber }, index) => {
                      return (
                        <Grid
                          key={index}
                          item={true}
                          xs={12}
                          lg={6}
                          className={classes.cardItem}
                          onClick={() =>
                            setActiveOrder({
                              buyerId: uid,
                              orderId: id
                            })
                          }>
                          <GroceryOrderCard
                            name={displayName}
                            phone={orderPhoneNumber}
                            type={type}
                            deliveryDistance={deliveryDistance}
                            partySize={partySize}
                            itemCount={getItemCount(items)}
                            isSelected={orderId === id}
                            expectedTime={expectTime}
                            createdAt={createdAt}
                            status={status}
                            bgColor={cardColor(index)}
                            orderNumber={orderNumber}
                            isProcessingOrder={true}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel className={classes.expansionPanel} defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionSummary}>
                <Typography variant="h6"> Ready {!!readyOrderCount ? `( ${readyOrderCount} )` : ''} </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                {!readyOrderCount ? (
                  <Box padding={1}>
                    <Typography variant="h6">No Ready Orders.</Typography>
                  </Box>
                ) : (
                  <Grid container={true} className={classes.cardContainer}>
                    {map(readyOrders, ({ id, createdAt, status, orderPhoneNumber, buyer: { displayName, uid }, partySize, type, expectTime, deliveryDistance, items, orderNumber }, index) => {
                      return (
                        <Grid
                          key={index}
                          item={true}
                          xs={12}
                          lg={6}
                          className={classes.cardItem}
                          onClick={() =>
                            setActiveOrder({
                              buyerId: uid,
                              orderId: id
                            })
                          }>
                          <GroceryOrderCard
                            name={displayName}
                            phone={orderPhoneNumber}
                            type={type}
                            deliveryDistance={deliveryDistance}
                            partySize={partySize}
                            itemCount={getItemCount(items)}
                            isSelected={orderId === id}
                            expectedTime={expectTime}
                            createdAt={createdAt}
                            status={status}
                            bgColor={cardColor(index)}
                            orderNumber={orderNumber}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel className={classes.expansionPanel} defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionSummary}>
                <Typography variant="h6"> Future orders {!!futureOrderCount ? `( ${futureOrderCount} )` : ''} </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                {!futureOrderCount ? (
                  <Box padding={1}>
                    <Typography variant="h6">No Future Orders.</Typography>
                  </Box>
                ) : (
                  <Grid container={true} className={classes.cardContainer}>
                    {map(futureOrders, ({ id, createdAt, status, orderPhoneNumber, buyer: { displayName, uid }, partySize, type, expectTime, deliveryDistance, items, orderNumber }, index) => {
                      return (
                        <Grid
                          key={index}
                          item={true}
                          xs={12}
                          lg={6}
                          className={classes.cardItem}
                          onClick={() =>
                            setActiveOrder({
                              buyerId: uid,
                              orderId: id
                            })
                          }>
                          <GroceryOrderCard
                            name={displayName}
                            phone={orderPhoneNumber}
                            type={type}
                            deliveryDistance={deliveryDistance}
                            partySize={partySize}
                            itemCount={getItemCount(items)}
                            isSelected={orderId === id}
                            expectedTime={expectTime}
                            createdAt={createdAt}
                            status={status}
                            bgColor={cardColor(index)}
                            orderNumber={orderNumber}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>
        </Grid>
        <Grid item={true} xs={6} lg={5} className={classes.scroll2}>
          {orderId !== '' && buyerId !== '' && <OrderDetails orderId={activeOrder.orderId} buyerId={activeOrder.buyerId} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DisplayOrders;
