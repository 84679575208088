import { GROCERY_STORE_LOGIN_MUTATION, LOGIN_MUTATION } from 'graphql/mutations';
import { useMutationWithLoader } from 'hooks/loader';

export const useLogin = () => {
  const [loginEmployee, { loading, data, error }] = useMutationWithLoader(LOGIN_MUTATION);

  return {
    loginEmployee,
    loading,
    data,
    error
  };
};

export const useGroceryStoreEmployeeLogin = () => {
  const [loginGroceryBizEmployee, { loading, data, error }] = useMutationWithLoader(GROCERY_STORE_LOGIN_MUTATION);

  return {
    loginGroceryBizEmployee,
    loading,
    data,
    error
  };
};
