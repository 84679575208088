import React, { useState, useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import { FirebaseContext } from 'fbase';
import { useStore } from 'store';
import moment from 'moment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import OrderPauseDialog from './OrderPauseDialog';
import { useObject } from 'react-firebase-hooks/database';

export interface IPauseData {
  TAKEOUT?: {
    pauseDate: string;
    status: 'pause' | 'resume';
  };
  DELIVERY?: {
    pauseDate: string;
    status: 'pause' | 'resume';
  };
}

interface IInitialValue {
  takeOutPause: boolean;
  deliveryPause: boolean;
}

const OrderPause = () => {
  const {
    state: { storeId }
  } = useStore();

  const [openDialog, setOpenDialog] = useState(false);

  const { firebase } = useContext(FirebaseContext);

  const [snapshot] = useObject(firebase?.getDb().ref(`/groceryOrderPause/${storeId}/`));

  const pauseData: IPauseData | null | undefined = snapshot?.val();

  const now = moment().format('YYYY-MM-DD');

  const takeOutPause = pauseData && pauseData.TAKEOUT ? pauseData.TAKEOUT?.status === 'pause' && now === pauseData.TAKEOUT?.pauseDate : false;

  const deliveryPause = pauseData && pauseData.DELIVERY ? pauseData.DELIVERY?.status === 'pause' && now === pauseData.DELIVERY?.pauseDate : false;

  return (
    <>
      <Box display="flex" alignItems="center">
        <Button
          onClick={() => {
            setOpenDialog(true);
          }}
          size="small"
          endIcon={(takeOutPause || deliveryPause) && <FiberManualRecordIcon fontSize="small" color="error" />}>
          Pause
        </Button>
        {openDialog && <OrderPauseDialog open={openDialog} onClose={() => setOpenDialog(false)} takeOut={takeOutPause} delivery={deliveryPause} />}
      </Box>
    </>
  );
};

export default OrderPause;
