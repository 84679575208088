import React, { useState, useEffect } from 'react';
import { useHistory } from 'graphql/hooks/groceryStoreOrders.hooks';
import OrderDetails from '../OrderDetails';
import { useStyles } from '../style';
import { map } from 'lodash';
import grey from '@material-ui/core/colors/grey';
import { Box, Grid, Typography, useMediaQuery, Button, CircularProgress } from '@material-ui/core';
import GroceryOrderCard from 'components/GroceryOrderCard';

interface IActiveOrder {
  orderId: string;
  buyerId: string;
}

const OrderHistory = () => {
  const classes = useStyles();

  const { historyOrders, fetching, hasMore, fetchMoreData, error } = useHistory();

  const [activeOrder, setActiveOrder] = useState<IActiveOrder>({
    orderId: '',
    buyerId: ''
  });

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (activeOrder.buyerId === '' && activeOrder.orderId === '') {
      if (historyOrders && historyOrders.length) {
        const {
          id,
          buyer: { uid }
        } = historyOrders[0];

        setActiveOrder({
          orderId: id,
          buyerId: uid
        });
      }
    }
  }, [activeOrder.buyerId, activeOrder.orderId, historyOrders]);

  const cardColor = (i: number) => {
    if (!isDesktop) {
      if (i % 2 === 0) return 'white';
      else return grey[300];
    } else {
      return '';
    }
  };

  const { orderId, buyerId } = activeOrder;

  if (!fetching && error) {
    return (
      <Box padding={2}>
        <Typography variant="h6">Something went wrong.</Typography>
      </Box>
    );
  }

  if (!fetching && !historyOrders.length) {
    return (
      <Box padding={2}>
        <Typography variant="h6">No orders found.</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.scrollContainer}>
      <Grid container={true} className={classes.container}>
        <Grid item={true} xs={6} lg={7} className={classes.scroll1}>
          <Box padding={2}>
            <Grid container={true} className={classes.cardContainer}>
              {map(historyOrders, ({ id, createdAt, status, orderPhoneNumber, buyer: { displayName, uid }, partySize, type, expectTime, deliveryDistance, items, orderNumber }, index) => {
                let itemCount = 0;

                items.forEach(({ count }: any) => {
                  if (count) {
                    itemCount += count;
                  }
                });

                return (
                  <Grid
                    key={index}
                    item={true}
                    xs={12}
                    lg={6}
                    className={classes.cardItem}
                    onClick={() =>
                      setActiveOrder({
                        buyerId: uid,
                        orderId: id
                      })
                    }>
                    <GroceryOrderCard
                      name={displayName}
                      phone={orderPhoneNumber}
                      type={type}
                      deliveryDistance={deliveryDistance}
                      partySize={partySize}
                      itemCount={itemCount}
                      isSelected={orderId === id}
                      expectedTime={expectTime}
                      createdAt={createdAt}
                      status={status}
                      bgColor={cardColor(index)}
                      orderNumber={orderNumber}
                    />
                  </Grid>
                );
              })}
            </Grid>
            {hasMore && (
              <Box paddingY={1} marginBottom={1} display="flex" justifyContent="center" alignItems="center">
                <Button onClick={fetchMoreData} variant="contained" color="secondary" disableElevation={true} disabled={fetching}>
                  {fetching ? 'Loading' : 'Load more'}
                </Button>
                {fetching && (
                  <Box paddingX={1}>
                    <CircularProgress size={20} color="secondary" />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item={true} xs={6} lg={5} className={classes.scroll2}>
          {orderId !== '' && buyerId !== '' && <OrderDetails orderId={activeOrder.orderId} buyerId={activeOrder.buyerId} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderHistory;
