import { newOrderDurationIdentifier } from './constants';

export const getNewOrderTimeDifference = (difference: number) => {
  if (difference < 0) {
    return newOrderDurationIdentifier.OVERDUE;
  } else if (difference >= 0 && difference <= 2) {
    return newOrderDurationIdentifier.WITHIN_2_MINS;
  } else if (difference > 2 && difference <= 4) {
    return newOrderDurationIdentifier.WITHIN_4_MINS;
  } else if (difference > 4 && difference <= 6) {
    return newOrderDurationIdentifier.WITHIN_6_MINS;
  } else {
    return newOrderDurationIdentifier.OVERDUE;
  }
};

export const getNewOrderPickerBackgroundColor = (arg: number): string => {
  switch (arg) {
    case newOrderDurationIdentifier.WITHIN_2_MINS:
      return 'green';
    case newOrderDurationIdentifier.WITHIN_4_MINS:
      return 'yellow';
    case newOrderDurationIdentifier.WITHIN_6_MINS:
      return 'orange';
    case newOrderDurationIdentifier.OVERDUE:
      return 'red';
    default:
      return 'blue';
  }
};
