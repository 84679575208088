import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton, Drawer, Divider } from '@material-ui/core';
import { useStyles } from './style';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'fbase';
import { useApolloClient } from '@apollo/react-hooks';

import { AuthContext } from 'fbase/authContext';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';

import SecurityIcon from '@material-ui/icons/Security';
import HistoryIcon from '@material-ui/icons/History';
import ExitToApp from '@material-ui/icons/ExitToApp';
import GroceryWaitMins from './WaitMins';
import OrderPause from './OrderPause';

interface IProps {
  storeId: string;
}

const GroceryHeader = ({ storeId }: IProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { firebase } = useContext(FirebaseContext);

  const client = useApolloClient();

  const { currentUser } = useContext(AuthContext);

  const onLogOut = async () => {
    try {
      if (firebase) {
        await firebase.signOut();
        client.clearStore();
        localStorage.clear();

        history.push('/grocerystore/' + storeId);
      }
    } catch {
      console.log('FAILED TO SIGN OUT');
    }
  };

  const changeRoute = (route: string) => {
    setOpenDrawer(false);
    history.push(route);
  };

  const displayName: string = currentUser ? currentUser.displayName || '' : '';

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <MenuIcon className={classes.drawerIcon} onClick={() => setOpenDrawer(true)} />
        </Box>

        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <Box paddingY={2}>
            <Box paddingX={3} paddingY={1}>
              <AccountCircle className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                WELCOME, {displayName.toUpperCase()}
              </Typography>
            </Box>
            <Divider variant="middle" className={classes.divider} />
            <Box className={classes.link} onClick={() => changeRoute(`/grocerystore/${storeId}/home/`)} paddingX={3} paddingY={1}>
              <LocalGroceryStoreIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                STORE
              </Typography>
            </Box>
            <Box className={classes.link} onClick={() => changeRoute(`/grocerystore/${storeId}/home/history`)} paddingX={3} paddingY={1}>
              <HistoryIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                HISTORY
              </Typography>
            </Box>

            <Box className={classes.link} onClick={() => changeRoute('/privacypolicy')} paddingX={3} paddingY={1}>
              <SecurityIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                PRIVACY POLICY
              </Typography>
            </Box>

            <Box className={classes.link} onClick={() => changeRoute('/useragreement')} paddingX={3} paddingY={1}>
              <AccountCircle className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                USER AGREEMENT
              </Typography>
            </Box>

            <Box className={classes.link} onClick={onLogOut} paddingX={3} paddingY={1}>
              <ExitToApp className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                LOGOUT
              </Typography>
            </Box>
          </Box>
        </Drawer>
        <GroceryWaitMins storeId={storeId} />
        <OrderPause />
      </Toolbar>
    </AppBar>
  );
};

export default GroceryHeader;
