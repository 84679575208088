import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  backIcon: {
    paddingRight: theme.spacing(1),
    cursor: 'pointer'
  },
  boldText: {
    fontWeight: 'bolder'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  redTextColor: {
    color: theme.palette.error.main
  },
  greenTextColor: {
    color: theme.palette.success.main
  },
  dishContainer: {
    padding: theme.spacing(1, 2)
  },
  amountContainer: {
    padding: theme.spacing(0, 2)
  },
  errorBtn: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  cancelIcon: {
    color: theme.palette.error.main
  }
}));
