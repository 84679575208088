import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  restaurantImg: {
    display: 'flex',
    justifyContent: 'center'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  restaurantContainer: {
    padding: theme.spacing(1)
  }
}));
