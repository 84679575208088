import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment, { Moment } from 'moment';
import { useGetWaitTimeContext } from 'context/WaitMinsContext';
import { Typography } from '@material-ui/core';
import { useStyles } from './style';

interface IProps {
  openDialog: boolean;
  setOpenDialog: () => void;
  orderNumber: string;
  expectTime: Moment;
  acceptOrder: () => void;
  openEtaDelay: () => void;
}

function OrderAcceptConformation({ openDialog, setOpenDialog, expectTime, acceptOrder, openEtaDelay, orderNumber }: IProps) {
  const contextWaitMins = useGetWaitTimeContext();

  const classes = useStyles();

  const momentExpectTime = expectTime.format('ddd MMM-DD, hh:mm A').toUpperCase();
  const timeRemaining = moment(expectTime).diff(moment.now(), 'minutes', true);

  function EtaTypography() {
    const shouldRed: boolean = Math.round(timeRemaining) >= contextWaitMins.cookingWaitMins ? false : true;
    return (
      <span
        style={{
          color: shouldRed ? 'red' : 'black',
          fontStyle: 'bold'
        }}>
        {momentExpectTime}
      </span>
    );
  }

  return (
    <Dialog open={openDialog}>
      <DialogTitle>{'Accept Order Confirmation'}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          You are accepting #{orderNumber} order.
          <br />
          Can it be ready by <EtaTypography /> (remaining time: {Math.trunc(timeRemaining) < 0 ? 0 : Math.trunc(timeRemaining)} mins),
          <Typography className={classes.dialogContentTextTypography}>Current Time: {moment(moment.now()).format('hh:mm A')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" disableElevation={true} onClick={acceptOrder}>
          Yes
        </Button>
        <Button variant="outlined" color="secondary" onClick={openEtaDelay}>
          No
        </Button>
        <Button variant="outlined" color="default" onClick={setOpenDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderAcceptConformation;
