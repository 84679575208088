import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import UserAgreement from 'pages/UserAgreement';
import RestaurantList from 'pages/RestaurantList';
import RestaurantDashboard from 'pages/RestaurantDashboard';
import GroceryStoreDashboard from 'pages/GroceryStoreDashboard';

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const AppRoutes: React.FC = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact={true} path="/" component={RestaurantList} />
          <Route exact={true} path="/:restaurantId/home/privacypolicy" component={PrivacyPolicy} />
          <Route exact={true} path="/:restaurantId/home/useragreement" component={UserAgreement} />
          <Route path="/grocerystore/:storeId" component={GroceryStoreDashboard} />
          <Route path="/:restaurantId" component={RestaurantDashboard} />
        </Switch>
      </Router>
    </>
  );
};

export default AppRoutes;
