import { newOrderDurationIdentifier, processingOrderDurationIdentifier } from './constants';

export const getNewOrderTimeDifference = (difference: number) => {
  if (difference < 0) {
    return newOrderDurationIdentifier.OVERDUE;
  } else if (difference >= 0 && difference <= 2) {
    return newOrderDurationIdentifier.WITHIN_2_MINS;
  } else if (difference > 2 && difference <= 4) {
    return newOrderDurationIdentifier.WITHIN_4_MINS;
  } else if (difference > 4 && difference <= 6) {
    return newOrderDurationIdentifier.WITHIN_6_MINS;
  } else {
    return newOrderDurationIdentifier.OVERDUE;
  }
};

export const getNewOrderPickerBackgroundColor = (arg: number): string => {
  switch (arg) {
    case newOrderDurationIdentifier.WITHIN_2_MINS:
      return 'green';
    case newOrderDurationIdentifier.WITHIN_4_MINS:
      return 'yellow';
    case newOrderDurationIdentifier.WITHIN_6_MINS:
      return 'orange';
    case newOrderDurationIdentifier.OVERDUE:
      return 'red';
    default:
      return 'blue';
  }
};

export const getProcessingOrderTimeDifference = (difference: number) => {
  if (difference < 0) {
    return processingOrderDurationIdentifier.OVERDUE;
  } else if (difference > 0 && difference <= 10) {
    return processingOrderDurationIdentifier.WITHIN_10_MINS;
  } else if (difference > 10 && difference <= 20) {
    return processingOrderDurationIdentifier.WITHIN_20_MINS;
  } else if (difference > 20 && difference <= 30) {
    return processingOrderDurationIdentifier.WITHIN_30_MINS;
  } else {
    return newOrderDurationIdentifier.OVERDUE;
  }
};

export const getProcessingOrderPickerBackgroundColor = (arg: number): string => {
  switch (arg) {
    case processingOrderDurationIdentifier.WITHIN_10_MINS:
      return 'green';
    case processingOrderDurationIdentifier.WITHIN_20_MINS:
      return 'yellow';
    case processingOrderDurationIdentifier.WITHIN_30_MINS:
      return 'orange';
    case processingOrderDurationIdentifier.OVERDUE:
      return 'red';
    default:
      return 'blue';
  }
};
