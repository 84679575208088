import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    color: '#000',
    backgroundColor: '#fff'
  },
  icon: {
    maxWidth: '50px',
    maxHeight: '50px',
    margin: theme.spacing(1),
    cursor: 'pointer'
  },
  drawerIcon: {
    padding: theme.spacing(0, 1),
    cursor: 'pointer'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  iconLink: {
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: 5,
    color: 'white',
    cursor: 'pointer'
  },
  iconLinkActive: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: 5,
    color: 'white',
    cursor: 'pointer'
  },
  badge: {
    padding: theme.spacing(0.5, 1),
    marginLeft: -theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
    borderRadius: '50%',
    width: 'max-content',
    height: 'max-content',
    color: 'white',
    backgroundColor: theme.palette.error.main
  },
  activeLink: {
    '& > button': {
      color: theme.palette.secondary.main
    }
  },
  colorSpan: {
    margin: theme.spacing(0, 0.5),
    color: theme.palette.primary.main
  },
  logo: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    margin: theme.spacing(0.5, 3),
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  menuIcon: {
    margin: 0,
    padding: 0
  },
  linkIcon: {
    verticalAlign: 'middle',
    paddingRight: theme.spacing(1)
  },
  username: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1)
  },
  toolbar: {
    padding: theme.spacing(0, 1)
  },
  btns: {
    textTransform: 'none'
  },
  divider: {
    backgroundColor: grey[500],
    height: '2px'
  }
}));
