import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  btns: {
    margin: theme.spacing(1)
  },
  printIcon: {
    margin: theme.spacing(0, 1),
    cursor: 'pointer'
  },
  successBtn: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  successText: {
    color: theme.palette.success.main
  }
}));
