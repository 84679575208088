import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogContentText: {
    color: 'black',
    fontSize: '18px'
  },
  dialogContentTextTypography: {
    paddingTop: theme.spacing(3)
  }
}));
