import { gql } from 'apollo-boost';

export default gql`
  query getGrocerySellerOrders($input: GetGrocerySellerOrdersInput!) {
    getGrocerySellerOrders(input: $input) {
      edges {
        node {
          id
          status
          expectTime
          orderNumber
          createdAt
          updatedAt
          deliveryDistance
          timeLines {
            msg
            status
          }
          buyer {
            uid
            phoneNumber
            email
            displayName
          }
          orderEmail
          orderPhoneNumber
          paymentType
          lokobeeFee
          totalTax
          type
          subTotal
          items {
            title
            count
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
