import React, { useContext } from 'react';
import { FirebaseContext } from 'fbase';
import { AuthContext } from 'fbase/authContext';
import { useGroceryStoreEmployeeLogin, useLogin } from 'graphql/hooks/login.hooks';
import { Box, TextField, Button } from '@material-ui/core';
import { Formik } from 'formik';
import validationSchema from './validation';
import { useStyles } from './style';
import { useSnackbar } from 'notistack';
import { useHistory, Redirect } from 'react-router-dom';
import { useStore } from 'store';

const LoginForm = () => {
  const classes = useStyles();

  const { firebase } = useContext(FirebaseContext);

  const { currentUser } = useContext(AuthContext);

  const history = useHistory();

  const { loginGroceryBizEmployee } = useGroceryStoreEmployeeLogin();

  const snackbar = useSnackbar();

  const {
    state: { storeId }
  } = useStore();
  const onSubmit = async (values: any) => {
    if (storeId) {
      const { username, password } = values;
      try {
        const { data } = await loginGroceryBizEmployee({
          variables: {
            input: {
              password,
              userName: username.replace(/\s/g, ''),
              storeId: storeId
            }
          }
        });

        if (data && data.loginGroceryBizEmployee) {
          const {
            employee: { displayName },
            token
          } = data.loginGroceryBizEmployee;

          if (firebase) {
            const { user } = await firebase.signInWithCustomToken(token);

            if (user) {
              await user.updateProfile({
                displayName
              });
              localStorage.setItem('ops_store_id', storeId);
              history.push('/grocerystore/' + storeId + '/home');
            }
          }
        }
      } catch (e) {
        snackbar.enqueueSnackbar('Invalid credentials.', { variant: 'error' });
      }
    } else {
      alert('Invalid store!');
    }
  };

  if (currentUser) {
    localStorage.setItem('ops_store_id', storeId);
    return <Redirect to={'/grocerystore/' + storeId + '/home'} />;
  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ handleSubmit, isValid, values, handleChange, handleBlur, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <Box className={classes.formWrapper}>
            <TextField
              name="username"
              label="username"
              placeholder="Username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              fullWidth={true}
              className={classes.textField}
              error={errors.username && touched.username ? true : false}
              helperText={touched.username ? errors.username : ''}
            />
            <TextField
              type="password"
              name="password"
              label="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              fullWidth={true}
              className={classes.textField}
              error={errors.password && touched.password ? true : false}
              helperText={touched.password ? errors.password : ''}
            />
            <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
              Login
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
