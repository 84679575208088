export const groceryStoreInitialState = {
  storeId: null,
  groceryStoreTimeZone: null,
  storeinfo: {
    name: ''
  },
  groceryOpsSettings: {
    disableTakeoutOrderDetailDisplay: false,
    disableDeliveryOrderDetailDisplay: false,
    disableDiningOrderDetailDisplay: false,
    enableTakeoutOrder: false,
    enableDeliveryOrder: false
  }
};

export const groceryStoreActions = {
  SET_GROCERY_STORE: (state: any, payload: any) => ({ ...state, storeId: payload }),
  SET_GROCERY_STORE_INFO: (state: any, payload: any) => ({ ...state, storeInfo: payload }),
  RESET_GROCERY_STORE: (state: any) => ({ ...state, storeId: null }),
  SET_GROCERY_STORE_TIMEZONE: (state: any, payload: any) => ({ ...state, groceryStoreTimeZone: payload }),
  RESET_GROCERY_STORE_TIMEZONE: (state: any) => ({ ...state, groceryStoreTimeZone: null }),
  SET_GROCERY_STORE_OPS_SETTINGS: (state: any, payload: any) => ({ ...state, groceryOpsSettings: payload }),
  RESET_GROCERY_STORE_OPS_SETTINGS: (state: any) => ({
    ...state,
    groceryOpsSettings: {
      disableTakeoutOrderDetailDisplay: false,
      disableDeliveryOrderDetailDisplay: false,
      disableDiningOrderDetailDisplay: false,
      enableTakeoutOrder: false,
      enableDeliveryOrder: false
    }
  })
};

export default {};
