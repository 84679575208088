import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from 'pages/GroceryStoreDashboard/Login';
import Home from 'pages/GroceryStoreDashboard/Home';
import GroceryPrivateRoute from 'components/GroceryPrivateRoute';

const GroceryStoreRoutes: React.FC = () => {
  return (
    <Switch>
      <GroceryPrivateRoute path="/grocerystore/:storeId/home" component={Home} />
      <Route path="/grocerystore/:storeId" component={Login} />
    </Switch>
  );
};

export default GroceryStoreRoutes;
