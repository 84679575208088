export const getPaymentTypeString = {
  ONLINE_STRIPE: 'Paid online on Stripe',
  ONSITE: 'To be paid by cash',
  ONSITE_CASH: 'To be paid by cash',
  ONSITE_VISA_CARD: 'To be paid by VISA card',
  ONSITE_MASTER_CARD: 'To be paid by MASTER card',
  ONSITE_AMERICAN_EXPRESS_CARD: 'To be paid by AMERICAN EXPRESS card',
  ONSITE_DISCOVER_CARD: 'To be paid by DISCOVER card',
  ONSITE_APPLE_PAY: 'To be paid by Apple Pay',
  ONSITE_GOOGLE_PAY: 'To be paid by Google Pay'
};
