import * as Yup from 'yup';

const regex = new RegExp(/^[0-9]+(\.[0-9][0-9]?)?$/);

export default (subTotal: string) => {
  return Yup.object().shape({
    adjustAmount: Yup.string()
      .required('Required')
      .matches(regex, 'Invalid price value.')
      .test('value', 'Amount should be less than subtotal', (value: any) => {
        if (value && !isNaN(parseFloat(value)) && !isNaN(parseFloat(subTotal))) {
          if (parseFloat(value) < parseFloat(subTotal)) {
            return true;
          } else {
            return false;
          }
        }

        return false;
      })
      .test('min', 'Adjust amount should be more than 0', (value: any) => {
        if (value && !isNaN(parseFloat(value))) {
          if (parseFloat(value) === 0) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }),
    adjustReason: Yup.string()
      .required('Required')
      .max(100)
  });
};
