import React from 'react';
import { Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { getTheme } from '@lokobee/lokobee-ui';
import { AuthProvider } from 'fbase/authContext';
import { FirebaseContext, Firebase } from 'fbase';
import AppRoutes from 'routes';
import { LoaderStore } from 'store';
import Loader from 'components/Loader';
import { SnackbarProvider } from 'notistack';
import grey from '@material-ui/core/colors/grey';
import Footer from 'components/Footer';
import useClearCache from 'hooks/useClearCache';

const App: React.FC = () => {
  const { state, refetchCacheAndReload } = useClearCache();

  if (!state.loading && !state.isLatestVersion) {
    // You can decide how and when you want to force reload
    refetchCacheAndReload();
  }

  if (state.loading) {
    return null;
  }
  return (
    <ThemeProvider theme={getTheme()}>
      <FirebaseContext.Provider value={{ firebase: new Firebase() }}>
        <AuthProvider>
          <LoaderStore>
            <SnackbarProvider
              preventDuplicate={true}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <Box display="flex" flexDirection="column" height="100%" bgcolor={grey[100]}>
                <Box flex={1}>
                  <Loader />
                  <AppRoutes />
                  <Footer sticky={true} />
                </Box>
              </Box>
            </SnackbarProvider>
          </LoaderStore>
        </AuthProvider>
      </FirebaseContext.Provider>
    </ThemeProvider>
  );
};

export default App;
