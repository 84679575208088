import { makeStyles } from '@material-ui/core';

export interface IStyleProps {
  backgroundImage?: string;
}

export const useStyles = makeStyles((theme) => ({
  root: ({ backgroundImage }: IStyleProps) => ({
    backgroundImage,
    backgroundSize: 'cover',
    height: '100vh',
    position: 'relative' as any,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  paper: {
    minWidth: 500,
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 20
  },
  appIcon: {
    width: 100,
    height: 100,
    margin: 20
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    margin: 0,
    width: '100%'
  },
  textField: {
    marginBottom: theme.spacing(2)
  }
}));
