import { DocumentNode } from 'graphql';
import { QueryResult } from '@apollo/react-common';
import { useQuery, QueryHookOptions, useLazyQuery, LazyQueryHookOptions, QueryLazyOptions, useMutation, MutationHookOptions, MutationTuple } from '@apollo/react-hooks';
import { useStore } from 'store';
import { useEffect } from 'react';

export const useQueryWithLoader = <TData = any, TVariables = Record<string, any>>(query: DocumentNode, options?: QueryHookOptions<TData, TVariables> | undefined): QueryResult<TData, TVariables> => {
  const { dispatch } = useStore();

  const queryResult = useQuery(query, {
    ...options,
    onCompleted: (data: TData) => {
      if (options && options.onCompleted) {
        options.onCompleted(data);
      }

      muteLoader();
    },
    onError: () => {
      muteLoader();
    }
  });

  const { loading } = queryResult;

  const muteLoader = () =>
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });

  useEffect(() => {
    if (loading) {
      dispatch({
        type: 'LOADER_INCREMENT_COUNT'
      });
    }
  }, [dispatch, loading]);

  return queryResult;
};

export const useLazyQueryWithLoader = <TData = any, TVariables = Record<string, any>>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables> | undefined,
  showLoader = true
): [(options?: QueryLazyOptions<TVariables> | undefined) => void, QueryResult<TData, TVariables>] => {
  const { dispatch } = useStore();

  const queryResult = useLazyQuery(query, {
    ...options,
    onCompleted: (data: TData) => {
      if (options && options.onCompleted) {
        options.onCompleted(data);
      }

      showLoader && muteLoader();
    },
    onError: () => {
      showLoader && muteLoader();
    }
  });

  const [, { loading }] = queryResult;

  const muteLoader = () =>
    showLoader &&
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });

  useEffect(() => {
    if (showLoader && loading) {
      dispatch({
        type: 'LOADER_INCREMENT_COUNT'
      });
    }
  }, [dispatch, loading, showLoader]);

  return queryResult;
};

export const useMutationWithLoader = <TData = any, TVariables = Record<string, any>>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables> | undefined,
  showLoader = true
): MutationTuple<TData, TVariables> => {
  const { dispatch } = useStore();

  const queryResult = useMutation(mutation, {
    ...options,
    onCompleted: (data: TData) => {
      if (options && options.onCompleted) {
        options.onCompleted(data);
      }

      showLoader && muteLoader();
    },
    onError: () => {
      showLoader && muteLoader();
    }
  });

  const [, { loading }] = queryResult;

  const muteLoader = () =>
    showLoader &&
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });

  useEffect(() => {
    if (showLoader && loading) {
      dispatch({
        type: 'LOADER_INCREMENT_COUNT'
      });
    }
  }, [dispatch, loading, showLoader]);

  return queryResult;
};
