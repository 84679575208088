export interface IDiscount {
  price: string;
  percentage: string;
}

export enum DeliveryProviders {
  Self = 'SELF',
  Boons = 'BOONS',
  Buying = 'BUYING',
  DoordashClassic = 'DOORDASH_CLASSIC'
}

export enum OrderType {
  Takeout = 'TAKEOUT',
  Delivery = 'DELIVERY',
  Dining = 'DINING'
}
export interface IDeliveryConfirmInput {
  cookingWaitMins: number;
  handlingIns: string;
  bagNumber: number;
  type?: OrderType;
  deliveryProvider?: DeliveryProviders;
  deliveryQuoteId?: string;
}
