import { notifyType } from 'types';

export const notificationInitialState = {
  notify: 0
};

export const notificationActions = {
  SET_NOTIFY: (state: any, payload: notifyType) => {
    return { ...state, notify: payload };
  },
  UNSET_NOTIFY: (state: any) => {
    return { ...state, notify: 0 };
  }
};
