import React from 'react';
import { Box, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { useStyles } from '../style';
import { useUpdateDish } from 'graphql/hooks/dishes.hooks';

import { useSnackbar } from 'notistack';
import { DISH_STATUS_ACTIVE, DISH_STATUS_HIDDEN } from '../index';
interface IProps {
  isCatagoryItem: boolean;
  dishTitle: string;
  dishStatus: string;
  dishId?: string;
  categoryDishesIds?: Array<string>;
}
const SingleDishItem = ({ isCatagoryItem, dishId, categoryDishesIds, dishTitle, dishStatus }: IProps) => {
  const { updateDish } = useUpdateDish();
  const isHiddenDish: boolean = dishStatus === DISH_STATUS_HIDDEN ? true : false;
  const classes = useStyles();

  const snackbar = useSnackbar();
  const toggleStatus = () => {
    if (dishStatus === DISH_STATUS_HIDDEN) {
      return DISH_STATUS_ACTIVE;
    } else {
      return DISH_STATUS_HIDDEN;
    }
  };
  const updateSingleDishItem = async () => {
    if (dishId) {
      const newStatus = toggleStatus();
      const response = await updateDish([dishId], newStatus);
      if (response && response.data && response.data.restaurantEmployeeUpdateDishStatus) {
        snackbar.enqueueSnackbar('Dish updated succesfully', {
          variant: 'success'
        });
      } else {
        snackbar.enqueueSnackbar('Dish status update failed!', {
          variant: 'error'
        });
      }
    }
  };
  const updateDishGroup = async () => {
    if (categoryDishesIds) {
      const newStatus = toggleStatus();
      const response = await updateDish(categoryDishesIds, newStatus);
      if (response && response.data && response.data.restaurantEmployeeUpdateDishStatus) {
        snackbar.enqueueSnackbar('Dish status updated succesfully!', {
          variant: 'success'
        });
      } else {
        snackbar.enqueueSnackbar('Dish status update failed!', {
          variant: 'error'
        });
      }
    }
  };

  return (
    <Box marginY={isCatagoryItem ? 2 : 0} paddingX={1} borderBottom={isCatagoryItem ? 0.5 : 0}>
      <Grid container>
        <Grid item xs={8} className={classes.alignCenter}>
          <Box fontWeight={isCatagoryItem ? 'fontWeightBold' : 'fontWeightRegular'}>
            <Typography variant={isCatagoryItem ? 'h6' : 'body2'}>{dishTitle}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            className={classes.switch}
            control={<Switch checked={isHiddenDish} onChange={isCatagoryItem ? updateDishGroup : updateSingleDishItem} />}
            label={isHiddenDish ? 'SHOW' : 'HIDE'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleDishItem;
