import React, { useState } from 'react';
import { useStyles } from './style';
import PrintIcon from '@material-ui/icons/Print';
import { getLocalStorage } from 'util/storage';
import PrinterDialog from 'components/PrinterDialog';
import { Button } from '@material-ui/core';

interface IProps {
  onPrint: () => void;
}

const Print = ({ onPrint }: IProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const onClick = () => {
    const regularPrinter = getLocalStorage('regularPrinter');
    if (regularPrinter === 'true') {
      onPrint();
    } else {
      const printIp = getLocalStorage('printIp');

      if (printIp) {
        onPrint();
      } else {
        setOpen(true);
      }
    }
  };

  return (
    <>
      <Button className={classes.printbtn} startIcon={<PrintIcon />} color="default" variant="outlined" disableElevation={true} size="small" onClick={onClick}>
        Print
      </Button>

      {open && <PrinterDialog open={open} onClose={() => setOpen(false)} onSubmit={onPrint} />}
    </>
  );
};

export default Print;
