import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  inputFiled: {
    width: theme.spacing(10),
    margin: 0
  },
  dialogBoxContentText: {
    paddingLeft: theme.spacing(2),
    color: 'black',
    fontSize: '24px'
  }
}));
