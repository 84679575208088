import Big from 'big.js';
import { formatPhoneNumber } from 'util/number';
import moment from 'moment';
import { getLocalStorage } from 'util/storage';

export const formatPriceToString = (price: any) => {
  if (!price) {
    return null;
  }

  if (price instanceof Big) {
    return price.toFixed(2).toString();
  }

  return Big(price).toFixed(2);
};

export const calculatePoints = (qty: number, points: number) => {
  if (!qty || !points) {
    return 0;
  }

  return qty * points;
};

export const printOrderDetails = (data: any, notify: (msg: string) => void, timeDifferenceType?: any) => {
  if (data) {
    const {
      orderNumber,
      expectTime,
      buyer: { displayName },
      paymentType,
      items,
      type,
      createdAt,
      deliveryAddress,
      deliveryNote,
      note: orderNote,
      orderPhoneNumber,
      seller
    } = data;

    const { name: storeName, address, phone } = seller;

    const { text: storeAddress } = address || { text: '' };

    const storePhone = formatPhoneNumber(phone) ? formatPhoneNumber(phone) : phone;

    let customerPhone = '';

    if (orderPhoneNumber) {
      customerPhone = formatPhoneNumber(orderPhoneNumber) ? formatPhoneNumber(orderPhoneNumber) : orderPhoneNumber;
    } else {
      customerPhone = 'Phone no. NA';
    }

    const expectTimeMoment = moment(expectTime);
    const createdAtMoment = moment(createdAt);

    let orderType = '';
    if (type === 'TAKEOUT') orderType = `Take out`;
    if (type === 'DELIVERY') orderType = `Delivery`;

    const { text: customerDeliveryAddress } = deliveryAddress || { text: '' };

    let itemCount = 0;

    if (items) {
      items.forEach(({ count }: any) => {
        if (count) {
          itemCount += count;
        }
      });
    }

    const regularPrinter = getLocalStorage('regularPrinter');

    if (regularPrinter === 'true') {
      const content = `
      <html>
      <head>
      <meta name="format-detection" content="telephone=no">
      <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
      <style type="text/css">
        @page 
        {
          margin: 0mm; 
        }
        *{ 
          color-adjust: exact;
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact; 
        }
        html{
          margin:0px;
          width:100%;
          display:flex;
          justify-content:center;
          font-family: 'Ubuntu', sans-serif;
        }
        body{
          margin:2px;
          margin-top:20px;
          padding:0;
          width:278.4px
        }
        h1,h2,h3,h4,h5,h6,p{
          margin:0
        }
        .flexbox{
          display:flex;
          justify-content:space-between;
          align-items:center
        }
        .customerDetails{
          padding:5px;
          font-size:10px;
        }
        .customerDetailsHeader{
          font-size:12px;
          align-items: flex-start;
          padding:1px;
        }
        .noWrap{
          white-space: nowrap;
        }
        .boldText{
          font-weight:bold
        }
        .line{
          border-top: 2px dashed black;
        }
        .itemDetails p{
          font-size:15px;
        }
      </style>
      </head>
      
      <body>

      <div style="width:100%">
        <div class="flexbox">
          <h2>${orderNumber.substr(orderNumber.length - 4)}</h2>
          <h2>${orderType.toUpperCase()}</h2>
        </div>
        
        ${type === 'TAKEOUT' || type === 'DELIVERY' ? `<hr class="line" />` : ``} 
        ${type === 'TAKEOUT' && timeDifferenceType ? (timeDifferenceType === 'SCHEDULED' ? `<h2>SCHEDULED</h2>` : `<h2>ASAP</h2>`) : ''}
        ${type === 'DELIVERY' ? `<h2>Scheduled</h2>` : ``}
        <h2>${expectTimeMoment.format('ddd MMM-DD, hh:mm A').toUpperCase()} </h2>
        <hr class="line" />
        
        <div class="flexbox" style="align-items: flex-start;margin-bottom:5px">
          <p style="font-size:14px;">${storeName}</p>
        </div>
        
        <div class="flexbox" style="padding:2px">
          <div>
            <p style="font-size:12px;">${storeAddress}</p>
            <p style="font-size:12px;">${storePhone}</p>
          </div>
        </div>

        <hr class="line" />
        <div class="customerDetails" >
          <div class="flexbox boldText customerDetailsHeader">
            <p>${displayName}</p>
            <p class="noWrap">${paymentType === 'ONLINE_STRIPE' ? 'PAID' : 'PAYMENT DUE'}</p>
          </div>
          <p style="padding:1px">${customerPhone}</p>
          <div class="flexbox" style="align-items: flex-start;padding:1px;">
            <p class="noWrap">Order#: ${orderNumber}</p>
            <p>${createdAtMoment.format('MMM-DD-YYYY HH:mm A')}</p>
          </div>
        </div>

        ${
          type === 'DELIVERY'
            ? `
          
          <div class="customerDetails" style="margin-top:5px;">
            ${deliveryAddress ? `<p><span class="boldText">Delivery Address:</span> ${customerDeliveryAddress}</p>` : ``}
            ${deliveryNote ? `<p><span class="boldText">Delivery Note:</span> ${deliveryNote}</p>` : ``}
          </div>
        `
            : ``
        }

        <div class="itemDetails">
          <hr class="line" />
          <p style="font-size:14px;"> ITEMS (${itemCount})</p>
          <hr class="line" />
          ${items
            .map((item: any) => {
              const { title, count, size } = item;

              return `
                <h5>${count}x ${title} </h5>
                <p>${size}</p>
                <hr class="line" />
              `;
            })
            .join('')}

          ${
            orderNote
              ? `            
          <p style="font-weight:bold">Order Note:</p>
          <p>${orderNote}</p>
          <hr class="line" />
          `
              : ``
          }
        </div>

        <div class="flexbox" style="justify-content:center;font-size:8px;">
          <p>Powered by <span class="boldText">Lokobee</span></p>
        </div>



      </div>
      </body>
      </html>
      `;

      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.visibility = 'hidden';

      hiddFrame.srcdoc = content;

      document.body.appendChild(hiddFrame);
      hiddFrame.onload = () => {
        setTimeout(() => {
          hiddFrame.contentWindow?.print();
        }, 500);

        setTimeout(() => {
          try {
            document.body.removeChild(hiddFrame);
          } catch {}
        }, 2000);
      };
    } else {
      const printerIP = getLocalStorage('printIp');

      const PUBLIC_URL = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://' + window.location.hostname;

      const content = `
        <html>
        <script src="${PUBLIC_URL}/js/epos.js"></script>
        <script>
          // Total cols 48 for size 1
          const totalCols = 48;

          const printer = new epson.ePOSBuilder();

          function sendRequest() {
            if (printer) {            
              var request = printer.toString();
              var address = "https://${printerIP}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000";
              var epos = new epson.ePOSPrint(address);
              epos.onreceive = function (res) {            
                if (!res.success) {
                  window.parent.postMessage({ printerMessage: "Printer error !"}, "*");
                }else{
                  window.parent.postMessage({ printerMessage: "success"}, "*");
                }
              };
              epos.onerror = function (res) {
                window.parent.postMessage({ printerMessage: "Connection Error !"}, "*");
              };
            
              epos.send(request);
              
            }
          }


          function printStrings(string1, string2, width1, height1, width2, height2, wrap, bold1, bold2) {
            if (width1 === 0) {
              width1 = 1;
            }
            if (width2 === 0) {
              width2 = 1;
            }
  
            if (!string1) {
              string1 = "";
            }
            if (!string2) {
              string2 = "";
            }
  
            const leftCols = string1.length * width1;
            const rightCols = string2.length * width2;
  
            if (leftCols + rightCols < totalCols) {
              const remainingCols = totalCols - leftCols - rightCols;
  
              // Print string 1
              printer.addTextSize(width1, height1);
              if (bold1) {
                printBold(string1);
              } else {
                printer.addText(string1);
              }
  
              // Print spaces
              printer.addTextSize(1, 1);
              printer.addText(" ".repeat(remainingCols));
  
              // Print string 2
              printer.addTextSize(width2, height2);
              if (bold2) {
                printBold(string2);
              } else {
                printer.addText(string2);
              }
            } else {
              if (wrap) {
                // Keeping minimum 5 spaces
                // We do not wrap right string
                // Wrap only left string
                const remainingCols = totalCols - 5 - rightCols;
  
                // Print string 1
                printer.addTextSize(width1, height1);
                if (bold1) {
                  printBold(string1.substring(0, remainingCols));
                } else {
                  printer.addText(string1.substring(0, remainingCols));
                }
  
                // Print spaces
                printer.addTextSize(1, 1);
                printer.addText(" ".repeat(5));
  
                // Print string 2
                printer.addTextSize(width2, height2);
                if (bold2) {
                  printBold(string2);
                } else {
                  printer.addText(string2);
                }
  
                // New Line
                printer.addText("\\n");
  
                // Print remaining string1
                printer.addTextSize(width1, height1);
                if (bold1) {
                  printBold(string1.substring(remainingCols));
                } else {
                  printer.addText(string1.substring(remainingCols));
                }
                printer.addText("\\n");
              } else {
                // Print string 1
                printer.addTextSize(width1, height1);
                if (bold1) {
                  printBold(string1);
                } else {
                  printer.addText(string1);
                }
  
                // Print spaces
                printer.addTextSize(1, 1);
                printer.addText(" ".repeat(5));
  
                // Print string 2
                printer.addTextSize(width2, height2);
                if (bold2) {
                  printBold(string2);
                } else {
                  printer.addText(string2);
                }
              }
            }
          }

          function addDivider() {
            printer.addText("\\n");
            printer.addTextSize(2, 2);
            printer.addText("-".repeat(totalCols / 2));
            printer.addText("\\n");
          }
  
          function printBold(text) {
            if (!text) {
              text = "";
            }
            printer.addTextStyle(false, false, true, undefined);
            printer.addText(text);
            printer.addTextStyle(false, false, false, undefined);
          }
  
          function indent(spaces, text) {
            printer.addText(" ".repeat(spaces) + text);
          }

          async function buildPDF(){
            if (printer) {
              printStrings("${orderNumber.substr(orderNumber.length - 4)}", "${expectTimeMoment.format('ddd').toUpperCase()}", 3, 3, 3, 3, true, true, true);
              printStrings("${storeName}", "${orderType.toUpperCase()}", 1, 1, 2, 2, true, false, true);

              printStrings("${storeAddress}", "${type === 'TAKEOUT' || type === 'DELIVERY' ? expectTimeMoment.format('MMM-DD').toUpperCase() : ''}", 1, 1, 2, 2, true, false, true);
  
              printStrings("${storePhone}", "", 1, 1, 2, 2, true, false, true);
  
              printStrings("Scheduled:", "${expectTimeMoment.format('hh:mm A')}", 2, 2, 2, 2, true, false, true);
  
              addDivider();
  
              printStrings("${displayName}", "${paymentType === 'ONLINE_STRIPE' ? 'PAID' : 'PAYMENT DUE'}", 1, 1, 1, 1, true, true, true);
              printStrings("${customerPhone}", "", 1, 1, 1, 1, true, false, false);
              printStrings("Order#: ${orderNumber}", "${createdAtMoment.format('MMM-DD-YYYY HH:mm A')}", 1, 1, 1, 1, true, false, false);
  
              addDivider();

              ${
                type === 'DELIVERY'
                  ? `              
                  printer.addTextSize(1, 1);
                  ${deliveryAddress ? `printBold("Delivery Address: ");printer.addText("${customerDeliveryAddress}\\n");` : ``}
                  ${deliveryNote ? `printBold("Delivery Note: ");printer.addText("${deliveryNote}\\n");` : ``}
                  addDivider();              
              `
                  : ``
              }

              printer.addTextSize(2, 2);
              printBold("Items(${itemCount})");
    
              addDivider();

              ${items
                .map((item: any) => {
                  const { title, count, size } = item;

                  return `
                    printer.addTextSize(1, 1);
                    printBold("${count}x ${title}\\n");
                    printer.addText("${size}");
                    addDivider();
                  `;
                })
                .join('')}

              ${
                orderNote
                  ? `            
                    printBold("Order Note:");
                    printer.addText("${orderNote}");
                    addDivider();
                `
                  : ``
              }

              printer.addTextSize(1, 1);
              printer.addTextAlign(printer.ALIGN_CENTER);
              printer.addText("Powered by ");
              printBold("Lokobee");


              printer.addText("\\n");
              printer.addText("\\n");
              printer.addCut(printer.CUT_FEED);

              sendRequest();
            }
          }

          setTimeout(() => {
            buildPDF()
          }, 1000);


        </script>
        <body>
          <svg
          style="height: 50px; width: 50px"
          enable-background="new 0 0 24 24"
          id="closeIcon"
          version="1.0"
          viewBox="0 0 24 24"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" >
          <g>
            <path
              d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M16.9,15.5l-1.4,1.4L12,13.4l-3.5,3.5   l-1.4-1.4l3.5-3.5L7.1,8.5l1.4-1.4l3.5,3.5l3.5-3.5l1.4,1.4L13.4,12L16.9,15.5z"
            />
          </g>
        </svg>
      
      </body>
      </html>
      `;

      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.visibility = 'hidden';

      window.addEventListener(
        'message',
        (event: MessageEvent) => {
          if (event.data && event.data.printerMessage) {
            try {
              document.body.removeChild(hiddFrame);
            } catch {}
            if (event.data.printerMessage !== 'success') {
              notify(event.data.printerMessage);
            }
          }
        },
        false
      );

      hiddFrame.srcdoc = content;

      document.body.appendChild(hiddFrame);
    }
  }
};
