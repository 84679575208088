import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Grid } from '@material-ui/core';
import { useStyles } from '../style';
import { useOrderDetailsWithoutLoader } from 'graphql/hooks/groceryStoreOrders.hooks';
import grey from '@material-ui/core/colors/grey';
import { convertPriceTo, formatPhoneNumber } from 'util/number';
import ActionButtons from '../ActionButtons';
import OrderItems from '../OrderItems';
import { getPaymentTypeString } from './constants';
import moment from 'moment';
import { useStore } from 'store';
import { filter, isNull } from 'lodash';
import { useSnackbar } from 'notistack';
import { find } from 'lodash';
import { printOrderDetails } from '../utils';
import AdjustAmount from '../AdjustAmount';
import { makeReadable } from 'util/strings';
import { DeliveryProviders } from 'generated/custom';
interface IProps {
  orderId: string;
  buyerId: string;
}

const OrderDetails = ({ orderId, buyerId }: IProps) => {
  const classes = useStyles();

  const {
    state: { opsSettings }
  } = useStore();

  const { disableTakeoutOrderDetailDisplay = false, disableDeliveryOrderDetailDisplay = false } = opsSettings || {
    disableTakeoutOrderDetailDisplay: false,
    disableDeliveryOrderDetailDisplay: false
  };

  const { data, loading } = useOrderDetailsWithoutLoader(orderId, buyerId);

  const [load, setLoad] = useState(false);

  const snackbar = useSnackbar();

  useEffect(() => {
    setLoad(loading);
  }, [loading]);

  if (!loading && data && !load) {
    const {
      orderNumber,
      expectTime,
      buyer: { displayName },
      subTotal,
      tip,
      total,
      totalTax,
      deliveryFee,
      couponDiscount,
      coupon,
      status,
      paymentType,
      items,
      type,
      createdAt,
      deliveryDistance,
      deliveryAddress,
      deliveryNote,
      note,
      timeLines,
      lokobeeFee,
      orderPhoneNumber,
      redeemPoints,
      adjustAmount,
      adjustReason,
      totalAfterAdjust,
      deliveryProvider,
      bizExpectTime
    } = data;

    const paymentTypeString = (getPaymentTypeString as any)[paymentType];

    const now = moment();

    const expectTimeMoment = moment(expectTime);

    const createdAtMoment = moment(createdAt);

    const bizExpectTimeMoment = moment(bizExpectTime);

    const todayOrder = now.date() === expectTimeMoment.date() && now.month() === expectTimeMoment.month() && now.year() === expectTimeMoment.year();

    const { text: _deliveryAddress } = deliveryAddress || { text: '' };

    const orderType: any = {
      TAKEOUT: 'TAKEOUT',
      DELIVERY: 'DELIVERY'
    };

    const displayBill = (type === 'TAKEOUT' && !disableTakeoutOrderDetailDisplay) || (type === 'DELIVERY' && !disableDeliveryOrderDetailDisplay);

    const timeLine: any = filter(timeLines || [], ({ status: timeLineStatus }) => timeLineStatus === status);

    let msg = '';

    if (timeLine.length) {
      msg = timeLine[0].msg || '';
    }

    const couponCode = coupon ? coupon.code || '' : '';

    const lokobeeFeeNumber = lokobeeFee ? parseFloat(convertPriceTo(lokobeeFee, 'DOLLAR')) : 0.0;

    const taxNumber = totalTax ? parseFloat(convertPriceTo(totalTax, 'DOLLAR')) : 0.0;
    const deliveryFeeNumber = deliveryFee ? parseFloat(convertPriceTo(deliveryFee, 'DOLLAR')) : 0;

    const tipNumber = tip ? parseFloat(convertPriceTo(tip, 'DOLLAR')) : 0;

    let customerPhone = '';
    const difference = expectTimeMoment.diff(createdAtMoment, 'm');
    const timeDifferenceType = difference <= 30 ? 'ASAP' : 'SCHEDULED';

    if (orderPhoneNumber) {
      customerPhone = formatPhoneNumber(orderPhoneNumber) ? formatPhoneNumber(orderPhoneNumber) : orderPhoneNumber;
    } else {
      customerPhone = 'Phone no. NA';
    }

    const checkAlcohol = (items: any) => {
      const isAlcoholic = find(items, (item) => item.isAlcohol);

      return !!isAlcoholic;
    };

    const handlePrint = () => {
      printOrderDetails(
        data,
        (msg: string) => {
          snackbar.enqueueSnackbar(msg, { variant: 'error' });
        },
        timeDifferenceType
      );
    };

    const Row = ({ children }: any) => {
      return (
        <Box padding={1} borderTop={`solid 2px ${grey[500]}`}>
          {children}
        </Box>
      );
    };

    let itemCount = 0;

    items.forEach(({ count }: any) => {
      if (count) {
        itemCount += count;
      }
    });
    const showAdjustAmountBtn = () => {
      if (!subTotal) {
        return false;
      }

      if (paymentType !== 'ONLINE_STRIPE') {
        return false;
      }

      if (adjustAmount) {
        return false;
      }

      if (status === 'PLACED') {
        return false;
      }

      if (type === 'TAKEOUT' || type === 'DELIVERY') {
        const ETADiff = moment.duration(now.diff(expectTimeMoment)).asHours();

        if (ETADiff > 24) {
          return false;
        }
      }

      return true;
    };
    return (
      <Box width="100%" bgcolor={grey[300]} paddingBottom={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between" padding={2} bgcolor="#FFF">
          <Box>
            <Typography variant="h6">{displayName}</Typography>
            <Typography variant="body2" className={classes.boldText}>
              #{orderNumber}
            </Typography>
          </Box>
          <Box>
            <ActionButtons
              orderId={orderId}
              orderType={orderType[type]}
              orderStatus={status}
              buyerId={buyerId}
              setLoading={setLoad}
              onPrint={handlePrint}
              deliveryProviders={deliveryProvider}
              isFutureOrder={!todayOrder && expectTimeMoment.isAfter(now)}
            />
          </Box>
        </Box>
        <Box>
          {(status === 'REJECTED' || status === 'CANCELLED') && (
            <Box bgcolor="#FFF" paddingX={2}>
              <Typography variant="body2" color="error">
                {msg}
              </Typography>
            </Box>
          )}
        </Box>
        <Box padding={2}>
          <Grid container={true}>
            <Grid item={true} xs={3} sm={4}>
              <Typography variant="body2">{customerPhone}</Typography>
            </Grid>
            <Grid item={true} xs={3}>
              <Typography variant="body2" className={paymentType === 'ONLINE_STRIPE' ? classes.greenTextColor : classes.redTextColor}>
                {paymentTypeString}
              </Typography>
            </Grid>
            <Grid item={true} xs={6} sm={5}>
              <Typography variant="body2" align="right">
                REC: {`${createdAtMoment.format('DD-MMM').toUpperCase()} ${createdAtMoment.format('hh:mm A')}`}
              </Typography>

              {(type === 'TAKEOUT' || (type === 'DELIVERY' && deliveryProvider === DeliveryProviders.Self)) && (
                <Typography variant="body2" align="right">
                  ETA: {`${expectTimeMoment.format('DD-MMM').toUpperCase()} ${expectTimeMoment.format('hh:mm A')}`}
                </Typography>
              )}
              {type === 'DELIVERY' && deliveryProvider === DeliveryProviders.DoordashClassic && (
                <Typography variant="body2" align="right">
                  ETA: {`${bizExpectTimeMoment.format('DD-MMM').toUpperCase()} ${bizExpectTimeMoment.format('hh:mm A')}`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid item={true} xs={3} sm={4}>
              <Typography variant="body2" className={classes.boldText}>
                {orderType[type]}
              </Typography>
              {orderType[type] === 'TAKEOUT' && (
                <Typography variant="body2" className={classes.boldText}>
                  {timeDifferenceType}
                  <br />
                  {timeDifferenceType === 'SCHEDULED' && `${expectTimeMoment.format('ddd MMM-DD, hh:mm A').toUpperCase()}`}
                </Typography>
              )}
            </Grid>
            <Grid item={true} xs={3}>
              <Typography variant="body2" className={classes.boldText}>
                ITEMS ({itemCount})
              </Typography>
            </Grid>
            <Grid item={true} xs={6} sm={5}>
              {type === 'DELIVERY' && deliveryDistance !== 0 && (
                <Typography variant="body2" align="right">
                  {deliveryDistance.toFixed(2)} miles
                </Typography>
              )}
            </Grid>
          </Grid>

          <Box>
            {type === 'DELIVERY' && deliveryAddress && (
              <Typography variant="body2">
                <span className={classes.boldText}>Address: </span>
                {_deliveryAddress}
              </Typography>
            )}
          </Box>
          <Box>
            {type === 'DELIVERY' && deliveryNote && (
              <Typography variant="body2">
                <span className={classes.boldText}>Delivery Note: </span>
                {deliveryNote}
              </Typography>
            )}
          </Box>
          {type === 'DELIVERY' && !!deliveryProvider && (
            <Box>
              <Typography variant="body2">
                <span className={classes.boldText}>Delivery Provider: </span>
                {makeReadable(deliveryProvider)}
              </Typography>
            </Box>
          )}
          <Box paddingY={1}>
            {checkAlcohol(items) && (
              <Typography variant="body2" color="secondary">
                Must be Picked up by Someone 21+. ID Check Required.
              </Typography>
            )}
          </Box>
          <Box paddingY={1}>
            {note && (
              <Typography variant="body2">
                <span className={classes.boldText}>Order Note: </span>
                {note}
              </Typography>
            )}
          </Box>
        </Box>
        <OrderItems items={items} />
        <Box paddingX={1}>
          <Row>
            {displayBill && (
              <Box display="flex">
                {showAdjustAmountBtn() && <AdjustAmount subTotal={convertPriceTo(subTotal, 'DOLLAR')} orderId={orderId} buyerId={buyerId} orderNumber={orderNumber} />}
                {adjustAmount && adjustReason && (
                  <Box className={classes.adjustReason}>
                    <Typography variant="body2" className={classes.boldText}>
                      Adjust reason
                    </Typography>
                    <Typography variant="body2">{adjustReason}</Typography>
                  </Box>
                )}
                <Box paddingX={1} width="max-content" marginLeft="auto">
                  {subTotal && (
                    <Box display="flex">
                      <Typography variant="body2">
                        Subtotal ({itemCount} {itemCount > 1 ? 'Items' : 'Item'}):&nbsp;
                      </Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${convertPriceTo(subTotal, 'DOLLAR')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {couponDiscount && (
                    <Box display="flex">
                      <Typography variant="body2">Coupon Discount: {`${couponCode !== '' ? `( ${couponCode} ):` : ''}`} </Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText} color="error">
                          ${convertPriceTo(couponDiscount, 'DOLLAR')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {totalTax !== 0 && lokobeeFee !== 0 && (
                    <Box display="flex">
                      <Typography variant="body2">Tax & fees :</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${(taxNumber + lokobeeFeeNumber).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {totalTax !== 0 && lokobeeFee === 0 && (
                    <Box display="flex">
                      <Typography variant="body2">Tax :</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${taxNumber.toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {type === 'DELIVERY' && deliveryProvider && deliveryProvider === DeliveryProviders.DoordashClassic && (
                    <Box display="flex">
                      <Typography variant="body2">Doordash Fee :</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${(deliveryFeeNumber + tipNumber).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {type === 'DELIVERY' && deliveryFee && deliveryProvider !== DeliveryProviders.DoordashClassic && (
                    <Box display="flex">
                      <Typography variant="body2">Delivery Fee :</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${convertPriceTo(deliveryFee, 'DOLLAR')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {tip && deliveryProvider !== DeliveryProviders.DoordashClassic && (
                    <Box display="flex">
                      <Typography variant="body2">Tip :</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${convertPriceTo(tip, 'DOLLAR')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {total && (
                    <Box display="flex">
                      <Typography variant="body2">Total :</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${convertPriceTo(total, 'DOLLAR')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {!isNull(redeemPoints) && redeemPoints !== 0 && (
                    <Box display="flex">
                      <Typography variant="body2">PTS Redeemed:&nbsp;</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          {`${redeemPoints}`}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {adjustAmount && (
                    <Box display="flex">
                      <Typography variant="body2">Adjust amount :</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${convertPriceTo(adjustAmount, 'DOLLAR')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {totalAfterAdjust && (
                    <Box display="flex">
                      <Typography variant="body2">Total after adjust :</Typography>
                      <Box textAlign="right" flexGrow={1}>
                        <Typography variant="body2" className={classes.boldText}>
                          ${convertPriceTo(totalAfterAdjust, 'DOLLAR')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Row>
        </Box>
      </Box>
    );
  }

  return (
    <Box height="100%" width="100%" bgcolor="rgba(0,0,0,0.5)">
      <Grid item={true} xs={6} lg={5} className={classes.loadingIcon}>
        <CircularProgress />
      </Grid>
    </Box>
  );
};

export default OrderDetails;
