import { Box, Button, ButtonGroup, DialogActions, DialogContent, DialogTitle, FormControl, TextareaAutosize, TextField, Typography } from '@material-ui/core';
import { Dialog } from '@lokobee/lokobee-ui';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { useStyles } from './style';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useGetWaitTimeContext } from 'context/WaitMinsContext';
import { DeliveryProviders, IDeliveryConfirmInput } from 'generated/custom';
import { isEmpty } from 'lodash';
interface IInitialValue {
  cookingWaitMins: number;
  handlingIns: string;
}

const Minutes_Step = 5;

interface IProps {
  closePopUp: () => void;
  onSubmit: (deliveryConfirmInput: IDeliveryConfirmInput) => void;
  deliveryProvider: DeliveryProviders;
}

function BagDialogBox({ closePopUp, onSubmit: updateOrderMutation, deliveryProvider }: IProps) {
  const classes = useStyles();

  const [bagNumber, setBagNumber] = useState<number>(1);

  const snackbar = useSnackbar();

  const [initialValues, setInitialValues] = useState<IInitialValue>({
    cookingWaitMins: 0,
    handlingIns: ''
  });

  const contextWaitMins = useGetWaitTimeContext();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      cookingWaitMins: Yup.number()
        .required('Required')
        .min(0),
      handlingIns: deliveryProvider === DeliveryProviders.Boons ? Yup.string().required('Required') : Yup.string()
    }),
    onSubmit: async (formValues) => {
      const { cookingWaitMins, handlingIns } = formValues;
      //Send details to order status update mutation
      updateOrderMutation({
        bagNumber,
        handlingIns,
        cookingWaitMins
      });
    }
  });

  useEffect(() => {
    if (contextWaitMins) {
      setInitialValues((prev) => ({
        cookingWaitMins: contextWaitMins.cookingWaitMins || 0,
        handlingIns: prev.handlingIns
      }));
    }
  }, [contextWaitMins]);

  const { values, handleBlur, handleSubmit, errors } = formik;

  const handleChange = (event: React.ChangeEvent<any>) => {
    setInitialValues({ ...initialValues, handlingIns: event.target.value });
  };

  const [errorState, setErrorState] = useState(false);
  useEffect(() => {
    if (!isEmpty(errors.handlingIns)) {
      setErrorState(true);
    } else if (errors.handlingIns && isEmpty(errors)) {
      setErrorState(true);
    } else if (isEmpty(errors)) {
      setErrorState(false);
    }
  }, [errors]);

  // console.log('errors', errors);

  const onBagDecrement = () => {
    if (bagNumber === 1) {
      return;
    } else {
      setBagNumber(bagNumber - 1);
    }
  };

  const onCookDec = () => {
    let cookingTime = initialValues.cookingWaitMins;
    let newCookingTime = cookingTime - Minutes_Step;
    if (newCookingTime < 0) {
      return;
    } else {
      setInitialValues({ ...initialValues, cookingWaitMins: newCookingTime });
    }
  };

  const onBagNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    let bagQuantity = Number(e.target.value);
    setBagNumber(bagQuantity);
  };

  return (
    <>
      <Dialog dialogProps={{ fullWidth: true, fullScreen: false, maxWidth: 'md' }} open={true} setOpen={closePopUp}>
        <DialogTitle id="form-dialog-title">Package Details</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography noWrap={true} variant="body1">
                  Cooking time (mins)
                </Typography>
                <Box padding={1} />
                <ButtonGroup color="primary">
                  <Button onClick={onCookDec}>-</Button>
                  <TextField
                    classes={{
                      root: classes.inputFiled
                    }}
                    color="primary"
                    autoFocus
                    margin="dense"
                    variant="outlined"
                    defaultValue={1}
                    type="text"
                    size="medium"
                    value={initialValues.cookingWaitMins}
                  />
                  <Button onClick={() => setInitialValues({ ...initialValues, cookingWaitMins: initialValues.cookingWaitMins + Minutes_Step })}>+</Button>
                </ButtonGroup>
              </Box>
              {/* Bag Number  */}
              <Box paddingLeft={2}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography noWrap={true} variant="body1">
                    No. of Bags
                  </Typography>
                  <Box padding={1} />
                  <ButtonGroup color="primary">
                    <Button onClick={onBagDecrement}>-</Button>
                    <TextField
                      classes={{
                        root: classes.inputFiled
                      }}
                      color="primary"
                      autoFocus
                      margin="dense"
                      variant="outlined"
                      defaultValue={1}
                      type="text"
                      size="medium"
                      value={bagNumber}
                      onChange={onBagNumberChange}
                    />
                    <Button onClick={() => setBagNumber(bagNumber + 1)}>+</Button>
                  </ButtonGroup>
                </Box>
                {/* end of bag number */}
              </Box>
            </Box>

            <Box paddingY={2} display="flex" flexDirection="column">
              <TextField
                margin="dense"
                variant="outlined"
                label="Special handling instruction"
                multiline
                rows={4}
                type="text"
                fullWidth={true}
                value={values.handlingIns}
                name="handlingIns"
                onChange={handleChange}
                required={true}
                onBlur={handleBlur}
              />
              {errorState && <Typography style={{ fontStyle: 'italic', color: 'red' }}>This is a required Field</Typography>}
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button disableElevation variant="contained" onClick={closePopUp} color="secondary">
            Cancel
          </Button>
          <Button disableElevation variant="contained" onClick={() => handleSubmit()} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BagDialogBox;
