import { timeColor } from '@lokobee/lokobee-ui';
import { durationIdentifier, newOrderDurationIdentifier, processingOrderDurationIdentifier } from './constants';

export const getTimeDifference = (difference: number) => {
  if (difference >= -30 && difference < -15) {
    return durationIdentifier.IN_30_MINS;
  } else if (difference < -30) {
    return durationIdentifier.BEFORE_30_MINS;
  } else if (difference >= -15 && difference < 0) {
    return durationIdentifier.IN_15_MINS;
  } else if (difference >= 0) {
    return durationIdentifier.OVERDUE;
  } else {
    return 0;
  }
};

export const getPickerBackgroundColor = (arg: number): timeColor => {
  switch (arg) {
    case durationIdentifier.BEFORE_30_MINS:
      return 'GREEN';
    case durationIdentifier.IN_30_MINS:
      return 'YELLOW';
    case durationIdentifier.IN_15_MINS:
      return 'ORANGE';
    case durationIdentifier.OVERDUE:
      return 'RED';
    default:
      return 'BLUE';
  }
};

export const getNewOrderTimeDifference = (difference: number) => {
  if (difference < 0) {
    return newOrderDurationIdentifier.OVERDUE;
  } else if (difference >= 0 && difference <= 2) {
    return newOrderDurationIdentifier.WITHIN_2_MINS;
  } else if (difference > 2 && difference <= 4) {
    return newOrderDurationIdentifier.WITHIN_4_MINS;
  } else if (difference > 4 && difference <= 6) {
    return newOrderDurationIdentifier.WITHIN_6_MINS;
  } else {
    return newOrderDurationIdentifier.OVERDUE;
  }
};

export const getNewOrderPickerBackgroundColor = (arg: number): timeColor => {
  switch (arg) {
    case newOrderDurationIdentifier.WITHIN_2_MINS:
      return 'GREEN';
    case newOrderDurationIdentifier.WITHIN_4_MINS:
      return 'YELLOW';
    case newOrderDurationIdentifier.WITHIN_6_MINS:
      return 'ORANGE';
    case newOrderDurationIdentifier.OVERDUE:
      return 'RED';
    default:
      return 'BLUE';
  }
};

export const getProcessingOrderTimeDifference = (difference: number) => {
  if (difference < 0) {
    return processingOrderDurationIdentifier.OVERDUE;
  } else if (difference > 0 && difference <= 10) {
    return processingOrderDurationIdentifier.WITHIN_10_MINS;
  } else if (difference > 10 && difference <= 20) {
    return processingOrderDurationIdentifier.WITHIN_20_MINS;
  } else if (difference > 20 && difference <= 30) {
    return processingOrderDurationIdentifier.WITHIN_30_MINS;
  } else {
    return newOrderDurationIdentifier.OVERDUE;
  }
};

export const getProcessingOrderPickerBackgroundColor = (arg: number): timeColor => {
  switch (arg) {
    case processingOrderDurationIdentifier.WITHIN_10_MINS:
      return 'GREEN';
    case processingOrderDurationIdentifier.WITHIN_20_MINS:
      return 'YELLOW';
    case processingOrderDurationIdentifier.WITHIN_30_MINS:
      return 'ORANGE';
    case processingOrderDurationIdentifier.OVERDUE:
      return 'RED';
    default:
      return 'BLUE';
  }
};
