import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useStyles } from './style';
import { useIncreaseOrderExpectedTime } from 'graphql/hooks/restaurant.hooks';
import moment, { Moment } from 'moment';
import { IDeliveryConfirmInput } from 'generated/custom';
import { STATUS_TYPES } from 'pages/RestaurantDashboard/Orders/ActionButtons/type';

interface IProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  buyerId: string;
  orderId: string;
  acceptOrder: (status: STATUS_TYPES, deliveryConfirmInput?: IDeliveryConfirmInput, delayEtaBy?: number) => Promise<void>;
  orderStatus: string;
  expectTime: Moment;
}

const minutesStep = 5;

function EditEta({ openDialog, setOpenDialog, buyerId, orderId, acceptOrder, orderStatus, expectTime }: IProps) {
  const [currentExpectedTime, setCurrentExpectedTime] = useState<number>(5);
  const [remainingTime, setRemainingTime] = useState<number>(0);

  const classes = useStyles();

  const snackbar = useSnackbar();

  useEffect(() => {
    const timeRemaining = moment(expectTime).diff(moment.now(), 'minutes', true);

    const timeRemainingRound = Math.trunc(timeRemaining);

    const actualTimeRemaining = timeRemainingRound < minutesStep ? minutesStep : timeRemainingRound;

    setCurrentExpectedTime(actualTimeRemaining);
    setRemainingTime(actualTimeRemaining);
  }, [expectTime]);

  const { increaseOrderExpectedTimeBy } = useIncreaseOrderExpectedTime(buyerId, orderId);

  const submitButtonText = orderStatus === 'PLACED' ? 'Confirm Order' : 'Submit';

  const onETADec = () => {
    if (currentExpectedTime <= remainingTime) {
      return;
    } else {
      const newExpectedTime = currentExpectedTime - minutesStep;
      setCurrentExpectedTime(newExpectedTime);
    }
  };

  function onSubmit() {
    if (currentExpectedTime === 0) {
      snackbar.enqueueSnackbar('Cannot increment expected time by 0 mins', { variant: 'error' });
    } else {
      orderStatus === 'ACCEPTED' && callETAApi();
      orderStatus === 'PLACED' && acceptOrder('ACCEPTED', undefined, currentExpectedTime);
    }
  }

  async function callETAApi() {
    const response = await increaseOrderExpectedTimeBy(currentExpectedTime);
    if (response) {
      snackbar.enqueueSnackbar('Wait time updated succesfully.', { variant: 'success' });
    } else {
      snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }
  }

  return (
    <Dialog open={openDialog}>
      <DialogTitle>{'How much time is needed to make this order ready?'}</DialogTitle>

      <DialogContent>
        <Box paddingY={1} display="flex" alignItems="center" justifyContent="center">
          <ButtonGroup color="primary">
            <Button onClick={onETADec}>-</Button>
            <TextField
              classes={{
                root: classes.inputFiled
              }}
              color="primary"
              autoFocus
              margin="dense"
              variant="outlined"
              type="text"
              size="medium"
              value={currentExpectedTime}
            />
            <Button onClick={() => setCurrentExpectedTime(currentExpectedTime + minutesStep)}>+</Button>
          </ButtonGroup>
          <span className={classes.dialogBoxContentText}>Minutes</span>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" disableElevation={true} onClick={onSubmit}>
          {submitButtonText}
        </Button>
        <Button
          variant="outlined"
          color="default"
          onClick={() => {
            setOpenDialog(false);
            setCurrentExpectedTime(0);
          }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditEta;
