import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  printIcon: {
    margin: theme.spacing(0, 1)
  },
  onlineStatus: {
    color: theme.palette.success.main
  },
  offlineStatus: {
    color: theme.palette.error.main
  },
  container: {
    cursor: 'pointer'
  }
}));
