import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { getTimeDifference, getPickerBackgroundColor } from './utils';
import { useStore } from 'store';
import { words } from 'lodash';
import { Card, Typography, Box } from '@material-ui/core';
import { useStyles } from '../style';
import { formatPhoneNumber } from 'util/number';
import { Link } from 'react-router-dom';

interface IProps {
  /** @property { string } - Unique identifier to identify order */
  orderId: string;
  /** @property { string | number } - Total amount of purchase */
  total: number | string;
  /** @property { number | string } - Phone number of buyer */
  phone: number | string;
  /** @property { string } - Name of buyer */
  name: string;
  /** @property { number | string } - Expected arrival time of order. Generally is of timestamp type */
  expectedTime: number | string;
  /** @property { boolean } - If true card will be selectable otherwise redirect user to order information page */
  isSelectable?: boolean;
  /** @property { boolean } - Note: Only active when isSelectable is true. Defines whether card is selected or not */
  isSelected?: boolean;
  /** @property { Function } - Handler for setting isSelected Flag */
  setSelected?: (orderId: string, buyerId: string) => void;
  /** @property { string | number } - Tells at time order was created. It is of timestamp type */
  createdAt: string | number;
  /** @property { string } - UUID of order */
  orderNumber: string;
  buyerId: string;
  status: string;
  type: string;
  distance?: number;
}

const TakeOutOrderCard = ({ orderId, orderNumber, total, phone, name, expectedTime, isSelectable = false, isSelected, setSelected, createdAt, buyerId, status, type, distance }: IProps) => {
  const classes = useStyles();

  const {
    state: { restaurantId }
  } = useStore();

  const toPath = '/' + restaurantId + '/home/'.concat(orderId.toString());

  const [remainingTime, setRemainingTime] = useState<null | string>(null);
  /**
   * This holds time difference between Pick up time and current time.
   * It is used to set color to pick up bar.
   */
  const [timeDifference, setTimeDifference] = useState(0);

  const [overdue, setOverdue] = useState(false);
  /**
   * Holds time when order was received.
   */
  const [receivedIn, setReceivedIn] = useState<null | string>(null);

  /**
   * Similar to componentDidUpdate. Excutes only when there is change in remainingTime.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      const currentMoment = Moment();

      const expectedTimeMoment = Moment(expectedTime);

      const createdAtMoment = Moment(createdAt);

      const difference = +currentMoment.diff(expectedTimeMoment, 'm');

      const _timeDiffernce = getTimeDifference(difference);

      setTimeDifference(_timeDiffernce);

      /**
       * Calculates remaining time for pickup.
       */
      if (expectedTimeMoment.isBefore(currentMoment)) {
        setRemainingTime(expectedTimeMoment.format('D MMM'));

        setOverdue(true);
      } else {
        let rt = expectedTimeMoment.from(currentMoment, true).toString();

        setOverdue(false);
        setRemainingTime(rt);
      }

      if (createdAtMoment.isBefore(currentMoment)) {
        const _receivedIn = createdAtMoment.toNow(true).toString();
        setReceivedIn(_receivedIn);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createdAt, expectedTime, remainingTime, status]);

  const stringArray = words(remainingTime || undefined);
  const formattedString =
    !!remainingTime && !!stringArray && stringArray.length === 2 ? (
      <>
        <span>{(stringArray as any)[0].toString()}</span> <span className="">{(stringArray as any)[1].toString()}</span>
      </>
    ) : (
      remainingTime
    );

  const pickerClassName = timeDifference ? (classes as any)[getPickerBackgroundColor(timeDifference)] : null;

  const content = (
    <Card className={isSelectable && isSelected ? classes.cardSelected : classes.card}>
      <Box className={classes.cardContainer}>
        <Box flexGrow={1}>
          <Typography variant="body2" className={classes.cardText}>
            <span className={classes.boldText}>Order # :</span> {orderNumber}
          </Typography>
          <Typography variant="body2" className={classes.cardText}>
            <span className={classes.boldText}>Total :</span> ${total}
          </Typography>
          {phone && (
            <Typography variant="body2" className={classes.cardText}>
              <span className={classes.boldText}>Phone : </span>
              {formatPhoneNumber(phone.toString()) ? formatPhoneNumber(phone.toString()) : phone}
            </Typography>
          )}
          <Typography variant="body2" className={classes.cardText}>
            <span className={classes.boldText}>Nickname :</span> {name}
          </Typography>
          <Typography variant="body2" className={classes.cardText}>
            <span className={classes.boldText}>Received :</span> {receivedIn} ago
          </Typography>
          {type === 'DELIVERY' && !!distance && (
            <Typography variant="body2" className={classes.cardText}>
              <span className={classes.boldText}>Distance :</span> {distance?.toFixed(2)} miles
            </Typography>
          )}
        </Box>
        <Box className={classes.pickerConatainer}>
          <Box className={`${classes.picker} ${classes.blue}`} width="max-content" margin={1} textAlign="center">
            {type === 'TAKEOUT' ? (
              <Typography variant="body2">TAKEOUT</Typography>
            ) : (
              <>
                <Typography variant="body2">Delivery</Typography>
              </>
            )}
          </Box>
          {formattedString && (status === 'PLACED' || status === 'ACCEPTED' || status === 'READY') && (
            <Box className={`${classes.picker} ${pickerClassName}`} width="max-content">
              <Typography variant="body2">{overdue ? 'Overdue since' : type === 'TAKEOUT' ? 'Pick up in' : 'Deliver in'}</Typography>
              <Typography variant="h6">{formattedString}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );

  const onClick = () => {
    if (setSelected) {
      setSelected(orderId, buyerId);
    }
  };

  return (
    <Box width="100%" paddingY={1} onClick={onClick}>
      {isSelectable ? content : <Link to={{ pathname: toPath, state: { buyerId } }}>{content}</Link>}
    </Box>
  );
};

export default TakeOutOrderCard;
