import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Dialog, TextField, ButtonGroup } from '@material-ui/core';
import { useUpdateWaitMins } from 'graphql/hooks/restaurant.hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useStore } from 'store';
import { useStyles } from '../style';
import OrderPause from '../OrderPause';
import { useHistory } from 'react-router-dom';
import { useGetWaitTimeContext } from 'context/WaitMinsContext';

type minuteType = 'takeout' | 'delivery' | 'cookingTime';

const Minutes_Step = 5;

interface IInitialValue {
  takeOutWaitMins: number;
  deliveryWaitMins: number;
  cookingWaitMins: number;
}

interface IProps {
  restaurantId: string;
}

const WaitMins = ({ restaurantId }: IProps) => {
  const history = useHistory();

  const dishesMode = history.location.pathname === '/' + restaurantId + '/home/dishes';

  const contextWaitMins = useGetWaitTimeContext();

  const { updateWaitMins } = useUpdateWaitMins();

  const [openDialog, setOpenDialog] = useState(false);

  const [activeOrderType, setactiveOrderType] = useState<minuteType>('takeout');

  const snackbar = useSnackbar();

  const classes = useStyles();

  const {
    state: { opsSettings }
  } = useStore();

  const { enableTakeoutOrder = false, enableDeliveryOrder = false, enableCookingTime = true } = opsSettings || { enableTakeoutOrder: false, enableDeliveryOrder: false, enableCookingTime: false };

  const [initialValues, setInitialValues] = useState<IInitialValue>({
    takeOutWaitMins: 0,
    deliveryWaitMins: 0,
    cookingWaitMins: 0
  });

  const onCookDec = () => {
    let cookingTime = initialValues.cookingWaitMins;
    let newCookingTime = cookingTime - Minutes_Step;
    if (newCookingTime < 0) {
      return;
    } else {
      setInitialValues({ ...initialValues, cookingWaitMins: newCookingTime });
    }
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      takeOutWaitMins: Yup.number()
        .required('Required')
        .min(0),
      deliveryWaitMins: Yup.number()
        .required('Required')
        .min(0),
      cookingWaitMins: Yup.number()
        .required('Required')
        .min(0)
    }),
    onSubmit: async (formValues) => {
      const { takeOutWaitMins, deliveryWaitMins, cookingWaitMins } = formValues;

      const response = await updateWaitMins(takeOutWaitMins, deliveryWaitMins, cookingWaitMins);

      setOpenDialog(false);

      if (response) {
        snackbar.enqueueSnackbar('Wait time updated succesfully.', { variant: 'success' });
      } else {
        snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (contextWaitMins) {
      setInitialValues({
        takeOutWaitMins: contextWaitMins.takeOutWaitMins || 0,
        deliveryWaitMins: contextWaitMins.deliveryWaitMins || 0,
        cookingWaitMins: contextWaitMins.cookingWaitMins || 0
      });
    }
  }, [contextWaitMins]);

  const handleClick = (type: minuteType) => {
    setactiveOrderType(type);
    setOpenDialog(true);
  };

  const { values, handleBlur, handleChange } = formik;

  if (contextWaitMins) {
    const { takeOutWaitMins: takeoutWaitMinutes = 0, deliveryWaitMins: deliveryWaitMinutes = 0, cookingWaitMins: cookingWaitMinutes = 0 } = contextWaitMins;

    return (
      <Box display="flex" alignItems="center" flexGrow={1}>
        {enableTakeoutOrder && (
          <Button className={classes.btns} size="small" onClick={() => handleClick('takeout')}>
            TAKEOUT{`(${takeoutWaitMinutes || 0}mins)`}
          </Button>
        )}
        {enableDeliveryOrder && (
          <Button className={classes.btns} size="small" onClick={() => handleClick('delivery')}>
            DELIVERY{`(${deliveryWaitMinutes || 0}mins)`}
          </Button>
        )}
        {enableCookingTime && (
          <Button className={classes.btns} size="small" onClick={() => handleClick('cookingTime')}>
            COOKING TIME{`(${cookingWaitMinutes || 0}mins)`}
          </Button>
        )}

        <OrderPause />
        <Dialog open={openDialog}>
          <Box padding={3} textAlign="center">
            {activeOrderType === 'takeout' && <Typography variant="body1"> Update takeout time</Typography>}
            {activeOrderType === 'delivery' && <Typography variant="body1"> Update delivery time</Typography>}
            {activeOrderType === 'cookingTime' && <Typography variant="body1"> Update cooking time</Typography>}
            <Box paddingY={1}>
              {activeOrderType === 'takeout' && (
                <TextField type="number" variant="outlined" value={values.takeOutWaitMins} name="takeOutWaitMins" onChange={handleChange} onBlur={handleBlur} label="Takeout wait time (mins)" />
              )}
              {activeOrderType === 'delivery' && (
                <TextField type="number" variant="outlined" value={values.deliveryWaitMins} name="deliveryWaitMins" onChange={handleChange} onBlur={handleBlur} label="Delivery time (mins)" />
              )}
              {activeOrderType === 'cookingTime' && (
                <ButtonGroup color="primary">
                  <Button onClick={onCookDec}>-</Button>
                  <TextField
                    classes={{
                      root: classes.inputFiled
                    }}
                    color="primary"
                    autoFocus
                    margin="dense"
                    variant="outlined"
                    defaultValue={1}
                    type="text"
                    size="medium"
                    value={initialValues.cookingWaitMins}
                  />
                  <Button onClick={() => setInitialValues({ ...initialValues, cookingWaitMins: initialValues.cookingWaitMins + Minutes_Step })}>+</Button>
                </ButtonGroup>
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              <Box padding={1}>
                <Button variant="contained" color="primary" disableElevation={true} onClick={() => formik.submitForm()}>
                  Save
                </Button>
              </Box>
              <Box padding={1}>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    setOpenDialog(false);
                    formik.resetForm();
                  }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </Box>
    );
  }

  return null;
};

export default WaitMins;
