import { gql } from 'apollo-boost';

export default gql`
  query getGrocerySellerOngoingOrders($input: GetGrocerySellerOngoingOrdersInput!) {
    getGrocerySellerOngoingOrders(input: $input) {
      id
      total
      orderPhoneNumber
      expectTime
      createdAt
      updatedAt
      orderNumber
      lokobeeFee
      type
      status
      totalTax
      items {
        itemId
        title
        upcCode
        size
        categoryId
        subCategoryId
        brand
        price
        status
        description
        taxMode
        count
        isReward
      }
      buyer {
        uid
        phoneNumber
        email
        displayName
      }
      seller {
        id
        name
        owner {
          email
        }
      }
    }
  }
`;
