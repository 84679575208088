import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { formatPriceToString } from '../utils';
import { useStyles } from '../style';
import { map } from 'lodash';
import CancelIcon from '@material-ui/icons/Cancel';

interface IProps {
  items: any;
}

const OrderItems = ({ items }: IProps) => {
  const classes = useStyles();

  const Row = ({ children }: any) => {
    return (
      <Box padding={1} borderBottom={`solid 2px ${grey[200]}`}>
        {children}
      </Box>
    );
  };

  return (
    <>
      <Row>
        <Grid container={true} className={classes.dishContainer}>
          <Grid item={true} sm={2}>
            <Typography variant="body1" className={classes.boldText}>
              Quantity
            </Typography>
          </Grid>
          <Grid item={true} sm={5}>
            <Typography variant="body1" className={classes.boldText}>
              Items
            </Typography>
          </Grid>
          <Grid item={true} sm={3}>
            <Typography variant="body1" className={classes.boldText}>
              Dish Note
            </Typography>
          </Grid>
          <Grid item={true} sm={2}></Grid>
        </Grid>
      </Row>

      {map(items, (item: any, index) => {
        const dishType = item.dishType || 'REGULAR';

        if (dishType === 'REGULAR') {
          const { dishTitle, count, dishPrice, dishExtras, note, dishOriginalPrice } = item;
          const [{ text: _title }] = dishTitle || [{ text: '' }];

          const [{ text: _dishSize }] = dishOriginalPrice ? dishOriginalPrice.size : [{ text: '' }];

          return (
            <Grid container={true} className={classes.dishContainer}>
              <Grid item={true} sm={2}>
                <Typography variant="body2">{count}</Typography>
              </Grid>

              <Grid item={true} sm={5}>
                <Typography variant="body2">{_title}</Typography>
                {_dishSize && <Typography variant="body2">{_dishSize}</Typography>}

                {!!dishExtras && (
                  <>
                    {map(dishExtras, (extras, index) => {
                      const { groupTitle, items } = extras;

                      return (
                        <Typography variant="body2" key={index}>
                          {groupTitle} : {items.join(', ')}
                        </Typography>
                      );
                    })}
                  </>
                )}
              </Grid>

              <Grid item={true} sm={3}>
                {!!note && <Typography variant="body2">{note}</Typography>}
              </Grid>

              <Grid item={true} sm={2}>
                <Typography variant="body2" align="right">
                  $ {formatPriceToString(dishPrice * count)}
                </Typography>
              </Grid>
            </Grid>
          );
        }

        if (dishType === 'PIZZA') {
          const {
            count,
            dishTitle,
            pizzaCrust,
            toppings: { leftToppings, rightToppings },
            sauces: { leftSauces, rightSauces },
            cheeses: { leftCheeses, rightCheeses },

            halfAndHalf,
            pizzaBasePrice,
            dishPrice,
            note
          } = item;

          const [{ text: _title }] = dishTitle || [{ text: '' }];

          const { title: _dishSize } = pizzaBasePrice && pizzaBasePrice.size ? pizzaBasePrice.size : { title: '' };

          const { title: crust } = pizzaCrust || { title: '' };

          return (
            <Grid container={true} className={classes.dishContainer}>
              <Grid item={true} sm={2}>
                <Typography variant="body2">{count}</Typography>
              </Grid>

              <Grid item={true} sm={5}>
                <Typography variant="body2">Pizza</Typography>
                <Box paddingLeft={2}>
                  {_dishSize && <Typography variant="body2">{_dishSize}</Typography>}

                  <Typography variant="body2">{crust}</Typography>

                  {!halfAndHalf ? (
                    <>
                      <Typography variant="body2">{_title}</Typography>
                      {!!leftSauces.exclusions && !!leftSauces.exclusions.length && (
                        <>
                          {map(leftSauces.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}

                      {!!leftCheeses.exclusions && !!leftCheeses.exclusions.length && (
                        <>
                          {map(leftCheeses.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}

                      {!!leftToppings.exclusions && !!leftToppings.exclusions.length && (
                        <>
                          {map(leftToppings.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!leftSauces.additions && !!leftSauces.additions.length && (
                        <>
                          {map(leftSauces.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!leftCheeses.additions && !!leftCheeses.additions.length && (
                        <>
                          {map(leftCheeses.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!leftToppings.additions && !!leftToppings.additions.length && (
                        <>
                          {map(leftToppings.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant="body2">{'LEFT HALF'}</Typography>
                      <Typography variant="body2">{_title}</Typography>
                      {!!leftSauces.exclusions && !!leftSauces.exclusions.length && (
                        <>
                          {map(leftSauces.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}

                      {!!leftCheeses.exclusions && !!leftCheeses.exclusions.length && (
                        <>
                          {map(leftCheeses.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}

                      {!!leftToppings.exclusions && !!leftToppings.exclusions.length && (
                        <>
                          {map(leftToppings.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!leftSauces.additions && !!leftSauces.additions.length && (
                        <>
                          {map(leftSauces.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!leftCheeses.additions && !!leftCheeses.additions.length && (
                        <>
                          {map(leftCheeses.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!leftToppings.additions && !!leftToppings.additions.length && (
                        <>
                          {map(leftToppings.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}

                      <Typography variant="body2">{'RIGHT HALF'}</Typography>
                      <Typography variant="body2">{_title}</Typography>

                      {!!rightSauces.exclusions && !!rightSauces.exclusions.length && (
                        <>
                          {map(rightSauces.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}

                      {!!rightCheeses.exclusions && !!rightCheeses.exclusions.length && (
                        <>
                          {map(rightCheeses.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}

                      {!!rightToppings.exclusions && !!rightToppings.exclusions.length && (
                        <>
                          {map(rightToppings.exclusions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2}>
                                <CancelIcon className={classes.cancelIcon} />
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!rightSauces.additions && !!rightSauces.additions.length && (
                        <>
                          {map(rightSauces.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!rightCheeses.additions && !!rightCheeses.additions.length && (
                        <>
                          {map(rightCheeses.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                      {!!rightToppings.additions && !!rightToppings.additions.length && (
                        <>
                          {map(rightToppings.additions, (topping) => {
                            return (
                              <Box display="flex" alignItems="center" paddingLeft={2.5}>
                                <Typography variant="body2">{topping}</Typography>
                              </Box>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Grid>

              <Grid item={true} sm={3}>
                {!!note && <Typography variant="body2">{note}</Typography>}
              </Grid>

              <Grid item={true} sm={2}>
                <Typography variant="body2" align="right">
                  $ {formatPriceToString(dishPrice * count)}
                </Typography>
              </Grid>
            </Grid>
          );
        }

        return null;
      })}
    </>
  );
};

export default OrderItems;
