import { gql } from 'apollo-boost';

export default gql`
  query getGroceryStoreByNanoid($input: GetGroceryStoreByNanoidInput!) {
    getGroceryStoreByNanoid(input: $input) {
      id
      name
      logo {
        id
        url
      }
    }
  }
`;
