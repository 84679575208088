import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(1)
  }
}));
