import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Dialog, CircularProgress } from '@material-ui/core';
import { CurrencyInputField } from '@lokobee/lokobee-ui';
import { useFormik, getIn } from 'formik';
import validations from './validations';
import { isEmpty } from 'lodash';
import { useStore } from 'store';
import { convertUSDToCents } from 'util/number';
import { useSnackbar } from 'notistack';
import { useGroceryPartialRefund } from 'graphql/hooks/groceryStoreOrders.hooks';

interface IProps {
  orderId: string;
  orderNumber: string;
  subTotal: string;
  buyerId: string;
}

const AdjustAmount = ({ orderId, orderNumber, subTotal, buyerId }: IProps) => {
  const {
    state: { storeId }
  } = useStore();

  const { addGroceryPartialRefund, loading } = useGroceryPartialRefund();

  const snackbar = useSnackbar();

  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      adjustAmount: '',
      adjustReason: ''
    },
    validationSchema: validations(subTotal),
    onSubmit: async (formValues) => {
      const dollarValue = formValues.adjustAmount;

      const centValue = convertUSDToCents(dollarValue);

      if (centValue !== 0) {
        try {
          const response = await addGroceryPartialRefund({ storeId, orderNumber, orderId, adjustAmount: centValue, adjustReason: formValues.adjustReason, buyerId });

          if (response && response.data) {
            const { orderPartialRefund } = response.data;
            if (orderPartialRefund) {
              snackbar.enqueueSnackbar('Amount adjusted.', { variant: 'success' });
            }
            setOpen(false);
          } else {
            snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
            setOpen(false);
          }
          formik.resetForm();
        } catch (e) {
          console.log(e);
          snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
          setOpen(false);
        }
      }
    }
  });

  const onClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const { values, errors, touched, handleBlur, handleChange } = formik;

  const { adjustAmount, adjustReason } = values;

  return (
    <Box>
      <Button size="small" variant="contained" disableElevation={true} color="primary" onClick={() => setOpen(true)}>
        Adjust Amount
      </Button>
      <Dialog open={open} maxWidth="sm">
        <Box padding={2}>
          <Typography variant="h5">Adjust Amount</Typography>
          <Box paddingY={1} display="flex">
            <Box marginRight={1}>
              <CurrencyInputField
                variant="outlined"
                name="adjustAmount"
                value={adjustAmount}
                label="Adjust amount"
                error={!!getIn(touched, 'adjustAmount') && !!getIn(errors, 'adjustAmount')}
                helperText={!!getIn(touched, 'adjustAmount') && !!getIn(errors, 'adjustAmount') ? getIn(errors, 'adjustAmount') : ''}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                name="adjustReason"
                value={adjustReason}
                label="Adjust reason"
                error={!!getIn(touched, 'adjustReason') && !!getIn(errors, 'adjustReason')}
                helperText={!!getIn(touched, 'adjustReason') && !!getIn(errors, 'adjustReason') ? getIn(errors, 'adjustReason') : ''}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Box>
        <Box paddingY={2} display="flex" justifyContent="flex-end">
          <Box marginX={1}>
            <Button size="small" variant="contained" disableElevation={true} color="primary" onClick={() => formik.submitForm()} disabled={!isEmpty(errors) || loading}>
              Refund
            </Button>
          </Box>
          {loading && (
            <Box marginX={1}>
              <CircularProgress />
            </Box>
          )}
          <Box marginX={1}>
            <Button size="small" variant="outlined" disableElevation={true} color="default" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AdjustAmount;
