import { durationIdentifier } from './constants';

export const getTimeDifference = (difference: number) => {
  if (difference >= -30 && difference < -15) {
    return durationIdentifier.IN_30_MINS;
  } else if (difference < -30) {
    return durationIdentifier.BEFORE_30_MINS;
  } else if (difference >= -15 && difference < 0) {
    return durationIdentifier.IN_15_MINS;
  } else if (difference >= 0) {
    return durationIdentifier.OVERDUE;
  } else {
    return 0;
  }
};

export const getPickerBackgroundColor = (arg: number): string => {
  switch (arg) {
    case durationIdentifier.BEFORE_30_MINS:
      return 'green';
    case durationIdentifier.IN_30_MINS:
      return 'yellow';
    case durationIdentifier.IN_15_MINS:
      return 'orange';
    case durationIdentifier.OVERDUE:
      return 'red';
    default:
      return 'blue';
  }
};
