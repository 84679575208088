import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton, Drawer, Divider } from '@material-ui/core';
import { useStyles } from './style';
import KitchenModeOnIcon from 'assets/img/KitchenModeOn.svg';
import KitchenModeOffIcon from 'assets/img/KitchenModeOff.svg';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'fbase';
import { useApolloClient } from '@apollo/react-hooks';

import { AuthContext } from 'fbase/authContext';
import AccountCircle from '@material-ui/icons/AccountCircle';
import WaitMins from './WaitMins';
import MenuIcon from '@material-ui/icons/Menu';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import BlockIcon from '@material-ui/icons/Block';
import SearchIcon from '@material-ui/icons/Search';
import SecurityIcon from '@material-ui/icons/Security';
import HistoryIcon from '@material-ui/icons/History';
import { red } from '@material-ui/core/colors';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ListIcon from '@material-ui/icons/List';

interface IProps {
  restaurantId: string;
  tableServiceOrderCount: number;
}

const Header = ({ restaurantId, tableServiceOrderCount }: IProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { firebase } = useContext(FirebaseContext);

  const client = useApolloClient();

  const { currentUser } = useContext(AuthContext);

  const onLogOut = async () => {
    try {
      if (firebase) {
        await firebase.signOut();
        client.clearStore();
        localStorage.clear();

        history.push('/' + restaurantId);
      }
    } catch {
      console.log('FAILED TO SIGN OUT');
    }
  };

  const changeRoute = (route: string) => {
    setOpenDrawer(false);
    history.push(route);
  };

  const displayName: string = currentUser ? currentUser.displayName || '' : '';

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <MenuIcon className={classes.drawerIcon} onClick={() => setOpenDrawer(true)} />
          {tableServiceOrderCount !== 0 && <Box className={classes.badge}>{tableServiceOrderCount}</Box>}
        </Box>

        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <Box paddingY={2}>
            <Box paddingX={3} paddingY={1}>
              <AccountCircle className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                WELCOME, {displayName.toUpperCase()}
              </Typography>
            </Box>
            <Divider variant="middle" className={classes.divider} />
            <Box className={classes.link} onClick={() => changeRoute('/' + restaurantId + '/home')} paddingX={3} paddingY={1}>
              <img height="35px" width="35px" className={classes.linkIcon} src={KitchenModeOffIcon} alt="kitchen" />
              <Typography variant="body2" component="span">
                KITCHEN
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" className={classes.link} onClick={() => changeRoute('/' + restaurantId + '/home/tableservices')} paddingX={3} paddingY={1}>
              <RestaurantIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                TABLE SERVICE
              </Typography>
              {tableServiceOrderCount !== 0 && (
                <Box color="#fff" marginLeft={1} paddingY={0.5} paddingX={1} borderRadius="50%" bgcolor={red[500]}>
                  {tableServiceOrderCount}
                </Box>
              )}
            </Box>

            <Box className={classes.link} onClick={() => changeRoute('/' + restaurantId + '/home/dishes')} paddingX={3} paddingY={1}>
              <BlockIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                HIDE DISH
              </Typography>
            </Box>
            <Box className={classes.link} onClick={() => changeRoute('/' + restaurantId + '/home/dishoptions')} paddingX={3} paddingY={1}>
              <ListIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                DISH OPTIONS
              </Typography>
            </Box>

            <Box className={classes.link} onClick={() => changeRoute(`/${restaurantId}/home/history`)} paddingX={3} paddingY={1}>
              <HistoryIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                HISTORY
              </Typography>
            </Box>
            <Box className={classes.link} onClick={() => changeRoute(`/${restaurantId}/home/search`)} paddingX={3} paddingY={1}>
              <SearchIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                SEARCH
              </Typography>
            </Box>

            <Box className={classes.link} onClick={() => changeRoute(`/${restaurantId}/home/privacypolicy`)} paddingX={3} paddingY={1}>
              <SecurityIcon className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                PRIVACY POLICY
              </Typography>
            </Box>

            <Box className={classes.link} onClick={() => changeRoute(`/${restaurantId}/home/useragreement`)} paddingX={3} paddingY={1}>
              <AccountCircle className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                USER AGREEMENT
              </Typography>
            </Box>

            <Box className={classes.link} onClick={onLogOut} paddingX={3} paddingY={1}>
              <ExitToApp className={classes.linkIcon} />
              <Typography variant="body2" component="span">
                LOGOUT
              </Typography>
            </Box>
          </Box>
        </Drawer>
        <WaitMins restaurantId={restaurantId} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
