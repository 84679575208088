
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  inputFiled: {
    width: theme.spacing(10),
    margin: 0,
  },
  btns: {
    textTransform: 'none'
  },

}));