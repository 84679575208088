import { gql } from 'apollo-boost';

export default gql`
  query getAllDishes($input: GetAllDishesInput!) {
    getAllDishes(input: $input) {
      id
      status
      category {
        id
        title {
          text
        }
      }
      title {
        text
      }
    }
  }
`;
