import { useContext, useEffect, useState } from 'react';
import { GET_ORDER_BY_ORDER_NUMBER, GET_GROCERY_ORDER_BY_ORDER_ID, GET_GROCERY_SELLER_ONGOING_ORDERS, GET_GROCERY_SELLER_ORDERS } from 'graphql/query';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { HumanSpeaker } from 'util/speaker';
import { useStore } from 'store';
import { FirebaseContext } from 'fbase';
import { isEmpty, filter, map, sortBy, groupBy, uniq, isEqual } from 'lodash';
import Logger from 'util/logger';
import { useQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import { GROCERY_ORDER_PARTIAL_REFUND, UPDATE_GROCERY_ORDER_STATUS } from 'graphql/mutations';
import { IOrder, notifyType } from 'types';
import Moment from 'moment';

export const useGroceryStoreOrders = () => {
  const {
    state: { storeId },
    dispatch
  } = useStore();

  const { firebase } = useContext(FirebaseContext);

  const { data, loading, error, refetch, called, startPolling, stopPolling } = useQuery(GET_GROCERY_SELLER_ONGOING_ORDERS, {
    variables: {
      input: {
        sellerStoreId: storeId
      }
    }
  });

  const [newOrders, setNewOrders] = useState<any[]>([]);

  /**
   * To check if all orders are of 'type' orderType
   * @param orders The orders to check
   * @param type The orderType value to check for
   * @returns true is all orders are of 'type' orderType
   */

  useEffect(() => {
    if (firebase) {
      firebase
        .getDb()
        .ref('/grocerystore/order/' + storeId)
        .on('value', (value: any) => {
          if (refetch) refetch();
        });
    }
    // Pull orders in every 10 minutes
    startPolling(600000);
    return () => {
      if (firebase) {
        stopPolling();
        firebase
          .getDb()
          .ref('/grocerystore/order/' + storeId)
          .off('value');
      }
    };
  }, [firebase, refetch, startPolling, stopPolling, storeId]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const { getGrocerySellerOngoingOrders } = data;
      const _newOrders = filter(getGrocerySellerOngoingOrders, (order: any) => order.status === 'PLACED');

      const savedNewOrderIds = map(newOrders, 'id');

      const newReceivedOrder = filter(_newOrders, ({ id }) => !savedNewOrderIds.includes(id));

      /**
       * Sending audio alert if new orders comes
       */
      if (newReceivedOrder.length) {
        let notify: notifyType = 'DEFAULT';

        HumanSpeaker.speak(notify);
      }

      /**
       * If there is atleast one pending order
       */
      if (_newOrders.length) {
        let notify: notifyType = 'DEFAULT';

        dispatch({
          type: 'SET_NOTIFY',
          payload: notify
        });
      } else {
        dispatch({
          type: 'UNSET_NOTIFY'
        });
      }

      if (!isEqual(newOrders, _newOrders)) {
        setNewOrders(_newOrders);
      }
    }
  }, [data, newOrders.length, dispatch, newOrders]);

  if (error) {
    Logger.log('ERROR: ' + error);
  }

  if (!isEmpty(data)) {
    const { getGrocerySellerOngoingOrders } = data;

    /*
     * Group orders by order types TAKEOUT,DELIVERY
     */
    const groupedOrders = groupBy(getGrocerySellerOngoingOrders, 'type');

    /**
     * Take Out Orders
     * Should be sorted by expectTime
     **/

    const takeOutOrders = sortBy(groupedOrders['TAKEOUT'] ? groupedOrders['TAKEOUT'] : [], 'expectTime');

    /*
     * group takeout orders by order status
     **/

    const groupedTakeOutOrders = groupBy(takeOutOrders, 'status');

    const takeOutNewOrders = groupedTakeOutOrders['PLACED'] ? groupedTakeOutOrders['PLACED'] : [];
    /*
     * Show only today's orders in processing and other orders in future orders
     */
    const now = Moment();
    const takeOutProcessingOrders = groupedTakeOutOrders['ACCEPTED']
      ? filter(groupedTakeOutOrders['ACCEPTED'], (order: any) => {
          const expTime = Moment(order.expectTime);
          const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
          return !!todayOrder;
        })
      : [];
    const futureTakeOutOrders = groupedTakeOutOrders['ACCEPTED']
      ? filter(groupedTakeOutOrders['ACCEPTED'], (order: any) => {
          const expTime = Moment(order.expectTime);
          const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
          return !todayOrder && expTime.isAfter(now);
        })
      : [];
    const takeOutReadyOrders = groupedTakeOutOrders['READY'] ? groupedTakeOutOrders['READY'] : [];

    /**
     * Delivery Orders
     * Should be sorted by expectTime
     **/

    const deliveryOrders = groupedOrders['DELIVERY'] ? sortBy(groupedOrders['DELIVERY'], 'expectTime') : [];

    const groupedDeliveryOrders = groupBy(deliveryOrders, 'status');

    const deliveryNewOrders = groupedDeliveryOrders['PLACED'] ? groupedDeliveryOrders['PLACED'] : [];

    const deliveryProcessingOrders = groupedDeliveryOrders['ACCEPTED']
      ? filter(groupedDeliveryOrders['ACCEPTED'], (order: any) => {
          const expTime = Moment(order.expectTime);
          const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
          return !!todayOrder;
        })
      : [];

    const futureDeliveryOrders = groupedDeliveryOrders['ACCEPTED']
      ? filter(groupedDeliveryOrders['ACCEPTED'], (order: any) => {
          const expTime = Moment(order.expectTime);
          const todayOrder = now.date() === expTime.date() && now.month() === expTime.month() && now.year() === expTime.year();
          return !todayOrder && expTime.isAfter(now);
        })
      : [];

    const deliveryReadyOrders = groupedDeliveryOrders['READY'] ? groupedDeliveryOrders['READY'] : [];
    return {
      newOrders: [...takeOutNewOrders, ...deliveryNewOrders],
      processingOrders: [...takeOutProcessingOrders, ...deliveryProcessingOrders],
      readyOrders: [...takeOutReadyOrders, ...deliveryReadyOrders],
      futureOrders: sortBy([...futureTakeOutOrders, ...futureDeliveryOrders], 'expectTime'),
      allOrders: getGrocerySellerOngoingOrders,
      loading,
      error,
      called
    };
  }

  return {
    newOrders: null,
    processingOrders: null,
    readyOrders: null,
    allOrders: null,
    futureOrders: null,
    loading,
    error,
    called
  };
};

export const useOrderDetails = (orderId: string, buyerId: string) => {
  const { loading, data, error } = useQueryWithLoader(GET_GROCERY_ORDER_BY_ORDER_ID, {
    skip: !orderId,
    variables: {
      input: {
        orderId,
        buyerUid: buyerId
      }
    }
  });

  if (error) {
    Logger.log('ERROR: ' + error);
  }

  if (!isEmpty(data)) {
    const { getGroceryOrderByOrderId } = data;

    return { loading, data: getGroceryOrderByOrderId, error };
  }

  return { loading, data, error };
};

export const useOrderDetailsWithoutLoader = (orderId: string, buyerId: string) => {
  const { loading, data, error } = useQuery(GET_GROCERY_ORDER_BY_ORDER_ID, {
    skip: !orderId,
    variables: {
      input: {
        orderId,
        buyerUid: buyerId
      }
    }
  });

  if (error) {
    Logger.log('ERROR: ' + error);
  }
  if (data && data.getGroceryOrderByOrderId) {
    const { getGroceryOrderByOrderId } = data;

    return { loading, data: getGroceryOrderByOrderId, error };
  }

  return { loading, data, error };
};

export const useHistory = () => {
  const {
    state: { storeId }
  } = useStore();

  const [hasMore, setHasMore] = useState(false);

  const [endCursor, setEndCursor] = useState(null);

  const [historyOrders, setHistoryOrders] = useState<IOrder[]>([]);

  const [fetching, setFetching] = useState(true);

  const pageSize = 100;

  const { data, error, fetchMore } = useQueryWithLoader(GET_GROCERY_SELLER_ORDERS, {
    skip: !storeId,
    variables: {
      input: {
        sellerStoreId: storeId,
        first: pageSize
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.getGrocerySellerOrders && !error) {
      const { getGrocerySellerOrders } = data;

      const {
        pageInfo: { hasNextPage, endCursor }
      } = getGrocerySellerOrders;

      setHasMore(hasNextPage);
      setEndCursor(endCursor);

      if (getGrocerySellerOrders.edges.length) {
        const { getGrocerySellerOrders: { edges } = { edges: [] } } = data;
        setHistoryOrders(map(edges, ({ node }) => node));
      }
      setFetching(false);
    }
    if (error) {
      setFetching(false);
      Logger.log('ERROR: ' + error);
    }
  }, [data, error]);

  const fetchMoreData = async () => {
    if (hasMore) {
      try {
        setFetching(true);
        await fetchMore({
          query: GET_GROCERY_SELLER_ORDERS,
          variables: {
            input: {
              sellerStoreId: storeId,
              first: pageSize,
              after: endCursor
            }
          },
          updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
            const { pageInfo } = fetchMoreResult.getGrocerySellerOrders;
            const newCursor = pageInfo.endCursor;

            return {
              getGrocerySellerOrders: {
                edges: [...previousResult.getGrocerySellerOrders.edges, ...fetchMoreResult.getGrocerySellerOrders.edges],
                pageInfo: {
                  endCursor: newCursor,
                  hasNextPage: pageInfo.hasNextPage,
                  __typename: pageInfo.__typename
                },

                __typename: previousResult.getGrocerySellerOrders.__typename
              }
            };
          }
        });
      } catch (e) {
        setFetching(false);
        Logger.log(e.message);
      }
    }
  };

  return {
    historyOrders,
    fetchMoreData,
    fetching,
    hasMore,
    error
  };
};

export const useGroceryUpdateOrderStatus = () => {
  const [updateStatus, { data, loading, error }] = useMutation(UPDATE_GROCERY_ORDER_STATUS);

  const _updateStatus = async (storeId: string, orderId: string, buyerId: string, status: string, msg?: string) => {
    try {
      const response = await updateStatus({
        variables: {
          input: {
            buyerUid: buyerId,
            sellerStoreId: storeId,
            orderId,
            newStatus: status,
            msg
          }
        },
        update: (cache) => {
          const { getGrocerySellerOngoingOrders: orders }: any = cache.readQuery({
            query: GET_GROCERY_SELLER_ONGOING_ORDERS,
            variables: {
              input: {
                sellerStoreId: storeId
              }
            }
          });
          const newData = map(orders, (order) => {
            if (order.id === orderId) {
              return { ...order, status };
            }
            return order;
          });
          cache.writeQuery({
            data: {
              getGrocerySellerOngoingOrders: newData
            },
            query: GET_GROCERY_SELLER_ONGOING_ORDERS,
            variables: {
              input: {
                sellerStoreId: storeId
              }
            }
          });
        }
      });

      return response;
    } catch (e) {
      return null;
    }
  };

  return {
    updateStatus: _updateStatus,
    data,
    loading,
    error
  };
};

export const useGroceryPartialRefund = () => {
  const [addGroceryPartialRefund, { data, loading, error }] = useMutation(GROCERY_ORDER_PARTIAL_REFUND);

  interface IPartialRefundInput {
    storeId: any;
    orderNumber: string;
    orderId: string;
    adjustAmount: number;
    adjustReason: string;
    buyerId: string;
  }

  const _addPartialRefund = async (input: IPartialRefundInput) => {
    const { storeId, orderNumber, adjustAmount, adjustReason, orderId, buyerId } = input;

    try {
      const response = await addGroceryPartialRefund({
        variables: {
          input: {
            sellerStoreId: storeId,
            orderNumber,
            adjustAmount,
            adjustReason
          }
        },
        refetchQueries: [
          {
            query: GET_GROCERY_ORDER_BY_ORDER_ID,
            variables: {
              input: {
                orderId: orderId,
                buyerUid: buyerId
              }
            }
          }
        ]
      });

      return response;
    } catch (e) {
      return null;
    }
  };

  return {
    addGroceryPartialRefund: _addPartialRefund,
    data,
    loading,
    error
  };
};
