import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, TextField } from '@material-ui/core';
import { useStore } from 'store';
import { useStyles } from './style';
import { Dialog, ConfirmDialog } from '@lokobee/lokobee-ui';
import { useUpdateOrderStatus } from 'graphql/hooks/orders.hooks';
import { useSnackbar } from 'notistack';
import Print from 'components/Print';
import { getLocalStorage } from 'util/storage';
import BagDialogBox from 'components/BagDialogBox';
import { IDeliveryConfirmInput, DeliveryProviders, OrderType } from 'generated/custom';
import EditEta from 'components/DelayEtaDialog/EditEta';
import OrderAcceptConformation from 'components/OrderAcceptConformation';
import { Moment } from 'moment';
import { STATUS_TYPES } from './type';

interface IProps {
  orderId: string;
  orderNumber: string;
  orderStatus: string;
  buyerId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onPrint: () => void;
  isFutureOrder?: boolean;
  deliveryProviders?: DeliveryProviders;
  deliveryMode?: string;
  orderType: OrderType;
  deliveryQuoteId?: string;
  expectTime: Moment;
}

const ActionButtons = ({ orderId, orderNumber, orderStatus, buyerId, setLoading, onPrint, isFutureOrder = false, deliveryProviders, orderType, deliveryQuoteId, expectTime }: IProps) => {
  const classes = useStyles();

  const {
    state: { restaurantId }
  } = useStore();

  const { updateStatus, loading } = useUpdateOrderStatus();

  // const { validateOtp } = useValidateDriverOTP();

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const [openBagPopUp, setOpenBagPopUp] = useState<boolean>(false);

  const [openEtaEditPopUp, setOpenEtaEditPopUp] = useState(false);

  const [message, setMessage] = useState('');

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const snackbar = useSnackbar();

  const callUpdateStatus = useCallback(
    async (status: STATUS_TYPES, deliveryConfirmInput?: IDeliveryConfirmInput, delayEtaBy?: number) => {
      const response = await updateStatus(restaurantId, orderId, buyerId, status, undefined, deliveryConfirmInput, delayEtaBy);
      const error = response.error;

      if (error) {
        const graphQLErrors = response.error.graphQLErrors;

        const errorMessage = graphQLErrors[0].message || 'Something went wrong. Please try again.';

        snackbar.enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        const regularPrinter = getLocalStorage('regularPrinter');

        if (status === 'ACCEPTED' && regularPrinter === 'false') {
          onPrint();
        }

        snackbar.enqueueSnackbar('Order status updated.', { variant: 'success' });
      }
      setLoading(false);
    },
    [buyerId, onPrint, orderId, restaurantId, setLoading, snackbar, updateStatus]
  );

  const onDeliverySubmit = (deliveryConfirmInput: IDeliveryConfirmInput) => {
    deliveryConfirmInput.type = orderType;
    deliveryConfirmInput.deliveryProvider = deliveryProviders;
    // deliveryConfirmInput.deliveryQuoteId = deliveryQuoteId;
    callUpdateStatus('ACCEPTED', deliveryConfirmInput);
    setOpenBagPopUp(false);
  };

  // const onOTPSubmit = async (OTPvalue: string) => {
  //   if (restaurantId && OTPvalue && orderNumber) {
  //     const validate = await validateOtp({
  //       variables: {
  //         input: {
  //           optCode: OTPvalue,
  //           orderNumer: orderNumber,
  //           bizId: restaurantId
  //         }
  //       }
  //     });

  //     if (validate && validate.data.validateDriverOtp) {
  //       snackbar.enqueueSnackbar('Order status updated.', { variant: 'success' });
  //       setOpenOTPPopUp(false);
  //       callUpdateStatus('CLOSED');
  //     } else {
  //       snackbar.enqueueSnackbar('Invalid OTP. Please try again.', { variant: 'error' });
  //     }
  //   }
  // };

  const shouldPopUP = () => {
    if (orderType === 'DELIVERY' && (deliveryProviders === DeliveryProviders.Boons || deliveryProviders === DeliveryProviders.DoordashClassic)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldConformationPopUp = () => {
    if (orderType === 'TAKEOUT') {
      setOpenConfirmationDialog(true);
    } else {
      callUpdateStatus('ACCEPTED');
    }
  };

  // const onReadyClick = () => {
  //   if (deliveryProviders === 'BOONS' && orderType === 'DELIVERY') {
  //     setOpenOTPPopUp(true);
  //   } else {
  //     callUpdateStatus('CLOSED');
  //   }
  // };

  const callUpdateStatusWithMsg = async (status: STATUS_TYPES) => {
    if (message !== '' && message !== undefined) {
      const response = await updateStatus(restaurantId, orderId, buyerId, status, message);

      if (response) {
        snackbar.enqueueSnackbar('Order status updated.', { variant: 'success' });
      } else {
        snackbar.enqueueSnackbar('Something went wrong.', { variant: 'error' });
      }
      setLoading(false);
    }
  };

  if (buyerId === '') {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {orderStatus === 'REJECTED' && (
        <Box paddingY={1}>
          <Typography variant="body2" color="error">
            ORDER REJECTED
          </Typography>
        </Box>
      )}
      {orderStatus === 'CANCELLED' && (
        <Box paddingY={1}>
          <Typography variant="body2" color="error">
            ORDER CANCELLED
          </Typography>
        </Box>
      )}
      {orderStatus === 'CLOSED' && (
        <Box paddingY={1}>
          <Typography variant="body2" className={classes.successText}>
            ORDER CLOSED
          </Typography>
        </Box>
      )}

      {orderStatus === 'PLACED' && (
        <Dialog dialogProps={{ fullWidth: true, maxWidth: 'xs' }}>
          <Button variant="outlined" color="default" disableElevation={true} size="small" className={classes.btns}>
            Reject
          </Button>
          <Dialog.DialogContent>
            <Typography variant="body1"> Reason for rejection : </Typography>
            <TextField type="text" variant="outlined" multiline={true} rowsMax="6" value={message} fullWidth={true} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)} />
          </Dialog.DialogContent>
          <Dialog.DialogActions onSave={() => callUpdateStatusWithMsg('REJECTED')} onCancel={() => setMessage('')} saveBtnText="OK" disableSaveBtn={message.trim() === ''} />
        </Dialog>
      )}

      {orderStatus === 'ACCEPTED' && !(orderType === OrderType.Delivery && deliveryProviders === DeliveryProviders.DoordashClassic) && (
        <Box flex={1}>
          <Dialog dialogProps={{ fullWidth: true, maxWidth: 'xs' }}>
            <Button variant="outlined" color="default" disableElevation={true} size="small" className={classes.btns}>
              Cancel
            </Button>
            <Dialog.DialogContent>
              <Typography variant="body1"> Reason for cancellation : </Typography>
              <TextField
                type="text"
                variant="outlined"
                multiline={true}
                rowsMax="6"
                value={message}
                fullWidth={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
              />
            </Dialog.DialogContent>
            <Dialog.DialogActions onSave={() => callUpdateStatusWithMsg('CANCELLED')} onCancel={() => setMessage('')} saveBtnText="OK" disableSaveBtn={message.trim() === ''} />
          </Dialog>
        </Box>
      )}

      {orderStatus === 'ACCEPTED' && orderType === OrderType.Takeout && !isFutureOrder && (
        <Button variant="contained" color="primary" disableElevation={true} size="small" className={classes.btns} onClick={() => setOpenEtaEditPopUp(true)}>
          Delay
        </Button>
      )}

      {orderStatus === 'PLACED' && !isFutureOrder && (
        <Button
          variant="contained"
          color="primary"
          disableElevation={true}
          size="small"
          className={classes.btns}
          onClick={() => {
            shouldPopUP() ? setOpenBagPopUp(true) : shouldConformationPopUp();
          }}>
          Confirm
        </Button>
      )}

      {orderStatus === 'PLACED' && isFutureOrder && (
        <ConfirmDialog
          message="This is a future order. Are you sure you want to accept the order?"
          onYes={() => {
            shouldPopUP() ? setOpenBagPopUp(true) : callUpdateStatus('ACCEPTED');
          }}>
          <Button variant="contained" color="primary" disableElevation={true} size="small" className={classes.btns}>
            Confirm
          </Button>
        </ConfirmDialog>
      )}

      {orderStatus === 'ACCEPTED' && (
        <Button
          variant="contained"
          color="primary"
          disableElevation={true}
          size="small"
          className={`${classes.btns} ${classes.successBtn}`}
          onClick={() => callUpdateStatus('READY')}
          disabled={isFutureOrder}>
          Ready
        </Button>
      )}

      {orderStatus === 'READY' && !(orderType === OrderType.Delivery && (deliveryProviders === DeliveryProviders.DoordashClassic || deliveryProviders === DeliveryProviders.Boons)) && (
        <Button
          variant="contained"
          color="secondary"
          disableElevation={true}
          size="small"
          className={classes.btns}
          onClick={() => {
            callUpdateStatus('CLOSED');
          }}>
          Close
        </Button>
      )}

      {orderStatus !== 'PLACED' && <Print onPrint={onPrint} />}

      {openBagPopUp && deliveryProviders && <BagDialogBox closePopUp={() => setOpenBagPopUp(false)} onSubmit={onDeliverySubmit} deliveryProvider={deliveryProviders} />}

      {/* <OTPDialogBox
        openPopUp={openOTPPopUp}
        closePopUp={() => setOpenOTPPopUp(false)}
        onSubmit={(optValue) => {
          onOTPSubmit(optValue);
        }}
      /> */}
      {openEtaEditPopUp && (
        <EditEta
          openDialog={openEtaEditPopUp}
          setOpenDialog={setOpenEtaEditPopUp}
          buyerId={buyerId}
          orderId={orderId}
          acceptOrder={callUpdateStatus}
          orderStatus={orderStatus}
          expectTime={expectTime}
        />
      )}

      {openConfirmationDialog && (
        <OrderAcceptConformation
          openDialog={openConfirmationDialog}
          orderNumber={orderNumber}
          expectTime={expectTime}
          setOpenDialog={() => setOpenConfirmationDialog(false)}
          acceptOrder={() => callUpdateStatus('ACCEPTED')}
          openEtaDelay={() => {
            setOpenConfirmationDialog(false);
            setOpenEtaEditPopUp(true);
          }}
        />
      )}
    </Box>
  );
};

export default ActionButtons;
