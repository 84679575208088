import { gql } from 'apollo-boost';

export default gql`
  query getIngredientsInfo($input: GetIngredientsInfoInput!) {
    getIngredientsInfo(input: $input) {
      bizId
      title
      key
      disabled
    }
  }
`;
