import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './style';
import logoImg from 'assets/img/icon-1024.png';
import PrinterStatus from 'components/PrinterStatus';

interface IProps {
  sticky?: boolean;
}

const Footer = ({ sticky = false }: IProps) => {
  const classes = useStyles();

  return (
    <Box position={sticky ? 'fixed' : ''} className={classes.footerContainer}>
      <Box display="flex" justifyContent="flex-end" bgcolor="white" alignItems="center" padding={1}>
        <img src={logoImg} className={classes.logo} alt="App logo" />
        <Box flexGrow={1} display="flex">
          <Box flexGrow={1}>
            <Typography variant="body2">
              LOKOBEE
              <span className={classes.colorSpan}>OPS</span>
            </Typography>
          </Box>
        </Box>
        <Box paddingX={1}>
          <PrinterStatus />
        </Box>

        <Typography variant="body2">v{(global as any).appVersion}</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
