import { IDiscount } from 'generated/custom';
import Big from 'big.js';

export const formatPhoneNumber = (phoneNumberString: string, showPrefix?: boolean) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] && showPrefix ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const convertPrice = (value: number, shift: number) => {
  let strValue = `${value}`;

  let prefix = '';

  let prefixZero = strValue.length + shift - 1;

  while (prefixZero < 0) {
    prefix += '0';
    prefixZero += 1;
  }

  strValue = prefix + strValue;

  return (strValue = strValue.slice(0, shift) + '.' + strValue.slice(shift));
};

export function convertUSDToCents(dollarStr: string): number {
  let str = dollarStr;
  // remove $ sign
  if (str.startsWith('$')) {
    str = str.slice(1);
  }
  str = str.trim();

  // remove '.' and make it a int value string
  const pointPos = str.indexOf('.');
  let paddingZero = 2;
  if (pointPos >= 0) {
    // has '.'
    paddingZero = 2 - (str.length - pointPos - 1);
    str = str.slice(0, pointPos) + str.slice(pointPos + 1); // remove '.'
  }
  if (paddingZero < 0) {
    console.log(`Invalid USD value '${dollarStr}', expecting at most two decimal digits`);
    return 0;
  }
  while (paddingZero > 0) {
    str = str + '0';
    paddingZero -= 1;
  }

  return parseInt(str);
}

export function convertCentsToUSD(cents: number): string {
  if (cents < 10) {
    return `0.0${cents}`;
  }
  if (cents < 100) {
    return `0.${cents}`;
  }
  let s = `${cents}`;
  return s.slice(0, -2) + '.' + s.slice(-2);
}

export const applyDiscount = (price: string, discountAmount: string): IDiscount => {
  const discountedPrice = Big(price).sub(discountAmount).toFixed(2);

  const discountedPercentage = Big(discountAmount).div(price).mul(100).round().toString();

  return { 
    price: discountedPrice, 
    percentage: discountedPercentage 
  };
}

export const convertPriceTo = (price: string | number, convertTo: "DOLLAR" | "CENT") => {
  if (price) {
    if (convertTo === 'DOLLAR') {
      return Big(price).div(100).toFixed(2);
    }

    if (convertTo === 'CENT') {
      return Big(price).mul(100).toFixed(2);
    }
  }

  return '';
}
