import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'fbase/authContext';
import { useStore } from 'store';
const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const { currentUser } = useContext(AuthContext);

  const {
    state: { restaurantId }
  } = useStore();

  return <Route {...rest} render={(routeProps) => (!!currentUser ? <RouteComponent {...routeProps} /> : <Redirect to={'/' + restaurantId} />)} />;
};

export default PrivateRoute;
