import { gql } from 'apollo-boost';

export default gql`
  query getOrderByOrderNumber($input: GetOrderByOrderNumberInput!) {
    getOrderByOrderNumber(input: $input) {
      id
      createdAt
      status
      expectTime
      orderNumber
      buyer {
        uid
        phoneNumber
        email
        displayName
      }
      paymentType
      type
      total {
        intValue
        shift
      }
    }
  }
`;
