import { useQueryWithLoader, useMutationWithLoader, useLazyQueryWithLoader } from 'hooks/loader';
import { useStore } from 'store';

import { GET_GROCERYSTORE_BY_NANOID, GET_GROCERY_WAIT_MINS } from 'graphql/query';
import { GROCERY_ORDER_PAUSE, UPDATE_GROCERY_WAIT_MINS } from 'graphql/mutations';

export const useGetGroceryStoreByNanoId = () => {
  const [getGroceryStoreByNanoid, { data, called, loading }] = useLazyQueryWithLoader(GET_GROCERYSTORE_BY_NANOID);

  const queryGroceryStore = (nanoid: string) => {
    getGroceryStoreByNanoid({
      variables: {
        input: {
          nanoid
        }
      }
    });
  };

  return { queryGroceryStore, data, called, loading };
};

export const useGetGroceryWaitMins = () => {
  const {
    state: { storeId }
  } = useStore();

  const { data } = useQueryWithLoader(GET_GROCERY_WAIT_MINS, {
    variables: {
      input: {
        id: storeId
      }
    }
  });

  return {
    data
  };
};

export const useUpdateGroceryWaitMins = () => {
  const {
    state: { storeId }
  } = useStore();

  const [updateWaitMins, { data }] = useMutationWithLoader(UPDATE_GROCERY_WAIT_MINS);

  const updateCaller = async (takeoutWaitMinutes: number, deliveryWaitMinutes: number, packingWaitMins: number) => {
    const response = await updateWaitMins({
      variables: {
        input: {
          id: storeId,
          takeoutWaitMinutes,
          deliveryWaitMinutes,
          packingWaitMins
        }
      },
      update: (cache, { data: newData }) => {
        const { updateWaitMinutes } = newData || { updateWaitMinutes: {} };

        cache.writeQuery({
          data: { getWaitMinutes: { ...updateWaitMinutes } },
          query: UPDATE_GROCERY_WAIT_MINS,
          variables: {
            input: {
              id: storeId
            }
          }
        });
      }
    });

    return response;
  };

  return {
    updateGroceryWaitMins: updateCaller,
    data
  };
};

export const useUpdateGroceryStoreOrderPause = () => {
  const {
    state: { storeId }
  } = useStore();

  const [updateGrocerySuspendedOrderStatus, { data }] = useMutationWithLoader(GROCERY_ORDER_PAUSE);

  const updateCaller = async (pauseData: any) => {
    const response = await updateGrocerySuspendedOrderStatus({
      variables: {
        input: {
          id: storeId,
          pauseData: pauseData
        }
      }
    });

    return response;
  };

  return {
    updateGrocerySuspendedOrderStatus: updateCaller,
    data
  };
};
